/* eslint-disable require-atomic-updates */
import CKEditor from 'ckeditor4-react';
import {
  Avatar,
  Button,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  TextField,
  Typography,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  CardMedia
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Delete,
  AddCircleOutline,
  Clear,
  VideoLibrary,
  Image
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import 'firebase/storage';
import { useFormik } from 'formik';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import Header from '../../components/Header';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import defaultImage from '../../images/default-image.png';
import IconModal from '../../components/Modal/IconModal';
import MediaLibraryGallery from '../mediaLibrary/components/MediaLibraryGallery';
import { postApi, putApi, getApi, deleteApi } from '../../services/Services';
import { PanelHeader } from '../dialogs/components/actionbar';
import './presenter.css';
import useStyles from './styles';
import CategoriesModal from './modal/CategoriesModal';
import {
  getCustomID,
  createOrUpdateIntent,
  updateIntentWithFind,
  removeEmptyItemInArray,
  GetSeletedRobot,
  GetTranslatedText
} from '../../utills';
import { useSelector } from 'react-redux';

CKEditor.editorUrl = process.env.PUBLIC_URL + '/ckeditor/ckeditor.js';
const initialValues = {
  id: '',
  category_name: '',
  mediaType: '',
  discription: '',
  image: '',
  training: [],
  response: [],
  title: '',
  htmldata: '',
  intentID: ''
};

const validationSchema = Yup.object().shape({
  category_name: Yup.string().label('category_name').required('Required'),
  discription: Yup.string().label('discription').required('Required'),
  image: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      type: Yup.string().required('Required')
    })
    .required('Required'),
  training: Yup.object().label('training').nullable(),
  response: Yup.object().label('response').nullable(),
  title: Yup.string().label('title').required('Required'),
  mediaType: Yup.string().label('mediaType').required('Required'),
  htmldata: Yup.string().label('htmldata'),
  intentID: Yup.string().label('intentID')
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreatePresenter = (props) => {
  const classes = useStyles();
  const { history, match } = props;
  const { params } = match;
  const { id } = params;
  const presenteresId = id;
  const [actiontype, setActionType] = useState('add');
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [htmldata, setHtmldata] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [defaultCate, setDefaultCate] = useState(null);
  const [ImageList, setImageList] = useState([]);
  const [VideoList, setVideoList] = useState([]);
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const timerRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [isCategoriesModelOpen, setIsCategoriesModelOpen] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState();

  const handleFirebaseResponse = (isSuccess, name) => {
    utility.loading(true);
    if (isSuccess) {
      utility.snack(`Presenter has been successfully ${name}.`, 'success');
      history.push('/app/presenter/list');
    } else {
      console.log('==== firebase Error =======');
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const addIntentWithData = async (values) => {
    const updatedTraning = await removeEmptyItemInArray(
      Object.values(values.training)
    );
    const updatedResponse = await removeEmptyItemInArray(
      Object.values(values.response)
    );
    try {
      const intent = await createOrUpdateIntent({
        name: values.title,
        training: updatedTraning,
        response: updatedResponse,
        selectedRobotID: selectedRobot
      });
      return intent;
    } catch (e) {
      return false;
    }
  };

  const addPresenterSubmit = async (values) => {
    let intent;
    try {
      intent = await addIntentWithData(values);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    if (intent && intent.data && intent.data.id) {
      values.intentID = intent.data.id;
    }
    values.imageLink = values.image.value;
    values.response = Object.assign({}, values.response);
    values.training = Object.assign({}, values.training);
    try {
      await postApi(`/add-presenter?selectedRobot=${selectedRobot}&language=${language}`, values);
      handleFirebaseResponse(true, 'created');
    } catch (e) {
      handleFirebaseResponse(false, 'created');
    }
  };

  const editPresenterSubmit = async (values) => {
    if (!values.intentID) {
      try {
        const intent = await addIntentWithData(values);
        values.intentID = intent.data.id || '';
      } catch (e) {
        console.log('error ', e);
      }
    } else {
      try {
        const updatedTraning = await removeEmptyItemInArray(
          Object.values(values.training)
        );
        const updatedResponse = await removeEmptyItemInArray(
          Object.values(values.response)
        );
        await updateIntentWithFind({
          name: values.title,
          training: updatedTraning,
          response: updatedResponse,
          id: values.intentID,
          selectedRobotID: selectedRobot
        });
      } catch (e) {
        console.log('error ', e);
      }
    }
    values.response = Object.assign({}, values.response);
    values.training = Object.assign({}, values.training);
    values.imageLink = values.image.value;

    try {
      await putApi(
        `/edit-presenter?selectedRobot=${selectedRobot}&id=${values.id}&language=${language}`,
        values
      );
      handleFirebaseResponse(true, 'updated');
    } catch (e) {
      handleFirebaseResponse(false, 'updated');
    }
  };

  const addNewCateory = async (data) => {
    const id = getCustomID();
    const newCateoryData = {
      ...data,
      categoryId: id
    };
    try {
      utility.loading(true);
      const response = await postApi(
        `/category?selectedRobot=${selectedRobot}`,
        newCateoryData
      );
      if (response && response.statusCode === 200) {
        setCategoryList((preState) => {
          const newData = preState;
          newData.push(data);
          return newData;
        });
        setIsCategoriesModelOpen(!isCategoriesModelOpen);
        setIsCategoriesModelOpen(true);
        utility.snack('Category has been added successfully.', 'success');
      } else {
        utility.snack('Category added operation failed.', 'error');
      }
      utility.loading(false);
    } catch (err) {
      utility.loading(false);
      utility.snack('Category added operation failed.', 'error');
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      if (!selectedRobot) return false;
      utility.loading(true);
      const index = categoryList.findIndex(
        (each) => each.categoryTitle === values.category_name
      );

      if (index === -1) {
        await addNewCateory({ categoryTitle: values.category_name });
      }
      const training = Object.entries(values.training);
      const traniningPhrase = [];
      if (training.length > 0) {
        await training.forEach(async (item) => {
          const translatedText = await GetTranslatedText(item[1], 'en');
          traniningPhrase[item[0]] = translatedText;
        });
      }

      values.training = traniningPhrase;

      if (actiontype === 'add') {
        addPresenterSubmit(values);
      } else {
        editPresenterSubmit(values);
      }
    },
    validationSchema
  });

  const addCategory = async (text) => {
    let isCategoryExist = false;

    categoryList.forEach((item) => {
      if (text.toLocaleLowerCase() === item.categoryTitle.toLocaleLowerCase()) {
        isCategoryExist = true;
      }
    });

    if (!isCategoryExist) {
      await addNewCateory({ categoryTitle: text });
    } else {
      utility.snack('Category already exist.', 'error');
    }
  };

  const deleteCategory = async (id) => {
    utility.loading(true);
    try {
      const response = await deleteApi(
        `/category?selectedRobot=${selectedRobot}`,
        { id }
      );
      if (response && response.statusCode === 200) {
        setCategoryList((preState) => {
          let newData = preState;
          newData = newData.filter((item) => item.categoryId !== id);
          console.log('newData ', newData);
          return newData;
        });
        utility.snack('Category has been deleted successfully.', 'success');
      } else {
        utility.snack('Category deleted operation failed.', 'error');
      }
      utility.loading(false);
    } catch (err) {
      utility.loading(false);
      utility.snack('Category deleted operation failed.', 'error');
    }
  };

  const editCategory = async (data) => {
    utility.loading(true);
    try {
      const response = await putApi(
        `/category?selectedRobot=${selectedRobot}&&id=${id}`,
        {
          categoryId: data.categoryId,
          categoryTitle: data.categoryTitle
        }
      );
      if (response && response.statusCode === 200) {
        utility.snack('Category has been edit successfully.', 'success');
      } else {
        utility.snack('Category update operation failed.', 'error');
      }
      utility.loading(false);
    } catch (err) {
      utility.loading(false);
      utility.snack('Category update operation failed.', 'error');
    }
  };

  const handleClickOpen = (type) => {
    setValue(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValue('');
  };

  const getCategoriesData = async (robotId) => {
    const selectedRobotID = robotId ? robotId : selectedRobot;
    try {
      if (selectedRobotID) {
        const response = await getApi(
          `/categories?selectedRobot=${selectedRobotID}`
        );
        if (response && response.statusCode === 200) {
          setCategoryList(response.data);
        } else {
          setCategoryList([]);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
      throw new Error('Categories Error', err);
    }
  };

  const handleDelete = useCallback(() => {
    const genarateImage = {
      value: '',
      type: ''
    };
    formik.setFieldValue('image', genarateImage);
  }, []);

  const handleSelect = (item, type) => {
    if (item && type) {
      if (type === 'image') {
        const genarateImage = {
          value: item.downloadlink,
          type: item.downloadlink.split('.').pop().split('?')[0]
        };
        formik.setFieldValue('image', genarateImage);
        formik.setFieldValue('mediaType', 'image');
      } else {
        const genarateImage = {
          value: item.downloadlink,
          type: item.downloadlink.split('.').pop().split('?')[0]
        };
        formik.setFieldValue('image', genarateImage);
        formik.setFieldValue('mediaType', 'video');
      }
      handleClose();
    }
  };

  const getPresenteresInfo = async () => {
    utility.loading(true);
    if (presenteresId) {
      setActionType('edit');
      const getSeletedRobot = GetSeletedRobot();
      setSelectedRobot(getSeletedRobot);
      try {
        const presenterRes = await getApi(
          `/get-presenter-by-id?id=${presenteresId}&selectedRobot=${getSeletedRobot}`
        );
        if (presenterRes.data && presenterRes.data.id) {
          const result = presenterRes.data;
          const training =
            (result.training
              ? Object.values(result.training ? result.training : {})
              : Object.values(
                  result.training_phase ? result.training_phase : {}
                )) || [];
          const translatedTraining = [];
          training.forEach(async (item) => {
            const translatedText = await GetTranslatedText(item, 'ro');
            translatedTraining.push(translatedText);
          });
          formik.setFieldValue('id', result.id);
          formik.setFieldValue('category_name', result.category_name);
          setDefaultCate(result.category_name);
          formik.setFieldValue('discription', result.discription);
          formik.setFieldValue('mediaType', result.mediaType);
          formik.setFieldValue('training', translatedTraining);
          formik.setFieldValue('response', result.response);
          formik.setFieldValue('title', result.title);
          formik.setFieldValue('htmldata', result.htmldata);
          setHtmldata(result.htmldata);
          formik.setFieldValue('image', {
            value: result.imageLink ? result.imageLink : '',
            type: result.imageLink
              ? result.imageLink.split('.').pop().split('?')[0]
              : ''
          });
        }
      } catch (e) {
        history.push('/app/presenter/list');
        utility.snack('Data not found.', 'error');
      }
      utility.loading(false);
    } else {
      const id = getCustomID();
      formik.setFieldValue('id', id);
    }
  };

  const handleTrainingPhase = () => {
    const training = formik.values.training || [];
    const id = getCustomID();
    training[id] = '';
    formik.setFieldValue('training', training);
  };
  const handleResponse = () => {
    const response = formik.values.response || [];
    const id = getCustomID();
    response[id] = '';
    formik.setFieldValue('response', response);
  };

  const onChangeTrainingPhase = (id, e) => {
    const training = formik.values.training;
    training[id] = e.target.value;
    formik.setFieldValue('training', training);
  };
  const onChangeResponse = (i, e) => {
    const response = formik.values.response;
    response[i] = e.target.value;
    formik.setFieldValue('response', response);
  };
  const onCancelField = (index, isResponse) => {
    if (!isResponse) {
      if (Object.values(formik.values.training).length > 0) {
        const training = Object.values(formik.values.training);
        const newTrainingPhase = training.filter((e) => e !== training[index]);
        formik.setFieldValue('training', newTrainingPhase);
      }
    } else {
      if (Object.values(formik.values.response).length > 0) {
        const response = Object.values(formik.values.response);
        const newResponse = response.filter((e) => e !== response[index]);
        formik.setFieldValue('response', newResponse);
      }
    }
  };

  const getData = async (robotId) => {
    utility.loading(true);
    const selectedRobotID = robotId ? robotId : selectedRobot;
    try {
      if (selectedRobotID) {
        const imageResponse = await getApi(
          `/all-media-library-images?selectedRobot=${selectedRobotID}`
        );
        if (imageResponse && imageResponse.statusCode === 200) {
          const imagesData = [];
          imageResponse.data.forEach((item) => {
            imagesData.push({
              id: item.imageId,
              title: item.imageName,
              downloadlink: item.imageUrl
            });
          });
  
          setImageList(imagesData);
        }
        const videoResponse = await getApi(
          `/all-media-library-videos?selectedRobot=${selectedRobotID}`
        );
        if (videoResponse && videoResponse.statusCode === 200) {
          const videoData = [];
  
          videoResponse.data.forEach((item) => {
            videoData.push({
              id: item.videoId,
              title: item.videoName,
              downloadlink: item.videoUrl
            });
          });
          setVideoList(videoData);
        }
        utility.loading(false);
      }
    } catch (err) {
      utility.loading(false);
      throw new Error("All media library Error", err)
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (presenteresId) {
      getPresenteresInfo();
    } else {
      const id = getCustomID();
      formik.setFieldValue('id', id);
    }
  }, []);

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const renderCategoriesModal = () => (
    <IconModal
      button={
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsCategoriesModelOpen(!isCategoriesModelOpen)}
          style={{ width: '98%', height: '55px', marginLeft: '10px' }}
        >
          {t('presenter.categoryManagement')}
        </Button>
      }
    />
  );

  const renderTrannings = () => {
    const trainingData = formik.values.training
      ? Object.entries(formik.values.training)
      : [];
    if (!trainingData || trainingData.length === 0) return null;
    return trainingData.map((item, i) => {
      return (
        <ListItem key={i}>
          <TextField
            id="training"
            label={t('presenter.trainingPhrase')}
            variant="outlined"
            fullWidth
            name="training"
            error={
              formik.errors.training && formik.touched.training ? true : null
            }
            helperText={
              formik.errors.training && formik.touched.training
                ? formik.errors.training
                : ''
            }
            onChange={(e) => {
              onChangeTrainingPhase(item[0], e);
            }}
            value={item[1]}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      onCancelField(i, false);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </ListItem>
      );
    });
  };

  const renderResponses = () => {
    const responseData = formik.values.response
      ? Object.entries(formik.values.response)
      : [];
    if (!responseData || responseData.length === 0) return null;
    return responseData.map((item, i) => {
      return (
        <ListItem key={i}>
          <TextField
            id="response"
            label={t('presenter.response')}
            variant="outlined"
            name="response"
            fullWidth
            error={
              formik.errors.response && formik.touched.response ? true : null
            }
            helperText={
              formik.errors.response && formik.touched.response
                ? formik.errors.response
                : ''
            }
            onChange={(e) => {
              onChangeResponse(item[0], e);
            }}
            value={item[1]}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      onCancelField(item[0], true);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </ListItem>
      );
    });
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getData(robotId);
    getCategoriesData(robotId);
  };

  return (
    <>
      <Header
        title={
          presenteresId
            ? t('presenter.editpresenter')
            : t('presenter.createtitle')
        }
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={presenteresId ? false : true}
      />
      <CategoriesModal
        onSubmit={addCategory}
        open={isCategoriesModelOpen}
        toggleModal={() => setIsCategoriesModelOpen(!isCategoriesModelOpen)}
        list={categoryList}
        onDelete={deleteCategory}
        onEdit={editCategory}
      />
      <PanelHeader
        title={
          presenteresId
            ? t('presenter.editpresenter')
            : t('presenter.createtitle')
        }
      />
      <form
        className="presenter-form"
        style={{ width: '100%' }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper style={{ padding: 15, width: '100%' }}>
              <ListItem>
                <TextField
                  id="title"
                  label={t('presenter.RowTitle')}
                  variant="outlined"
                  name="title"
                  fullWidth
                  error={
                    formik.errors.title && formik.touched.title ? true : null
                  }
                  helperText={
                    formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : ''
                  }
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                />
              </ListItem>
              <ListItem>
                <Grid container>
                  <Grid item xs={6}>
                    {actiontype === 'add' ? (
                      <Autocomplete
                        freeSolo
                        id="category_name"
                        fullWidth
                        disableClearable
                        options={categoryList.map(
                          (option) => option.categoryTitle
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('presenter.RowCategory')}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search'
                            }}
                            name="category_name"
                            error={
                              formik.errors.category_name &&
                              formik.touched.category_name
                                ? true
                                : null
                            }
                            helperText={
                              formik.errors.category_name &&
                              formik.touched.category_name
                                ? formik.errors.category_name
                                : ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.category_name}
                            onBlur={formik.handleBlur}
                          />
                        )}
                        onChange={(_, selectedOptions) =>
                          formik.setFieldValue('category_name', selectedOptions)
                        }
                      />
                    ) : defaultCate !== null ? (
                      <Autocomplete
                        freeSolo
                        id="category_name"
                        fullWidth
                        disableClearable
                        defaultValue={defaultCate}
                        options={categoryList.map(
                          (option) => option.categoryTitle
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('presenter.RowCategory')}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search'
                            }}
                            name="category_name"
                            error={
                              formik.errors.category_name &&
                              formik.touched.category_name
                                ? true
                                : null
                            }
                            helperText={
                              formik.errors.category_name &&
                              formik.touched.category_name
                                ? formik.errors.category_name
                                : ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.category_name}
                            onBlur={formik.handleBlur}
                          />
                        )}
                        onChange={(_, selectedOptions) =>
                          formik.setFieldValue('category_name', selectedOptions)
                        }
                      />
                    ) : (
                      <TextField
                        id="category_name"
                        label={t('presenter.RowCategory')}
                        variant="outlined"
                        name="category_name"
                        fullWidth
                        error={formik.errors.category_name ? true : null}
                        helperText={
                          formik.errors.category_name
                            ? formik.errors.category_name
                            : ''
                        }
                        onChange={formik.handleChange}
                        value={formik.values.category_name}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {renderCategoriesModal()}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem>
                <TextField
                  id="discription"
                  label={t('presenter.RowDecription')}
                  variant="outlined"
                  name="discription"
                  fullWidth
                  multiline
                  minRows={3}
                  error={
                    formik.errors.discription && formik.touched.discription
                      ? true
                      : null
                  }
                  helperText={
                    formik.errors.discription && formik.touched.discription
                      ? formik.errors.discription
                      : ''
                  }
                  onChange={formik.handleChange}
                  value={formik.values.discription}
                  onBlur={formik.handleBlur}
                />
              </ListItem>

              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <IconButton
                    onClick={() => {
                      handleTrainingPhase();
                    }}
                  >
                    <label style={{ fontSize: '15px' }}>
                      {t('presenter.RowAddTraning')}
                    </label>{' '}
                    <AddCircleOutline />
                  </IconButton>
                  {renderTrannings()}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <IconButton
                    onClick={() => {
                      handleResponse();
                    }}
                  >
                    <label style={{ fontSize: '15px' }}>
                      {t('presenter.RowAddResponse')}
                    </label>{' '}
                    <AddCircleOutline />
                  </IconButton>
                  {renderResponses()}
                </Grid>
              </Grid>

              <ListItem>
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <CKEditor
                    type="classic"
                    data={htmldata || ''}
                    onChange={(event) => {
                      const data = event.editor.getData();
                      setHtmldata(event.editor.getData());
                      formik.setFieldValue('htmldata', data);
                    }}
                  />
                  {formik.errors.htmldata && (
                    <FormHelperText error className={classes.errorContainer}>
                      {formik.errors.htmldata}
                    </FormHelperText>
                  )}
                </div>
              </ListItem>
              <ListItem>
                <Button variant="contained" color="primary" type="submit">
                  {t('presenter.RowSave')}
                </Button>
              </ListItem>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper style={{ padding: 15, width: '100%' }}>
              <List>
                <ListItemIcon>
                  <Typography>
                    {formik.values.mediaType === 'image' ? 'Image' : 'Video'}
                  </Typography>
                </ListItemIcon>

                {formik.values.mediaType === 'image' && (
                  <ListItem className={classes.concenterAlign}>
                    <div className={classes.concenterAlign}>
                      <Avatar
                        src={
                          formik.values.image?.value
                            ? formik.values.image?.value
                            : defaultImage
                        }
                        variant="circular"
                        style={{
                          width: 100,
                          height: 100
                        }}
                      />
                    </div>
                  </ListItem>
                )}
                {formik.values.mediaType === 'video' && (
                  <ListItem className={classes.concenterAlign}>
                    <div className={classes.concenterAlign}>
                      <Avatar>
                        <CardMedia
                          component={
                            formik.values.mediaType === 'image'
                              ? 'img'
                              : 'video'
                          }
                          width="100%"
                          height="auto"
                          image={
                            formik.values.image?.value
                              ? formik.values.image?.value
                              : defaultImage
                          }
                          title="Contemplative Reptile"
                          style={{ cursor: 'pointer' }}
                          // onClick={() => { handleSelect(item.id) }}
                        />
                      </Avatar>
                    </div>
                  </ListItem>
                )}

                {formik.values.image?.value ? (
                  <ListItem
                    className={clsx([
                      classes.concenterAlign,
                      classes.noHorizontalPadding
                    ])}
                  >
                    <Avatar onClick={handleDelete}>
                      <Delete />
                    </Avatar>
                  </ListItem>
                ) : (
                  <ListItem
                    className={clsx([
                      classes.concenterAlign,
                      classes.noHorizontalPadding
                    ])}
                  >
                    <>
                      <label htmlFor="contained-button-file">
                        <IconButton
                          onClick={() => {
                            handleClickOpen('image');
                          }}
                        >
                          <Avatar>
                            <Image />
                          </Avatar>
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            handleClickOpen('video');
                          }}
                        >
                          <Avatar>
                            <VideoLibrary />
                          </Avatar>
                        </IconButton>
                      </label>
                    </>
                  </ListItem>
                )}
                <ListItem>
                  {formik?.errors?.image?.value && formik?.touched?.image && (
                    <FormHelperText error className={classes.errorContainer}>
                      {formik.errors.image.value}
                    </FormHelperText>
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <div>
            <Dialog
              fullScreen
              open={open}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    Gallery{' '}
                  </Typography>
                </Toolbar>
              </AppBar>
              <div>
                {value === 'image' &&
                  ImageList &&
                  ImageList.length > 0 &&
                  ImageList.map((each) => {
                    return (
                      <MediaLibraryGallery
                        item={each}
                        key={each.id.toString()}
                        handleDelete={handleDelete}
                        type={value}
                        use={true}
                        handleSelect={handleSelect}
                      />
                    );
                  })}
                {value === 'video' &&
                  VideoList &&
                  VideoList.length > 0 &&
                  VideoList.map((each, index) => {
                    return (
                      <MediaLibraryGallery
                        item={each}
                        key={index.toString()}
                        handleDelete={handleDelete}
                        type={value}
                        use={true}
                        handleSelect={handleSelect}
                      />
                    );
                  })}
              </div>
            </Dialog>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default withRouter(memo(CreatePresenter));
