import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '400px',
    heght: '200px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  actionBtnRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    justifyContent: 'flex-end'
  }
}));
