import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  addIconbtn: {
    padding: '5px 0'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  btnDiv: {
    marginTop: '30px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  formControlModal: {
    minWidth: '19rem !important'
  },
  formControlText: {
    margin: theme.spacing(1),
    minWidth: '80%'
  },
  uploadButton: {
    backgroundColor: '#e0e0e0'
  }
}));
