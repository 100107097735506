import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { postApi, getApi, putApi } from '../services/Services';

export const GoogleCredentials = () => {
  if (process.env.REACT_APP_GOOGLE_TRANSLATE_CREDENTIALS) {
    return JSON.parse(process.env.REACT_APP_GOOGLE_TRANSLATE_CREDENTIALS.replace(/\\n/g, '\n'));
  }
  return {};
};

export const GetTranslatedText = async (text, targetLang) => {
  let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATE_API_KEY}`;
  url += '&q=' + encodeURI(text);
  url += `&target=${targetLang}`;
  let translatedText = '';

  await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
    .then((res) => res.json())
    .then((response) => {
      const {
        data: { translations }
      } = response;
      translatedText = translations[0].translatedText;
    });
  return translatedText;
};

export const IsEmailValid = async (email) => {
  const emailValidation = /\S+@\S+\.\S+/;
  return emailValidation.test(email);
};

export const GetSeletedRobot = () => {
  return Cookies.get('selectedRobot') || '';
};

export const SetSeletedRobot = async (id) => {
  Cookies.set('selectedRobot', id);
};

export const getCustomID = () => {
  const uuidWithTime = `${moment()
    .format('DD-MM-YYYY-LT')
    .replace(' ', '')}-${uuidv4().replace(/-/g, '').substring(0, 15)}`;
  return uuidWithTime;
};

export const createOrUpdateIntent = async ({
  training,
  response,
  name,
  selectedRobotID
}) => {
  const existMsg = 'An intent with the same name already exists.';
  const selectedRobot = selectedRobotID ? selectedRobotID : '';
  let intent = await postApi(`/intent?selectedRobot=${selectedRobot}`, {
    question: {
      items: training
    },
    response: {
      items: response
    },
    name
  });

  if (intent.statusCode === 200 && intent.intentMsg === existMsg) {
    const getAllIntents = await getApi(
      `/allintents?selectedRobot=${selectedRobot}`
    );
    let selectedIntent;

    getAllIntents.data.intents.forEach((item) => {
      if (item.name === name) selectedIntent = item;
    });
    if (selectedIntent) {
      intent = await putApi(`/intent?selectedRobot=${selectedRobot}`, {
        question: {
          items: training
        },
        response: {
          items: response
        },
        name,
        id: selectedIntent.id
      });
    }
  }
  return intent;
};

export const updateIntentWithFind = async ({
  training,
  response,
  name,
  id,
  selectedRobotID
}) => {
  const existMsg = 'An intent with the same name already exists.';
  const selectedRobot = selectedRobotID ? selectedRobotID : '';
  let intent = await putApi(`/intent?selectedRobot=${selectedRobot}`, {
    question: {
      items: training
    },
    response: {
      items: response
    },
    name,
    id
  });

  if (intent.statusCode === 200 && intent.intentMsg === existMsg) {
    const getAllIntents = await getApi(
      `/allintents?selectedRobot=${selectedRobot}`
    );
    let selectedIntent;

    getAllIntents.data.intents.forEach((item) => {
      if (item.name === name) selectedIntent = item;
    });
    if (selectedIntent) {
      intent = await putApi(`/intent?selectedRobot=${selectedRobot}`, {
        question: {
          items: training
        },
        response: {
          items: response
        },
        name,
        id: selectedIntent.id
      });
    }
  }
  return intent;
};

export const removeEmptyItemInArray = async (data) => {
  const newData = [];
  data.forEach((item) => {
    if (item) newData.push(item);
  });
  return newData;
};

export const getTimeZoneData = () => {
  return { timeZone: 'Europe/Bucharest' };
};
