import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EidIcon from '@material-ui/icons/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '../../../components/Wrappers';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752
  },
  list: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'scroll',
    maxHeight: '200px'
  },
  title: {
    paddingTop: '10px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      // eslint-disable-line no-useless-computed-key
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  ModalBtn: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff !important',
    marginTop: '20px',
    zIndex: 99
  },
  btnDiv: {
    marginTop: '30px'
  },
  closeIconbtn: {
    cursor: 'pointer'
  },
  editCloseIconbtn: {
    cursor: 'pointer',
    paddingTop: '10px'
  }
}));

const validationSchema = Yup.object().shape({
  category_name: Yup.string().label('category_name')
});

const SimpleModal = (props) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [categoryName, setCategoryName] = React.useState('');
  const [seletedCategory, setSeletedCategory] = React.useState('');
  const {
    open,
    toggleModal,
    list,
    onSubmit,
    onDelete,
    onEdit,
    seletedCategoryID
  } = props;

  useEffect(() => {
    setSeletedCategory(seletedCategoryID);
  }, [seletedCategoryID]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { category_name: '' },
    onSubmit: (values, { resetForm }) => {
      if (categoryName) {
        if (
          seletedCategory &&
          seletedCategory &&
          seletedCategory === 'update'
        ) {
          const updatedCategory = seletedCategory;
          updatedCategory.categoryTitle = categoryName;
          onEdit(updatedCategory);
        } else {
          onSubmit(categoryName);
        }
        setSeletedCategory('');
        setCategoryName('');
        resetForm();
      }
    },
    validationSchema
  });

  const { t } = useTranslation('common');

  const handleClose = () => {
    toggleModal();
  };

  const onEditClick = (item) => {
    setSeletedCategory(item.categoryId);
    setCategoryName(item.categoryTitle);
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {props.body}
          <div className={classes.btnDiv}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h4" className={classes.text}>
                Add Category
              </Typography>
              <CloseIcon
                onClick={() => toggleModal()}
                className={classes.closeIconbtn}
              />
            </Grid>

            <form className="presenter-form" onSubmit={formik.handleSubmit}>
              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={8} md={8}>
                  <TextField
                    id="category_name"
                    label={t('presenter.RowCategory')}
                    variant="outlined"
                    name="category_name"
                    fullWidth
                    error={
                      formik.errors.category_name &&
                      formik.touched.category_name
                        ? true
                        : null
                    }
                    helperText={
                      formik.errors.category_name &&
                      formik.touched.category_name
                        ? formik.errors.category_name
                        : ''
                    }
                    onChange={(e) => setCategoryName(e.target.value)}
                    value={categoryName}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      margin: '0px 0px 0px 10px',
                      width: '100px',
                      height: '55px'
                    }}
                    type="submit"
                  >
                    {t('presenter.RowSave')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <div className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" className={classes.title}>
                    Categories List
                  </Typography>
                  <div className={classes.list}>
                    <List dense={false}>
                      {list.length > 0 &&
                        list.map((item, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={item.categoryTitle} />
                            <ListItemSecondaryAction>
                              {item.categoryId === seletedCategory ? (
                                <CloseIcon
                                  onClick={() => {
                                    setCategoryName('');
                                    setSeletedCategory('');
                                  }}
                                  className={classes.editCloseIconbtn}
                                />
                              ) : (
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => onEditClick(item)}
                                >
                                  <EidIcon />
                                </IconButton>
                              )}

                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => onDelete(item.categoryId)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

SimpleModal.propTypes = {
  open: PropTypes.bool,
  list: PropTypes.array,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  toggleModal: PropTypes.func
};

SimpleModal.defaultProps = {
  open: false,
  list: [],
  onSubmit: () => {},
  onDelete: () => {},
  onEdit: () => {},
  toggleModal: () => {}
};

export default SimpleModal;
