import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  ButtonGroup,
  Button,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
// styles
import useStyles from './styles';

import { useDispatch } from 'react-redux';
import { changeLeng } from '../../app/data/language/language';

const options = ['English', 'Romania'];

export default function PageTitle() {
  const classes = useStyles();
  const { i18n } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const initIndex = i18n.language === 'en' ? 0 : 1;
  const [selectedIndex, setSelectedIndex] = React.useState(initIndex);
  const dispatch = useDispatch();

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    let lang = 'en';
    if (index === 1) lang = 'ro';

    i18n.changeLanguage(lang);
    dispatch(changeLeng(lang));
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          style={{ backgroundColor: 'white' }}
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleToggle}
        >
          <img
            src={'./' + options[selectedIndex] + '.png'}
            width={'28'}
            height={'18'}
          />
          <Typography variant="button" className={classes.languageText}>
            {options[selectedIndex]}
          </Typography>
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      <img
                        src={'./' + option + '.png'}
                        width={'28'}
                        height={'18'}
                        style={{ marginRight: '10px' }}
                      />
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
