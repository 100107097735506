import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  personFace: {
      marginRight: '20px',
      marginBottom: '20px',
      position: 'relative',
      '&:hover $hiddenComponent': {
        display: 'block',
      },
      '&:hover $hiddenDeleteIcon': {
        display: 'block',
      },
  },
  hiddenComponent: {
    display: 'none',
    position: 'absolute',
    top: -76,
    left: 30,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  hiddenDeleteIcon: {
    display: 'none',
    position: 'absolute',
    top: 25,
    left: 25,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  concenterAlign: {
    maxWidth: '705px',
    display: 'flex',
    flexWrap: 'wrap',
  }
}));
