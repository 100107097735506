import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  formControlModal: {
    minWidth: '19rem !important'
  },
  barChart: {
    '& .recharts-surface': {
      '& .recharts-xAxis': {
        '& .recharts-cartesian-axis-ticks': {
          display: 'none'
        }
      },
      '& .recharts-bar': {
        '& .recharts-bar-rectangles': {
          '& path': {
            fill: '#2ecc71'
          }
        }
      }
    }
  },
  questionInChart: {
    width: '100%',
    fontStyle: 'italic',
    padding: '0 15px',
    whiteSpace: 'break-spaces'
  },
  responseItemsContainer: {
    width: '100%',
    border: "1px solid lightgray",
    borderRadius: "10px",
    padding: '10px 0px 10px 10px'
  },
  responseItem: {
    overflow: 'hidden',
    background: 'dodgerblue',
    color: 'white',
    border: '0px solid',
    borderRadius: '10px',
    padding: '0px',
    minWidth: '45px',
    fontWeight: '500',
    marginRight: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  activeResponse: {
    background: 'green'
  },
  itemContainer: {
    display: 'flex',
    padding: '8px'
  },
  itemValue: {
    width: '100%',
  },
  deleteContainer: {
    display: 'flex',
    marginLeft: '10px'
  },
  checkItem: {
    fontSize: '20px',
    marginRight: '10px'
  },
  delResponse: {
    fontSize: '20px',
    borderRadius: '50%',
    border: '1px solid',
    padding: '1px',
  },
  notEmty: {
    borderBottom: '1px solid red'
  },
  notEmptyText: {
    color: 'red'
  }
}));
