import React, { useState } from 'react';
import { Button, Tooltip, Typography, Grid, Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import { useTranslation } from 'react-i18next';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import ProgressBar from '../../components/ProgressBar';
import useStyles from './styles';
import * as firebase from 'firebase';
import 'firebase/storage';
import MediaLibraryGallery from './components/MediaLibraryGallery';
import { getApi, postApi, deleteApi } from '../../services/Services';
import Header from '../../components/Header';
import { getCustomID } from '../../utills';

export default function MediaLibrary() {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [value, setValue] = React.useState('images');
  const [ImageList, setImageList] = useState([]);
  const [VideoList, setVideoList] = useState([]);
  const [soundList, setSoundList] = useState([]);
  const [uploadingSatus, setUploadingSatus] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [selectedRobot, setSelectedRobot] = useState();
  const [robotDetail, setRobotDetail] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);

  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (data) => {
    try {
      let mediaData;
      if (value === 'images') {
        mediaData = {
          imageId: data.makeId,
          imageName: data.fileName,
          imageUrl: data.getDownloadURL
        };
      } else if (value === 'videos') {
        mediaData = {
          videoId: data.makeId,
          videoName: data.fileName,
          videoUrl: data.getDownloadURL,
          isVideo: true
        };
      } else {
        mediaData = {
          soundId: data.makeId,
          soundName: data.fileName,
          soundUrl: data.getDownloadURL,
          isSound: true
        };
      }
      await postApi(`/media-library?selectedRobot=${selectedRobot}`, mediaData);
    } catch (e) {
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const getData = async (robotId) => {
    utility.loading(true);
    setIsDataFetched(false);
    const selectedRobotID = robotId ? robotId : selectedRobot;

    try {
      const imageResponse = await getApi(
        `/all-media-library-images?selectedRobot=${selectedRobotID}`
      );
      if (imageResponse && imageResponse.statusCode === 200) {
        const imagesData = [];
        imageResponse.data.forEach((item) => {
          imagesData.push({
            id: item.imageId,
            title: item.imageName,
            downloadlink: item.imageUrl
          });
        });

        setImageList(imagesData);
      }

      const videoResponse = await getApi(
        `/all-media-library-videos?selectedRobot=${selectedRobotID}`
      );
      if (videoResponse && videoResponse.statusCode === 200) {
        const videoData = [];
        videoResponse.data.forEach((item) => {
          videoData.push({
            id: item.videoId,
            title: item.videoName,
            downloadlink: item.videoUrl
          });
        });
        setVideoList(videoData);
      }

      const soundRespose = await getApi(
        `/all-media-library-sounds?selectedRobot=${selectedRobotID}`
      );
      if (soundRespose && soundRespose.statusCode === 200) {
        const soundData = [];
        soundRespose.data.forEach((item) => {
          soundData.push({
            id: item.soundId,
            title: item.soundName,
            downloadlink: item.soundUrl
          });
        });
        setSoundList(soundData);
      }

      utility.loading(false);
      setIsDataFetched(true);
    } catch (err) {
      utility.loading(false);
      setIsDataFetched(true);
    }
  };

  const handleOnClick = (event) => {
    let firebaseAgent;
    try {
      firebaseAgent = firebase.initializeApp(
        {
          projectId: robotDetail.project_id,
          databaseURL: robotDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robotDetail.project_id}.appspot.com`
        },
        robotDetail.id
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    firebaseAgent = firebase.app(robotDetail.id);
    setUploadingSatus(true);
    const files = event.target.files;
    for (const file of files) {
      if (!file) continue;
      const makeId = getCustomID();
      const fileNameGenarate = makeId + '___' + file.name;
      const storageRef = firebaseAgent
        .storage()
        .ref(`MediaLibrary/${value}/` + fileNameGenarate);

      const task = storageRef.put(file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (percentage < 100) {
            setUploadPercentage(percentage);
          }
        },
        function error() {
          setUploadingSatus(false);
          utility.snack('Something went wrong please try again.', 'error');
        },
        function complete() {
          task.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              setUploadPercentage(100);
              const sendData = {
                fileName: fileNameGenarate,
                getDownloadURL: url,
                makeId
              };
              handleSubmit(sendData);
              setTimeout(() => {
                setUploadingSatus(false);
                setUploadPercentage(0);
                utility.snack('Media has been upload successfully.', 'success');
                getData();
              }, 1000);
            })
            .catch((e) => {
              console.log(e);
              utility.snack('Something went wrong please try again.', 'error');
            });
        }
      );
    }
  };

  const deleteSound = async (audioData) => {
    if (audioData) {
      if (window.confirm('Do you want to delete?')) {
        utility.loading(true);
        const id = audioData.id;
        const response = await deleteApi(
          `/media-library-Sound?selectedRobot=${selectedRobot}`,
          { id, title: audioData.title }
        );
        if (response.statusCode === 200) {
          await getData();
          utility.snack('Successfully Delete');
        }
        utility.loading(false);
      }
    }
  };

  const deleteImage = async (imageData) => {
    if (imageData) {
      if (window.confirm('Do you want to delete?')) {
        utility.loading(true);
        const id = imageData.id;
        const response = await deleteApi(
          `/media-library-image?selectedRobot=${selectedRobot}`,
          { id, title: imageData.title }
        );
        if (response.statusCode === 200) {
          // await firebase.storage().ref(`MediaLibrary/images/${imageData.title}`).delete();
          await getData();
          utility.snack('Successfully Delete');
        }
        utility.loading(false);
      }
    }
  };

  const deleteVideo = async (videoData) => {
    if (videoData) {
      if (window.confirm('Do you want to delete?')) {
        utility.loading(true);
        const id = videoData.id;
        const response = await deleteApi(
          `/media-library-Video?selectedRobot=${selectedRobot}`,
          { id, title: videoData.title }
        );
        if (response.statusCode === 200) {
          await getData();
          utility.snack('Successfully Delete');
        }
        utility.loading(false);
      }
    }
  };

  const handleDelete = (item, type) => {
    if (item && type) {
      if (type === 'audios') {
        deleteSound(item);
      } else if (type === 'images') {
        deleteImage(item);
      } else {
        deleteVideo(item);
      }
    }
  };

  const onHeaderRobotSelected = (robotId, robot) => {
    setSelectedRobot(robotId);
    getData(robotId);
    if (robot) setRobotDetail(robot);
  };

  const isUploadBtnShow = () => {
    switch (value) {
      case 'images':
        return ImageList.length === 0;
      case 'videos':
        return VideoList.length === 0;
      case 'audios':
        return soundList.length === 0;
      default:
        return ImageList.length === 0;
    }
  };
  return (
    <>
      <input
        accept={value.slice(0, -1) + '/*'}
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e) => handleOnClick(e)}
      />
      <Header
        title={t('mediaLibrary.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      {uploadingSatus && (
        <Grid item xs={12}>
          <div className={classes.progressContainer}>
            <Typography>{t('mediaLibrary.uploading')}...</Typography>
            <ProgressBar value={uploadPercentage} />
          </div>
        </Grid>
      )}
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
        >
          <Tab value={'images'} label={t('mediaLibrary.images')} />
          <Tab value={'videos'} label={t('mediaLibrary.videos')} />
          <Tab value={'audios'} label={t('mediaLibrary.audios')} />
          {isDataFetched && !isUploadBtnShow() && (
            <Tooltip
              title={`${t('mediaLibrary.add')} ${t(`mediaLibrary.${value}`)}`}
            >
              <label
                htmlFor="raised-button-file"
                className={classes.tabBarUploadBtnWrapper}
              >
                <Button
                  variant="text"
                  component="span"
                  className={classes.tabBarUploadBtn}
                >
                  <BackupOutlinedIcon style={{ marginRight: 5 }} />
                </Button>
              </label>
            </Tooltip>
          )}
        </Tabs>
      </AppBar>

      {isDataFetched && isUploadBtnShow() && (
        <Box className={classes.uploadBtnContainer}>
          <label htmlFor="raised-button-file">
            <BackupOutlinedIcon
              style={{ fontSize: 120 }}
              className={classes.uploadBtnIcon}
            />
          </label>
          <Typography>{t('mediaLibrary.addMedia')}</Typography>
        </Box>
      )}
      <div>
        {value === 'images' &&
          ImageList &&
          ImageList.length > 0 &&
          ImageList.map((each) => {
            return (
              <MediaLibraryGallery
                item={each}
                key={each.id ? each.id.toString() : ''}
                handleDelete={handleDelete}
                type={value}
              />
            );
          })}
        {value === 'videos' &&
          VideoList &&
          VideoList.length > 0 &&
          VideoList.map((each, index) => {
            return (
              <MediaLibraryGallery
                item={each}
                key={index.toString()}
                handleDelete={handleDelete}
                type={value}
              />
            );
          })}
        {value === 'audios' &&
          soundList &&
          soundList.length > 0 &&
          soundList.map((each, index) => {
            return (
              <MediaLibraryGallery
                item={each}
                key={index.toString()}
                handleDelete={handleDelete}
                type={value}
              />
            );
          })}
      </div>
    </>
  );
}

// #######################################################################
