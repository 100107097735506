import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const ProgressBar = ({ value, style, hidePercentageLabel }) => {
  return (
    <Box display="flex" alignItems="center" style={style}>
      <Box width="100%" mr={hidePercentageLabel ? 0 : 1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      {!hidePercentageLabel && (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired
};

export default ProgressBar;
