import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from "./app/store";
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import { RobotProvider } from './context/RobotContext';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_ro from './translations/ro/common.json';
import common_en from './translations/en/common.json';
import Cookies from 'js-cookie';

//firebase
import initFirebase from './init-firebase';
import Axios from 'axios';
import { UtilityProvider } from './context/UtilityContext';

import './index.css';

Axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
Axios.interceptors.request.use((request) => {
  request.headers['token'] = Cookies.get('token');
  return request;
});
Axios.interceptors.response.use(async (response) => {
  return response.data;
});

initFirebase();
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en // 'common' is our custom namespace
    },
    ro: {
      common: common_ro
    }
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <LayoutProvider>
      <UtilityProvider>
        <UserProvider>
          <RobotProvider>
            <ThemeProvider theme={Themes.default}>
              <CssBaseline />
              <Provider store={store}>
                <App />
              </Provider>
            </ThemeProvider>
          </RobotProvider>
        </UserProvider>
      </UtilityProvider>
    </LayoutProvider>
  </I18nextProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
