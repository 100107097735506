import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Typography, Tooltip, IconButton, Paper, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { getApi, deleteApi } from '../../services/Services';
import Header from '../../components/Header';
import ConfirmModal from '../../components/ConfirmModal';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';

import useStyles from './styles';
import { useSelector } from 'react-redux';

const StoryTellings = (props) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const { history } = props;

  const language = useSelector((state)=> state.language.value);
  const [selectedRobot, setSelectedRobot] = useState();
  const [storytellers, setStorytellers] = useState([]);

  const getStorytellers = async (robotId) => {
    try {
      utility.loading(true);
      const storytellersResponse = await getApi(
        `/storyteller/list?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
        );

      if (storytellersResponse && storytellersResponse.statusCode === 200) {
        setStorytellers(
          storytellersResponse.data.map((item) => {
            return {
              title: item[0],
              presentations: item[1]
            };
          })
        );
      }
      utility.loading(false);
    } catch (err) {
      utility.loading(false);
    }
  };

  useEffect(() => {
    if (selectedRobot) {
      getStorytellers();
    }
  }, [selectedRobot, language]);

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    //
    getStorytellers(robotId)
  };

  const deleteStoryteller = async (title) => {
    try {
      utility.loading(true);
      await deleteApi(`/storyteller?selectedRobot=${selectedRobot}&language=${language}`, {
        title
      }).then(async (resp) => {
        console.error(resp)
        try {
            if(resp.statusCode == 200){
              const {data} = await getApi(`/allintents?selectedRobot=${selectedRobot}`);
              let re = new RegExp(`(^${language.toLowerCase()}_)|(^${language.toUpperCase()}_)`, 'gi');
              for (let intent of data.intents) {
                if (intent.name.replace(/(PRESENTATION)/gi, '').trim().replace(re, '') == title) {
                  await deleteApi(`/intents?selectedRobot=${selectedRobot}`, {
                      intentids: [intent.id]
                  }).then((resp) => {
                    console.log('resp ->', resp);
                  })
                  break;
                }
              }
            }
          } catch(e) {
            console.error(e);
          } finally {
            getStorytellers();
            utility.loading(false);
          }
      });
    // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <>
      <Header
        title={t('storytelling.listTitle')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <Typography
        size="md"
        align="left"
        weight="medium"
        className={classes.pageTitle}
      >
        {t('storytelling.title')}
      </Typography>
      <Tooltip
        title={t('storytelling.addNewStoryteller')}
        style={{ width: 'fit-content' }}
      >
        <Link
          to={{
            pathname: 'create'
          }}
        >
          <IconButton>
            <AddCircleOutline />
          </IconButton>
        </Link>
      </Tooltip>
      <Paper className={classes.storytellingListWrapper}>
        <Typography size="sm" align="left">
          {t('storytelling.recentAdded')}
        </Typography>
        <Box className={classes.storytellingList}>
          {storytellers.map((item) => (
            <Box key={item.title} className={classes.storytellerItemCard}>
              <Box className={classes.storytellerItemThumbnail}>
                <ConfirmModal
                  confirmText={t('storytelling.deleteStorytellerConfirm')}
                  confirmAction={() => deleteStoryteller(item.title)}
                >
                  {(toggleModal) => (
                    <IconButton
                      style={{ position: 'absolute', top: 2, left: 2 }}
                      onClick={() => toggleModal()}
                    >
                      <DeleteIcon style={{ color: '#fff', filter: 'drop-shadow(2px 2px 7px #44444444)'}} />
                    </IconButton>
                  )}
                </ConfirmModal>
                <img
                  src={item.presentations[0].imageUrl}
                  alt="Presentations thumbnail"
                  style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/app/storytellings/edit/${item.title}`);
                  }}
                />
              </Box>
              <Box className={classes.storytellerItemTitleWrapper}>
                <Typography align="center" className={classes.storytellerTitle}>
                  {item.title}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    </>
  );
};

export default withRouter(StoryTellings);
