import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden'
  },
  content: {
    flexGrow: 1,
    width: `calc(100vw - 240px)`,
    minHeight: 'calc(100vh - 75px)',
    marginTop: '70px',
    padding: '24px'
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    // height: `calc(100vh - 70px)`,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  fakeToolbar: {
    ...theme.mixins.toolbar
  }
}));
