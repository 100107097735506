import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  ModalBtn: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff !important',
    marginTop: '20px',
    zIndex: 99
  },
  btnDiv: {
    marginTop: '30px'
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 80,
    transition: 'background 300ms ease-out',
    '& span': {
      fontSize: 10,
      padding: 7
    },
    '& svg': {
      transition: 'all 300ms ease-out',
      fontSize: '1.3rem'
    },
    '&:hover': {
      backgroundColor: '#d9d9d99c',
      color: '#000'
    }
  },
  addIconbtn: {
    padding: '5px 0'
  }
}));

const SimpleModal = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const { icon, btnType } = props;

  const { t } = useTranslation('common');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveData = () => {
    props.handle();
    setOpen(false);
  };

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      {btnType === 'button' ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          className={classes.ModalBtn}
          style={{ marginBottom: '20px' }}
        >
          {props.add}
        </Button>
      ) : (
        <div className={classes.addIconbtn} onClick={handleOpen}>
          <Tooltip title={props.add}>
            <IconButton>{icon}</IconButton>
          </Tooltip>
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {props.body}
          <div className={classes.btnDiv}>
            <Button onClick={handleClose} variant="contained">
              {t('close')}
            </Button>
            <Button
              onClick={saveData}
              variant="contained"
              color="secondary"
              style={{ marginLeft: '20px' }}
            >
              {' '}
              {t('save')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

SimpleModal.propTypes = {
  btnType: PropTypes.string,
  icon: PropTypes.objectOf(PropTypes.any)
};

SimpleModal.defaultProps = {
  btnType: 'button',
  icon: null
};

export default SimpleModal;
