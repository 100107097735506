import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useStyles from './styles';
import { 
    Avatar,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Backup, Delete } from '@material-ui/icons';
import Header from '../../components/Header';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import defaultImage from '../../images/default-image.png';
import clsx from 'clsx';
import * as Yup from 'yup';
import { getApi, postApi } from '../../services/Services';
import { getCustomID } from '../../utills';
import ConfirmModal from '../../components/Utility/ConfirmModal';

const initialValues = {
    name: '',
    image: null,
    images: null
};

const validationSchema = Yup.object().shape({
    name: Yup.string().label('name').required('Required'),
});

export default function FaceRecognitionForm(props) {
    const { history, match } = props;
    const classes = useStyles();
    const { t } = useTranslation('common');
    const [type, setType] = useState('add');
    const dispatch = useUtilityDispatch();
    const utility = useUtility(dispatch);
    const [selectedRobot, setSelectedRobot] = useState('');
    const [imagesList, setImagesList] = useState([]);
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);
    const [imageUUID, setImageUUID] = useState("");
    const [deleteImageUUID, setDeleteImageUUID] = useState("");
    const [toggleModal, setToggleModal] = useState(false);
    const [collectionUUID, setCollectionUUID] = useState("");
    const { params } = match;
    const { id } = params;
    const uuidPerson = id;
    const [personFacesInfo, setPersonFacesInfo] = useState({});

    useEffect(() => {
        if (uuidPerson) {
            const id = getCustomID();
            formik.setFieldValue('uuid', id);
            getPersonFaces();
        }
    }, [uuidPerson]);

    const onHeaderRobotSelected = (robotID, robot) => {
        setSelectedRobot(robotID);
        setCollectionUUID(robot.collection_token);

        if (uuidPerson) {
            getfaceRecognitionInfo(robotID, uuidPerson);
        }
    };
    
    const getfaceRecognitionInfo = async (robotID, uuid) => {
        return await getApi(`/faceRecognition/${uuid}?selectedRobot=${robotID}`)
            .then((response) => {
                if (response.statusCode === 200) {
                    setPersonFacesInfo(response.data)
                }
            })
            .catch((error) => {
            console.error(error);
            });
    };

    const personInfo = async (personUUID, userData) => {
        if (selectedRobot) {
            try {
            const resp = await postApi(
                `/faceRecognition?selectedRobot=${selectedRobot}&personUUID=${personUUID}`,
                userData
            );
            if (resp.statusCode === 200) {
                utility.snack('Successfully Save ');
            }
            } catch (e) {
            utility.snack(e.response.data.message, 'error');
            }

            utility.loading(false);
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
          utility.loading(true);
          if (type === 'add') {
            try {
                await fetch(`https://api.luxand.cloud/collection/${collectionUUID}`,
                {
                    method: "GET",
                    headers: {
                        "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                    },
                }
                ).then(res => res.json())
                .then(res => {
                    if(res.uuid) {
                        const formData = new FormData();
                        formData.append('name', values.name);
                        formData.append('photos', values.image);
                        formData.append('store', +(!!values.image) + '');
                        formData.append('collections', res.uuid);
                            fetch(`https://api.luxand.cloud/v2/person`, {
                                method: "POST",
                                headers: {
                                    "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                                },
                                body: formData
                            }).then(res => res.json())
                            .then(res => {
                                if (res.status === "success") {
                                    let photoData = new FormData();
                                    photoData.append('photo', values.image) 
                                    utility.snack(
                                    'Persons has been successfully added.',
                                    'success'
                                    );
                                    fetch(`https://api.luxand.cloud/photo/detect`, {
                                        method: "POST",
                                        headers: {
                                            "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                                        },
                                        body: photoData
                                    }).then(res => res.json())
                                    .then(resPhoto => {
                                        let image = {
                                            img: res.faces[0].uuid,
                                            age: resPhoto[0].age,
                                            date: Date.now()
                                        };
                                        personInfo(res.uuid, {gender: resPhoto[0].gender.value, images: [image]})
                                    })

                                    setTimeout(() => {
                                        history.push('/app/faceRecognition');
                                    }, 1000);
                                } else {
                                    utility.snack('Something went wrong please try again.', 'error');
                                }
                            });
                        }
                    utility.loading(false);
                })
            } catch (e) {
              utility.loading(false);
              utility.snack('Something went wrong please try again.', 'error');
            }
          } 
          else {
            try {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('uuid', uuidPerson);

                fetch(`https://api.luxand.cloud/person/${uuidPerson}`, {
                    method: "PUT",
                    headers: {
                        token : process.env.REACT_APP_LUXAND_API_TOKEN,
                    },
                    body: formData
                }).then(res => res.json())
                .then(res => {
                    if (res.status === 'success') {
                        utility.snack(
                          'Person has been successfully updated.',
                          'success'
                        );
                        setTimeout(() => {
                          history.push('/app/faceRecognition');
                        }, 1000);
                      } 
                      else {
                        utility.snack('Something went wrong please try again.', 'error');
                      }
                });

                if (values.image !== null) {
                    formData.append('photo', values.image);
                    formData.append('store', 1);

                    fetch(`https://api.luxand.cloud/person/${uuidPerson}/${imageUUID}`,{
                        method: "PUT",
                        headers: {
                            token : process.env.REACT_APP_LUXAND_API_TOKEN,
                        },
                        body: formData
                    }).then(res => res.json())
                    .then(res => {
                        if (res.status === "success") {
                            let photoData = new FormData();
                            photoData.append('photo', values.image) 
                            utility.snack(
                              'Person has been successfully updated.',
                              'success'
                            );

                            fetch(`https://api.luxand.cloud/photo/detect`, {
                                method: "POST",
                                headers: {
                                    "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                                },
                                body: photoData
                            }).then(res => res.json())
                            setTimeout(() => {
                              history.push('/app/faceRecognition');
                            }, 1000);
                        } 
                        else {
                            utility.snack('Something went wrong please try again.', 'error');
                        }
                    });
                }
                if (values.images != null) {
                    let detectList = [];
                    for (let img of values.images) {
                        detectList.push(detectPhoto(uuidPerson, img));
                    }
                    Promise.all(detectList).then(values => {
                        setPersonFacesInfo(prev => {
                            prev.images = [...prev.images, ...values];

                            return prev
                        });

                        personInfo(uuidPerson, personFacesInfo)
                    }).catch(e => {
                        
                    })
                }
            } catch (e) {
                utility.loading(false);
                utility.snack('Something went wrong please try again.', 'error');
            }
          }
          utility.loading(false);
        },
    });

    const detectPhoto = (uuidPerson, img) => {
        let formData = new FormData();
        formData.append('store', 1);
        formData.append("photos", img);

        return new Promise(resolve => {
            fetch(`https://api.luxand.cloud/v2/person/${uuidPerson}`, {
                method: "POST",
                headers: {
                    token : process.env.REACT_APP_LUXAND_API_TOKEN,
                },
                body: formData
                }).then(res => res.json())
                .then(res => {
                    if (res.status === "success") {
                        let imageForm = new FormData();
                        imageForm.append('store', 1);
                        imageForm.append("photo", img);

                        fetch(`https://api.luxand.cloud/photo/detect`, {
                            method: "POST",
                            headers: {
                                "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                            },
                            body: imageForm
                        }).then(res => res.json())
                        .then(resPhoto => {
                            utility.snack(
                                'Person has been successfully updated.',
                                'success'
                            );

                            resolve({
                                img: res.added_faces[0].uuid,
                                age: resPhoto[0].age,
                                date: Date.now()
                            })
                        });
                    }  else {
                        utility.snack('Something went wrong please try again.', 'error');
                    }
            }) 
        })
    }

    const handleDelete = () => {
        setToggleModal(false)
        if (uuidPerson) {
            try {
                fetch(`https://api.luxand.cloud/person/${uuidPerson}/${deleteImageUUID}`, {
                    method: "DELETE",
                    headers: {
                        "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                    },
                }).then(res => res.json())
                .then(res => {
                    if (res.status === "success") {
                        setPersonFacesInfo(prev => {
                            prev.images = prev.images.filter(img => {
                                if (img.img != deleteImageUUID) {
                                    return img;
                                }
                            });

                            return prev
                        });

                        personInfo(uuidPerson, personFacesInfo)
                        getPersonFaces()
                    }
                })
            } catch (e) {}
        }
    };

    const handleSelectFillist = (fileList) => {
        const imageList = [];
        for (let file of fileList) {
            imageList.push(file);
        }

        setImagesList(imagesList.concat(imageList));

        formik.setFieldValue('images', fileList);
    };

    const handleSelect = (file) => {
        setImage(file);

        formik.setFieldValue('image', file);
    };

    const getPersonFaces = async () => {
        if (uuidPerson) {
            setType('edit');
            try {
                fetch(`https://api.luxand.cloud/v2/person/${uuidPerson}`, {
                    method: "GET",
                    headers: {
                        "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
                    },
                }).then(res => res.json())
                .then(res => {
                    formik.setFieldValue('name', res.name);
                    formik.setFieldValue('image', res.faces[0].url);
                    setImages(res.faces.slice(1));
                    setImageUUID(res.faces[0].uuid);
                })
            } catch (e) {}
        }
    }

    return (
        <div>
            <Header
                title={t('faceRecognition.title')}
                onHeaderRobotSelected={onHeaderRobotSelected}
                showRobotList={true}
            />
            <form
                className="presenter-form"
                style={{ width: '100%', marginTop: 20 }}
                onSubmit={formik.handleSubmit}
            >
            <Grid container spacing={4}>
                <Grid item xs={12} md={8} lg={8}>
                    <Paper style={{ padding: 15, width: '100%' }}>
                        <h2 id="simple-modal-title">{t('faceRecognition.title')}</h2>
                        <div className={classes.personFace}>
                            <ListItem className={classes.concenterAlign}>
                                <div className={classes.concenterAlign}>
                                    <Avatar
                                        src={image ? URL.createObjectURL(image) : (formik.values.image ? formik.values.image : defaultImage)}
                                        variant="circular"
                                        style={{
                                        width: 100,
                                        height: 100
                                        }}
                                    />
                                </div>
                            </ListItem>
                            <ListItem
                            className={clsx([
                                classes.concenterAlign,
                                classes.noHorizontalPadding
                            ])}
                            >
                                <label
                                htmlFor="contained-button-face"
                                >
                                <div className={type === 'edit' ? classes.hiddenComponent : ''}>
                                    <Avatar>
                                        <Backup />
                                    </Avatar>
                                    <input
                                    id="contained-button-face" 
                                    type="file" 
                                    accept="image/png, image/jpeg"
                                    onChange={(e) => handleSelect(e.target.files[0])}
                                    style={{
                                        display: 'none'
                                    }}
                                    />
                                </div>
                                </label>
                            </ListItem>
                        </div>
                        {type === 'edit' ?
                        <div>
                            <ListItem className={classes.concenterAlign}>
                                {images.map(img => {
                                    return <div key={img.uuid} className={classes.personFace}>
                                        <Avatar
                                        src={img.url}
                                        variant="circular"
                                        style={{
                                        width: 100,
                                        height: 100
                                        }}/>
                                        <div className={classes.hiddenDeleteIcon }>
                                            <IconButton
                                                style={{ position: 'absolute', top: 2, left: 2 }}
                                                onClick={() => {
                                                    setDeleteImageUUID(img.uuid)
                                                    setToggleModal(true)
                                                }}
                                            >
                                                <Delete style={{ color: '#fff', filter: 'drop-shadow(2px 2px 7px #44444444)'}} />
                                            </IconButton>
                                        </div> 
                                    </div>
                                })
                                }
                                {imagesList.map((item, i) =>
                                <div className={classes.personFace} key={i + item.name}>
                                    <Avatar
                                        src={item ? URL.createObjectURL(item) : (formik.values.images ? formik.values.images : defaultImage)}
                                        variant="circular"
                                        style={{
                                        width: 100,
                                        height: 100
                                        }}
                                    />
                                </div>
                                )}
                            </ListItem>
                            <ListItem
                            className={clsx([
                                classes.concenterAlign,
                                classes.noHorizontalPadding
                            ])}
                            >
                                <label htmlFor="contained-button-faces">
                                    <Avatar >
                                        <Backup />
                                    </Avatar>
                                    <input
                                        id="contained-button-faces" 
                                        type="file" 
                                        multiple="multiple"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => handleSelectFillist(e.target.files)}
                                        style={{
                                            display: 'none'
                                        }}
                                    />
                                </label>
                            </ListItem>
                        </div>
                        : ''
                        }
                        <TextField
                            id="name"
                            name="name"
                            label={t('faceRecognition.name')}
                            variant="outlined"
                            margin="normal"
                            type="text"
                            fullWidth
                            error={
                            formik.errors.name && formik.touched.name
                                ? true
                                : null
                            }
                            helperText={
                            formik.errors.name && formik.touched.name
                                ? formik.errors.name
                                : ''
                            }
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                        />
                        <ListItem>
                            <Button variant="contained" color="primary" type="submit">
                                Save
                            </Button>
                        </ListItem>
                    </Paper>
                </Grid>
            </Grid>
            </form>
            <ConfirmModal
                open={toggleModal}
                onOk={() => handleDelete()}
                onCancel={() => setToggleModal(false)}
                onClose={() => setToggleModal(false)}
            >
                <List>
                    <ListItem>
                        <div>{t('storytelling.deleteStorytellerConfirm')}</div>
                    </ListItem>
                </List>
            </ConfirmModal>
        </div>
    )
}