import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useStyles from './styles';
import { getApi } from '../../services/Services';
import { SetSeletedRobot, GetSeletedRobot } from '../../utills';
import { useRobotDispatch, updateRobot } from '../../context/RobotContext';


export default function RobotList({ onHeaderRobotSelected, disabled }) {
  const classes = useStyles();
  const [robots, setRobots] = useState([]);
  const [selectedRobot, setSelectedRobot] = useState('');
  const [showDropDownList, setShowDropDownList] = useState(false);
  const robotDispatch = useRobotDispatch();

  const filterRobotForUser = async (getRobotListData, userID) => {
    userID = userID.replace(/"/, '');

    const fillterRobot = [];
    getRobotListData.forEach((item) => {
      if (item.selectedUsers && item.selectedUsers.length > 0) {
        item.selectedUsers.forEach((selectedUsersData) => {
          if (selectedUsersData.uid === userID) {
            fillterRobot.push(item);
          }
        });
      }
    });
    return fillterRobot;
  };

  const getRobotList = async () => {
    const response = await getApi('/robotlist');
    let fillterRobot = [];
    if (response && response.statusCode === 200 && response.data.length) {
      // Todo In every component get selected robot from robotContext instead of
      // manage selected robot in every component state.
      setRobots(response.data);
      const robotContextData = {
        allRobots: response.data,
        selectedRobotDetails: {}
      };
      const getID = GetSeletedRobot();
      const role = Cookies.get('isAdmin') || false;
      if ((!role || role === 'false' || role === false) && !getID) {
        // for user
        let userID = Cookies.get('u_id') || '';
        userID = userID.replace(/"/, '');
        fillterRobot = await filterRobotForUser(response.data, userID);
        if (fillterRobot && fillterRobot[0] && fillterRobot[0].id) {
          onHeaderRobotSelected(fillterRobot[0].id, fillterRobot[0]);
          SetSeletedRobot(fillterRobot[0].id);
          setSelectedRobot(fillterRobot[0].id);
          robotContextData.selectedRobotDetails = fillterRobot[0];
        }
        if (fillterRobot.length > 1) {
          setShowDropDownList(true);
        }
      } else {
        // for admin
        setShowDropDownList(true);
        fillterRobot = response.data;
      }
      if (getID) {
        const selectedRobotFilter = response.data.filter(
          (item) => item.id === getID
        );
        setSelectedRobot(selectedRobotFilter[0].id);
        onHeaderRobotSelected(
          selectedRobotFilter[0].id,
          selectedRobotFilter[0]
        );
        robotContextData.selectedRobotDetails = selectedRobotFilter[0];
      }

      setRobots(fillterRobot);
      updateRobot(robotDispatch, robotContextData);
    }
  };

  useEffect(() => {
    getRobotList();
  }, []);

  const onRobotChange = (e) => {
    const value = e.target.value;
    const fillterRobot = robots.filter((item) => item.id === value);
    setSelectedRobot(value);
    onHeaderRobotSelected(value, fillterRobot[0]);
    SetSeletedRobot(value);
  };

  const renderRobotList = () => {
    if (robots.length > 0) {
      return robots.map((robot) => (
        <MenuItem value={robot.id} key={robot.id}>
          {robot.robotName}
        </MenuItem>
      ));
    }
  };

  if (!showDropDownList) return null;

  return (
    <FormControl className={classes.robotListContainer}>
      <InputLabel id="dialogFlowAgent">Robot/DialogFlow Agent</InputLabel>
      <Select
        labelId="dialogFlowAgent"
        id="dialogFlowAgent"
        name="dialogFlowAgent"
        value={selectedRobot}
        onChange={onRobotChange}
        disabled={disabled}
      >
        {renderRobotList()}
      </Select>
    </FormControl>
  );
}

RobotList.propTypes = {
  onHeaderRobotSelected: PropTypes.func,
  disabled: PropTypes.bool
};

RobotList.defaultProps = {
  onHeaderRobotSelected: () => {},
  disabled: false
};
