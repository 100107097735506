import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  progressContainer: {
    root: {
      width: '100%'
    }
  },
  tabBarUploadBtn: {
    color: 'white'
  },
  tabBarUploadBtnWrapper: {
    margin: 'auto',
    marginRight: 50
  },
  uploadBtnContainer: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  uploadBtnIcon: {
    cursor: 'pointer'
  }
}));
