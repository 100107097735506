import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Modal from '../../components/Modal/Modal';
import Table from '../tables/Tables';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import * as axios from 'axios';
import useStyles from './styles';
import { PanelHeader } from './components/actionbar';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { AddCircleOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import { deleteApi } from '../../services/Services';
import { useSelector } from 'react-redux';

const standardDialogData = [
  ['Special Dialog Functions', '30.09.2020'],
  ['#AgainstCovid19 by humanizing', '30.09.2020'],
  ['Pepper Functions', '23.09.2020'],
  ['Pepper Functions', '30.05.2020'],
  ['Pepper Random Sentences', '10.11.2020'],
  ['Pepper Greetings', '12.02.2020'],
  ['Pepper Random Questions', '30.09.2020'],
  ['Pepper Weather', '30.09.2020'],
  ['Pepper Entertainment', '30.09.2020'],
  ['Pepper Usecases', '30.01.2020'],
  ['Pepper Five', '30.10.2020']
];

export default function Dialogs(props) {
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const setLoading = (state) => {
    utility.loading(state);
  };

  const classes = useStyles();
  const [inputone, setInputOne] = useState(null);
  const query = window.location.href;
  const param = query.indexOf('standard') !== -1 ? 'standard' : 'custom';
  const { t } = useTranslation('common');
  const [dialogTitle, setdialogTitle] = useState('');
  const [checkAllStatus, setCheckAllStatus] = useState(false);
  const [customData, setcustomData] = useState([]);
  const [dialogs, upldateDialogs] = useState([]);
  const [selectedDialogs, setSelectedDialogs] = useState([]);
  const [standardData] = useState(standardDialogData);
  const [selectedRobot, setSelectedRobot] = useState();
  const language = useSelector(state => state.language.value);
  
  const getDialogList = (robotId) => {
    setSelectedDialogs([]);
    setLoading(true);
    axios
      .get(`/allintents?selectedRobot=${robotId ? robotId : selectedRobot}`)
      .then((response) => {
        setLoading(false);
        const dgs = response.data.intents;
        if (dgs) {
          const customDialogs = dgs.map((intent) => {
            const id = intent.id;
            return {
              id,
              title: intent.name,
              project: 0
            };
          });
          upldateDialogs(customDialogs);
          setcustomData(customDialogs);
        }
        // handle success
      })
      .catch(function () {
        setLoading(false);
        upldateDialogs([]);
        setcustomData([]);
      })
      .then(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    const inp = window.document
      .getElementById('datatable')
      .querySelectorAll('input[type="checkbox"]');
    const io = inp[0];
    setInputOne(io);
  }, []);

  const columnsCustom_enhanced = [
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      name: 'title',
      label: t('dialog.title'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <Link
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#49b9e9'
              }}
              to={{
                pathname: 'edit/' + customData[row.rowIndex].id
              }}
            >
              {row.rowData[0]}
            </Link>
          );
        },
      },
    }
  ];
  const columnsStandard = [
    {
      name: t('dialog.title'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span style={{ color: '#49b9e9' }}>{value}</span>;
        }
      }
    },
    {
      name: t('date')
    }
  ];

  const ModalBody = (
    <div>
      <h2 id="simple-modal-title">{t('dialog.modaltitle')}</h2>
      <TextField
        label={t('chooseDialogName')}
        id="title"
        margin="normal"
        type="text"
        fullWidth
        value={dialogTitle}
        onChange={(e) => setdialogTitle(e.target.value)}
      />
    </div>
  );

  const saveData = () => {
    setLoading(true);
    axios
      .post(`/intent?selectedRobot=${selectedRobot}`, {
        name: dialogTitle
      })
      .then((response) => {
        if (response.statusCode === 200) {
          const id = response.data.id;
          const url = 'edit/' + id;
          props.history.push(url);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  const edit = null;

  let tableTag;

  const contentPlaceHolder = <p>{t('notDataFound')}</p>;

  if (param === 'custom') {
    tableTag = (
      <div id="datatable">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EnhancedDataTable
              onSelect={setSelectedDialogs}
              contentPlaceHolder={contentPlaceHolder}
              rows={customData}
              columns={columnsCustom_enhanced}
              rowsPerPageOptions={paginationRowPerArr}
              multiselect={false}
              hideCheckbox={false}
              responsive='standard'
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    tableTag = (
      <Table
        title={t('dialog.title')}
        data={standardData}
        columns={columnsStandard}
      />
    );
  }

  const onDelete = () => {
    if (window.confirm('Do you want to delete?')) {
      const titles = [];
      for (let selected_id of selectedDialogs) {
        for (let {id, title} of customData) {
          if (id == selected_id) {
            titles.push(title);
            break;
          }
        }
      }

      setLoading(true);
      let re = new RegExp(`(^en_)|(^EN_)|(^ro_)|(^RO_)`, 'gi');
      axios
        .delete(`/intents?selectedRobot=${selectedRobot}`, {
          data: {
            intentids: selectedDialogs
          }
        })
        .then((response) => {
          const status = response.statusCode;
          if (status === 403) {
            alert('Authorization error');
            return false;
          } else if (status === 200) {
            for (let t of titles) {
              let title = t.replace(/(PRESENTATION)/gi, '').trim();
              let lang = null;

              if (title.match(re)) {
                lang = title.split('_')[0].toLowerCase();
              }

              deleteApi(`/storyteller?selectedRobot=${selectedRobot}&language=${lang||language}`, {
                title: title.replace(re, '')
              })
            }
          }
          getDialogList();
        })
        .catch(function (error) {
          if (
            error.toString() === 'Error: Request failed with status code 403'
          ) {
            utility.snack(
              'PERMISSION_DENIED: IAM permission error',
              'error',
              5000
            );
          }
        })
        .then(function () {
          setLoading(false);
          // always executed
        });
    }
  };

  const onSelectAll = () => {
    setCheckAllStatus(!checkAllStatus);
    inputone.click();
  };

  const onDuplicate = () => {
    setLoading(true);
    axios
      .post(`/dupicate-intents?selectedRobot=${selectedRobot}`, {
        intentids: selectedDialogs
      })
      .then((response) => {
        const status = response.statusCode;
        if (status === 403) {
          alert('Authorization error');
          return false;
        }
        getDialogList();
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        if (error.toString() === 'Error: Request failed with status code 403') {
          utility.snack(
            'PERMISSION_DENIED: IAM permission error',
            'error',
            5000
          );
        }
      })
      .then(function () {
        setLoading(false);
        // always executed
      });
  };

  const onCancelSearch = () => {
    setcustomData(dialogs);
  };

  const onSearch = (e) => {
    const search_text = e.target.value;
    const list = dialogs.filter(
      (item) => item.title.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
    );
    if (search_text === '') {
      return onCancelSearch();
    }
    setcustomData(list);
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getDialogList(robotId);
  };

  let modalTag;
  if (param === 'custom') {
    modalTag = (
      <div className={classes.actionbar}>
        <Modal
          btnType="icon"
          icon={<AddCircleOutline />}
          body={ModalBody}
          add={t('dialog.modaltitle')}
          handle={saveData}
        />
        <div className={classes.actions} style={{ width: '60%' }}>
          <PanelHeader
            onCancelSearch={onCancelSearch}
            onSearch={onSearch}
            bgColor="transparent"
            // onActive={props.onActiv}
            count={customData.length}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            isSelected={selectedDialogs.length > 0}
            onSelectAll={onSelectAll}
            isAllSelected={checkAllStatus}
            settings={{
              search: true,
              duplicate: true,
              delete: true,
              all: true
              // addNew: false,
              // active: false,
              // search: true,
              // all: false
            }}
          />
        </div>
      </div>
    );
  } else {
    modalTag = '';
  }

  let content = null;

  if (edit) {
    content = edit;
  } else {
    content = <AppBar position="static" className={classes.barBack} />;
  }

  return (
    <>
      <Header
        title={t('dialog.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />

      {/* <PageTitle title={t("dialog.title")} /> */}

      {content}

      {!edit ? modalTag : null}
      {!edit ? tableTag : null}
    </>
  );
}
