import React, {useState, useCallback, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { getApi, deleteApi } from '../../services/Services';
import {
  IconButton,
  Tooltip,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { AddCircleOutline, Create, Delete } from '@material-ui/icons';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import ConfirmModal from '../../components/Utility/ConfirmModal';

export default function FaceRecognition(props) {
  const { t } = useTranslation('common');
  const { history } = props;
  const classes = useStyles();
  const [selectedRobot, setSelectedRobot] = useState('');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [personList, setPersonList] = useState([]);
  const [personUUID, setPersonUUID] = useState('');
  const [toggleModal, setToggleModal] = useState(false);
  const [personInfo, setPersonInfo] = useState({});
  const [collectionUUID, setCollectionUUID] = useState("");

  useEffect(() => {
    if (Object.keys(personInfo).length && personList.length) {
      console.log('personInfo >>>', personInfo);
      console.log('personList >>>', personList);
      setPersonList(prev => {
        for (let person of prev) {
          let personData = personInfo[person.uuid];

          if (personData) {
            person.gender = personData.gender;
  
            if (personData.images.length == 1) {
              person.age = personData.images[0].age;
            } else {
              let ages = personData.images.map(img => {
                return img.age;
              }).sort()
  
              person.age = `${ages[0]} - ${ages.slice(-1)}`;
            }
          }
        }

        return prev;
      });
    }
  }, [personInfo, personList]);

  useEffect(() => {
    if (collectionUUID) {
      getCollection(); 
    }
  }, [collectionUUID]);

  const getCollection = async () => {
    utility.loading(true);

      if(collectionUUID){
        fetch(`https://api.luxand.cloud/collection/${collectionUUID}`,
        {
          method: "GET",
          headers: {
            "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
          },
        }
        ).then(res => res.json())
        .then(res => {
          if (res && res.persons) {
            const customPersons = res.persons.map((person) => {
              return {
                uuid: person.uuid,
                name: person.name,
              }
            })

            setPersonList(customPersons);
          }
  
          utility.loading(false);
        })
        .catch(err => {
          utility.loading(false)  
        })
      } else {
        utility.loading(false)
      }
  };

  const removePerson = () => {
    setToggleModal(false)
    if (personUUID) {
      try {
        fetch(`https://api.luxand.cloud/person/${personUUID}`, {
          method: "DELETE",
          headers: {
            "token" : process.env.REACT_APP_LUXAND_API_TOKEN,
          },
        }).then(res => res.json())
        .then(res => {
          if (res.status === "success") {
            deleteApi(`faceRecognition/${personUUID}?selectedRobot=${selectedRobot}`)
            getCollection()
          }
        })
      } catch (e) {}
    }
  };

  const getPersons = async (selectedRobot) => {
    return await getApi(`/faceRecognition?selectedRobot=${selectedRobot}`)
      .then((response) => {
      setPersonInfo(response.data)
      if (response.statusCode === 200 && response.data) {
        return response.data;
      }
      })
      .catch((error) => {
      console.error(error);
      });
  };

  const onHeaderRobotSelected = (robotID, robot) => {
    setSelectedRobot(robotID);
    setCollectionUUID(robot.collection_token);
    getPersons(robotID)
  };

  const columns = [
    {
      label: t('faceRecognition.name'),
      name: 'person name',
      options: {
        customBodyRender: (_, value) => {
          return <Typography>{personList[value.rowIndex].name}</Typography>;
        }
      }
    },
    {
      label: 'Age',
      name: 'person age',
      options: {
        customBodyRender: (_, row) => {
          return  <Typography>{personList[row.rowIndex].age || ""}</Typography>     
        }
      }
    },
    {
      label: 'Gender',
      name: 'person gender',
      options: {
        customBodyRender: (_, row) => {
          return  <Typography>{personList[row.rowIndex].gender || ""}</Typography> 
        }
      }
    },
    {
      name: t('action'),
      numeric: false,
      sortable: false,
      disablePadding: false,
      label: t('action'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <div className="table-actions" style={{ display: 'flex' }}>
            <Tooltip title={t('faceRecognition.edit')}>
              <IconButton
                aria-label="Edit"
                color="primary"
                onClick={() => {
                  history.push('faceRecognitionForm/edit/' + personList[row.rowIndex].uuid)
                }}
              >
                <Create fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('delete')}>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => {
                  setPersonUUID(personList[row.rowIndex].uuid)
                  setToggleModal(true)
                }}
              >
                <Delete fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
          );
        }
      }
    }
  ];

  const onSelect = useCallback(() => {}, []);

  return (
    <>
      <Header
        title={t('faceRecognition.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip
            title={t('faceRecognition.title')}
          >
            <IconButton>
              <Link 
                  to={{
                    pathname: 'faceRecognitionForm/create'
                  }}
                >
                <AddCircleOutline />
              </Link>
            </IconButton>
          </Tooltip>
        </div>
      <ConfirmModal
        open={toggleModal}
        onOk={() => removePerson()}
        onCancel={() => setToggleModal(false)}
        onClose={() => setToggleModal(false)}
      >
        <List>
          <ListItem>
              <div>{t('storytelling.deleteStorytellerConfirm')}</div>
          </ListItem>
        </List>
      </ConfirmModal>
      </div>
      <EnhancedDataTable
        title={t('faceRecognition.title')}
        onSelect={onSelect}
        multiselect={false}
        showCheckbox={false}
        rows={personList}
        columns={columns}
        rowsPerPageOptions={paginationRowPerArr}
        hideCheckbox={true}
        responsive='vertical'
      />
    </>
  );
}
