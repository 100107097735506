import React from 'react';
import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import Confirm from '../components/Alert/Confirm';
import { Alert } from '@material-ui/lab';

export const Snacky = (props) => {
  const { vertical, horizontal, onClose, type, text, open } = props;
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={onClose}
      message=""
      key={vertical + horizontal}
    >
      <Alert onClose={onClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
};

Snacky.defaultProps = {
  vertical: 'top',
  horizontal: 'right',
  type: 'success'
};

const UtilityStateContext = React.createContext();
const UtilityDispatchContext = React.createContext();

export const ACTIONS = {
  PAGE_LOADING: 'PAGE_LOADING',
  PAGE_UNLOADING: 'PAGE_UNLOADING',
  OPEN_CONFIRM: 'OPEN_CONFIRM',
  CLOSE_CONFIRM: 'CLOSE_CONFIRM',
  SNACK: 'SNACK_OPEN'
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

function utilityReducer(state, action) {
  switch (action.type) {
    case ACTIONS.PAGE_LOADING:
      return { ...state, pageLoading: true };
    case ACTIONS.PAGE_UNLOADING:
      return { ...state, pageLoading: false };
    case ACTIONS.OPEN_CONFIRM:
      return { ...state, openConfirm: true };
    case ACTIONS.CLOSE_CONFIRM:
      return { ...state, openConfirm: false };
    case ACTIONS.SNACK:
      return { ...state, snack: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UtilityProvider({ children }) {
  const classes = useStyles();
  const [state, dispatch] = React.useReducer(utilityReducer, {
    pageLoading: false,
    snack: {
      open: false,
      type: 'success',
      text: ''
    },
    confirm: {
      title: 'Confirm',
      onOk: () => {},
      open: false
    }
  });

  const utility = useUtility(dispatch);

  const handleClose = () => {
    utility.loading(false);
  };

  const { confirm, snack } = state;

  return (
    <UtilityStateContext.Provider value={state}>
      <UtilityDispatchContext.Provider value={dispatch}>
        {children}
        <div>
          <Backdrop
            className={classes.backdrop}
            open={state.pageLoading}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Confirm
            open={confirm.open}
            title={confirm.title}
            onOk={confirm.onOk}
          />
        </div>
        <Snacky open={snack.open} type={snack.type} text={snack.text} />
      </UtilityDispatchContext.Provider>
    </UtilityStateContext.Provider>
  );
}

function useUtilityState() {
  const context = React.useContext(UtilityStateContext);
  if (context === undefined) {
    throw new Error('useUtilityState must be used within a UtilityProvider');
  }
  return context;
}

function useUtilityDispatch() {
  const context = React.useContext(UtilityDispatchContext);
  if (context === undefined) {
    throw new Error('useUtilityDispatch must be used within a UtilityProvider');
  }
  return context;
}

function useUtility(dispatch) {
  return {
    loading: (value = true) => {
      if (value) {
        dispatch({ type: ACTIONS.PAGE_LOADING });
      } else {
        dispatch({ type: ACTIONS.PAGE_UNLOADING });
      }
    },
    snack: (text, type = 'success', timeout = 1500) => {
      dispatch({
        type: ACTIONS.SNACK,
        payload: {
          open: true,
          text,
          snack: true,
          type
        }
      });

      setTimeout(() => {
        dispatch({
          type: ACTIONS.SNACK,
          payload: {
            open: false,
            text: '',
            snack: false,
            type: 'success'
          }
        });
      }, timeout);
    }
  };
}

export { UtilityProvider, useUtilityState, useUtilityDispatch, useUtility };
