import React from 'react';

import PageTitle from '../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';

export default function ContentTemplates() {
  const { t } = useTranslation('common');

  return (
    <>
      <PageTitle title={t('expertView.shortcodes')} />
    </>
  );
}

// #######################################################################
