import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  addImageIcon: {
    margin: 'auto 0'
  },
  imageWrapper: {
    position: 'relative',
    height: 100
  },
  removeImgBtn: {
    position: 'absolute',
    top: -10,
    right: -10,
    background: '#efeded',
    '&:hover': {
      zIndex: 99,
      opacity: 100,
      background: '#efeded',
    }
  },
  removeImgIcon: {
    fontSize: 13
  }
}));
