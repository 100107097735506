import React, { useReducer } from 'react';

const RobotStateContext = React.createContext();
const RobotDispatchContext = React.createContext();

const robotReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_ROBOTS':
      return { ...state, ...action.data };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const RobotProvider = ({ children }) => {
  const [state, dispatch] = useReducer(robotReducer, {
    allRobots: [],
    selectedRobotDetails: {}
  });

  return (
    <RobotStateContext.Provider value={state}>
      <RobotDispatchContext.Provider value={dispatch}>
        {children}
      </RobotDispatchContext.Provider>
    </RobotStateContext.Provider>
  );
};

const useRobotState = () => {
  const context = React.useContext(RobotStateContext);
  if (context === undefined) {
    throw new Error('useRobotState must be used within a RobotProvider');
  }
  return context;
};

const useRobotDispatch = () => {
  const context = React.useContext(RobotDispatchContext);
  if (context === undefined) {
    throw new Error('useRobotDispatch must be used within a RobotProvider');
  }
  return context;
};

const updateRobot = (dispatch, data) => {
  dispatch({ type: 'UPDATE_ROBOTS', data });
};

export { RobotProvider, useRobotState, useRobotDispatch, updateRobot };
