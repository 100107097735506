import React, { useState } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';
import logo from './logo.png';
import { useUserDispatch, loginUser } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { useSelector } from 'react-redux';
import { putApi } from '../../services/Services';

function Login(props) {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const { t } = useTranslation('common');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('Something is wrong with your login or password :(');
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [loginValidation, setLoginValidation] = useState({
    login: false,
    password: false
  });
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const language = useSelector(state => state.language.value);
  const [forgotPass, setForgotPass] = useState(false)

  const errorTag = error ? (
    <Fade in={error}>
      <Typography color="secondary" className={classes.errorMessage}>
        {errorMessage}
      </Typography>
    </Fade>
  ) : (
    ''
  );

  async function signIn() {
    await loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError
    );
  }

  const onOptionChange = async (e) => {
    if (error) {
      setError(false);
      setErrorMessage('Something is wrong with your login or password :(');
    }
    if (e.key === 'Enter') {
      if (!loginValue) {
        setLoginValidation({password: false, login: true});
        return false;
      }
      if (!passwordValue) {
        setLoginValidation({password: true, login: false});
        return false;
      }
      await signIn();
      setLoginValidation({login: false, password: false})
    }
  }

  const forgotPassClick = async () => {
    if (loginValue){
      utility.loading(true);
      setForgotPass(true)
      await putApi(`/user-password?language=${language}`, { email: loginValue }, {
        "ContentType": "application/json"
      }).then(() => {
        utility.snack(
          'Password reset has been sent successfully.',
          'success'
        );
      })
      .catch((err) => {
        console.log('err.response', err.response);
        utility.snack(err.response.data.message, 'warning');
      })
      .then(() => {
        utility.loading(false);
        console.log('Verification call ended');
      });
    } else if (!loginValue) {
      setError(true);
      setErrorMessage('Something is wrong with your username :(');
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>IRIS Robotics</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography className={classes.quoteText} variant="h1">
              Login to Robot Management System
            </Typography>
            {errorTag}
            <TextField
              label="Email"
              id="email"
              className={loginValidation.login ? classes.notEmpty:''}
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField
                }
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              onKeyDown={onOptionChange}
              margin="normal"
              type="email"
              fullWidth
            />
            {forgotPass===false ?
              <TextField
                label="Password"
                id="password"
                className={loginValidation.password ? classes.notEmpty:''}
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyDown={onOptionChange}
                margin="normal"
                type="password"
                fullWidth
              /> 
            : ''}
            <div className={classes.forgotPassword}>
              <span onClick={forgotPassClick}>
                {t('forgotPassword')}
              </span>
            </div>
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={() => signIn()}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
