import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Modal from '../../components/Modal/Modal';
import { Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import * as axios from 'axios';
import useStyles from './styles';
import { PanelHeader } from './components/actionbar';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { AddCircleOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';

const standardDialogData = [
  ['Special Dialog Functions', '30.09.2020'],
  ['#AgainstCovid19 by humanizing', '30.09.2020'],
  ['Pepper Functions', '23.09.2020'],
  ['Pepper Functions', '30.05.2020'],
  ['Pepper Random Sentences', '10.11.2020'],
  ['Pepper Greetings', '12.02.2020'],
  ['Pepper Random Questions', '30.09.2020'],
  ['Pepper Weather', '30.09.2020'],
  ['Pepper Entertainment', '30.09.2020'],
  ['Pepper Usecases', '30.01.2020'],
  ['Pepper Five', '30.10.2020']
];

export default function KnowledgeBase(props) {
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const setLoading = (state) => {
    utility.loading(state);
  };

  const classes = useStyles();
  const [inputone, setInputOne] = useState(null);
  const query = window.location.href;
  const param = query.indexOf('standard') !== -1 ? 'standard' : 'custom';
  const { t } = useTranslation('common');
  const [knowledgeBaseTitle, setKnowledgeBaseTitle] = useState('');
  const [checkAllStatus, setCheckAllStatus] = useState(false);
  const [customData, setcustomData] = useState([]);
  const [dialogs, upldateDialogs] = useState([]);
  const [selectedDialogs, setSelectedDialogs] = useState([]);
  const [standardData] = useState(standardDialogData);
  const [selectedRobot, setSelectedRobot] = useState('');

  const getKnowledgeBaseList = (robotId) => {
    setSelectedDialogs([]);
    setLoading(true);
    axios
      .get(
        `/allknowledgebase?selectedRobot=${robotId ? robotId : selectedRobot}`
      )
      .then((response) => {
        setLoading(false);
        const dgs = response.data.knowledgeBase;
        if (dgs) {
          const Kb = dgs.map((intent) => {
            // let args = intent.name.split('/');
            const id = intent.id;
            return {
              id,
              title: intent.name,
              project: 0
              // date: moment(intent.create_timestamp).format("MMMM DD, YYYY")
            };
          });
          upldateDialogs(Kb);
          setcustomData(Kb);
        }
        // handle success
      })
      .catch(function (error) {
        setLoading(false);
        // handle error
        console.error(error);
      })
      .then(function () {
        setLoading(false);
        // always executed
      });
  };

  useEffect(() => {
    const inp = window.document
      .getElementById('datatable')
      .querySelectorAll('input[type="checkbox"]');
    const io = inp[0];
    setInputOne(io);
    getKnowledgeBaseList();
  }, []);

  const columns_KnowledgeBase = [
    {
      name: 'title',
      numeric: false,
      disablePadding: false,
      label: t('KnowledgeBase.title'),
      options: {
        customBodyRender: (_, row) => {
          console.log('customData >>>', customData);
          console.log('row >>>', row);
          return (
            <Link
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#49b9e9'
              }}
              to={{
                pathname: 'document/' + customData[row.rowIndex].id
              }}
            >
              {row.rowData[0]}
            </Link>
          );
        },
      },
    }
  ];
  // const columnsStandard = [
  //   {
  //     name: t("KnowledgeBase"),
  //     options: {
  //       filter: false,
  //       customBodyRender: (value) => {
  //         return <span style={{ color: "#49b9e9" }}>{value}</span>;
  //       },
  //     },
  //   },
  //   {
  //     name: t("date"),
  //   },
  // ];

  const ModalBody = (
    <div>
      <h2 id="simple-modal-title">{t('Add KnowledgeBase')}</h2>
      <TextField
        label={t('chooseKnowledgeBaseName')}
        id="title"
        margin="normal"
        type="text"
        fullWidth
        value={knowledgeBaseTitle}
        onChange={(e) => setKnowledgeBaseTitle(e.target.value)}
      />
    </div>
  );

  const saveData = () => {
    setLoading(true);
    axios
      .post(`/createknowledgebase?selectedRobot=${selectedRobot}`, {
        name: knowledgeBaseTitle
      })
      .then((response) => {
        if (response.statusCode === 200) {
          // props.history.push(url);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        setLoading(false);
        getKnowledgeBaseList();
      });
  };

  const edit = null;

  let tableTag;
  // let options = {
  //   onRowClick: (rowData, rowMeta) => { },
  //   onRowSelectionChange: (row, all) => {
  //     setSelectedDialogs(all.map((i) => dialogs[i["dataIndex"]]));
  //   },
  // };

  const onSelect = (selected) => {
    console.log(selected);
    setSelectedDialogs(selected);
  };

  const contentPlaceHolder = <p>{t('notDataFound')}</p>;

  // if (param === "custom") {
  tableTag = (
    <div id="datatable">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <EnhancedDataTable
            onSelect={onSelect}
            contentPlaceHolder={contentPlaceHolder}
            rows={customData}
            columns={columns_KnowledgeBase}
            rowsPerPageOptions={paginationRowPerArr}
            showCheckbox={false}
            multiselect={false}
            hideCheckbox={true}
          />
        </Grid>
      </Grid>
    </div>
  );
  // }
  // else {
  //   tableTag = (
  //     <Table
  //       title={t("dialog.title")}
  //       data={standardData}
  //       columns={columnsStandard}
  //     />
  //   );
  // }

  const onDelete = (d) => {
    // return console.log("onDelete", d)
    // Alert.confirm().then(() => {
    //   console.log("OK")
    // }, () => {
    //   console.log("CANCEL")
    // })
    // return false;
    if (window.confirm('Do you want to delete?')) {
      setLoading(true);
      console.log('selected dialogs', selectedDialogs);
      const knowledgeBaseId = selectedDialogs.map((item) => item.id);
      axios
        .delete(`/deleteknowledgeBase?selectedRobot=${selectedRobot}`, {
          data: {
            knowledgeBaseId: selectedDialogs
          }
        })
        .then((response) => {
          const status = response.statusCode;
          if (status === 403) {
            alert('Authorization error');
            return false;
          }
          getKnowledgeBaseList();
          // handle success
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          console.error(error);
        })
        .then(function () {
          setLoading(false);
          // always executed
        });
    }
  };

  const onSelectAll = () => {
    setCheckAllStatus(!checkAllStatus);
    inputone.click();
  };

  const onDuplicate = () => {
    setLoading(true);
    // console.log('selected dialogs', selectedDialogs);
    // let dialogIds = selectedDialogs.map(item => item.id);
    axios
      .post(`/dupicate-intents?selectedRobot=${selectedRobot}`, {
        intentids: selectedDialogs
      })
      .then((response) => {
        const status = response.statusCode;
        if (status === 403) {
          alert('Authorization error');
          return false;
        }
        // getDialogList();
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .then(function () {
        setLoading(false);
        // always executed
      });
  };

  const onSearch = (e) => {
    const search_text = e.target.value;
    const list = dialogs.filter(
      (item) => item.title.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
    );
    if (search_text === '') {
      return onCancelSearch();
    }
    setcustomData(list);
  };

  const onCancelSearch = () => {
    setcustomData(dialogs);
  };

  let modalTag;
  if (param === 'custom') {
    modalTag = (
      <div className={classes.actionbar}>
        <Modal
          btnType="icon"
          icon={<AddCircleOutline />}
          body={ModalBody}
          add={t('dialog.modaltitle')}
          handle={saveData}
        />
        <div className={classes.actions} style={{ width: '60%' }}>
          <PanelHeader
            onCancelSearch={onCancelSearch}
            onSearch={onSearch}
            bgColor="transparent"
            // onActive={props.onActiv}
            count={customData.length}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            isSelected={selectedDialogs.length > 0}
            onSelectAll={onSelectAll}
            isAllSelected={checkAllStatus}
            settings={{
              search: true,
              duplicate: true,
              delete: true,
              all: true
              // addNew: false,
              // active: false,
              // search: true,
              // all: false
            }}
          />
        </div>
      </div>
    );
  } else {
    modalTag = '';
  }

  let content = null;

  if (edit) {
    content = edit;
  } else {
    content = <AppBar position="static" className={classes.barBack} />;
  }

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getKnowledgeBaseList(robotId);
  };

  return (
    <>
      <Header
        title={t('Knowledge Base')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />

      {/* <PageTitle
        title={t("Knowledge Base")}
      /> */}

      {content}

      {!edit ? modalTag : null}
      {!edit ? tableTag : null}
    </>
  );
}
