import React, { useEffect, useState } from 'react';

// styles

// components
import Header from '../../../components/Header';
import { useTranslation } from 'react-i18next';
import useStyles from './style';
import {
  Button,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import 'firebase/storage';
import { useFormik } from 'formik';
import Table from '../../tables/Tables';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import {
  useUtility,
  useUtilityDispatch
} from '../../../context/UtilityContext';
import { getApi, postApi, deleteApi } from '../../../services/Services';
import { GetSeletedRobot } from '../../../utills';
import './component.css';

const initialValues = {
  displayName: '',
  knowledgeTypes: '',
  mimeType: '',
  file: ''
};

const validationSchema = Yup.object().shape({
  displayName: Yup.string().label('displayName').required('Required'),
  knowledgeTypes: Yup.string().label('knowledgeTypes').required('Required'),
  mimeType: Yup.string().label('mimeType').required('Required'),
  file: Yup.object()
    .shape({
      fileId: Yup.string().required(),
      value: Yup.string().required(),
      type: Yup.string().required()
    })
    .required('Required')
});

export default function KnowledgeBaseDocument(props) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [title, setTitle] = useState('');
  const [language, setLanguage] = useState('');
  const [actiontype, setActionType] = useState('');
  const [knowledgeBaseFile, setFnowledgeBaseFile] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState('');
  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  };
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const [modalStyle] = useState(getModalStyle);

  const getDocumentList = async (robotId) => {
    utility.loading(true);
    const knowledgeBaseId = props.match.params.id;
    if (knowledgeBaseId) {
      const r = await getApi(`/knowledgebasedocumentlist`, {
        knowledgeBaseId,
        selectedRobot: robotId ? robotId : selectedRobot
      });
      console.log(r);
      if (r && r.data && r.data.documentList) {
        setDocumentList(r.data.documentList);
      }
    }
    utility.loading(false);
  };

  const updateDocument = async (values) => {
    utility.loading(true);
    const knowledgeBaseId = props.match.params.id;
    if (knowledgeBaseId) {
      const r = await postApi(
        `/createknowledgebasedocument?knowBaseId=${knowledgeBaseId}&&selectedRobot=${selectedRobot}`,
        { values }
      );
      console.log(r);
    }
    getDocumentList();
    utility.loading(false);
    utility.snack('Document Successfully Created.', 'success');
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setOpen(false);
      setFnowledgeBaseFile(false);
      formik.resetForm();
      if (knowledgeBaseFile) {
        const mdata = values.file.value.replace(
          `data:${values.file.type};base64,`,
          ''
        );
        const finalValues = {
          displayName: values.displayName,
          knowledgeTypes: values.knowledgeTypes,
          mimeType: values.mimeType,
          documentPath: mdata
        };
        updateDocument(finalValues);
      }
    }
  });

  const getKnowledgeBase = async (robotId) => {
    utility.loading(true);
    const knowledgeBaseId = props.match.params.id;
    if (knowledgeBaseId) {
      const r = await getApi(`/getKnowledgeBase`, {
        knowledgeBaseId,
        selectedRobot: robotId ? robotId : selectedRobot
      });
      console.log(r);
      if (r && r.data && r.data.knowledgeBase) {
        setTitle(r.data.knowledgeBase.displayName);
        setLanguage(r.data.knowledgeBase.languageCode);
      }
    }
    utility.loading(false);
  };

  useEffect(() => {
    (async () => {
      const robotID = GetSeletedRobot();
      console.log('robotID ', robotID);
      setSelectedRobot(robotID);
      await getKnowledgeBase(robotID);
      await getDocumentList(robotID);
    })();
  }, []);

  const openModal = () => {
    setOpen(true);
    // formik.setFieldValue("id", uuidv4());
  };

  const closeModal = () => {
    setOpen(false);
    setFnowledgeBaseFile(false);
    formik.resetForm();
  };

  const deleteDocument = async (documentId) => {
    if (window.confirm('Do you want to delete?')) {
      utility.loading(true);
      const knowledgeBaseId = props.match.params.id;
      if (knowledgeBaseId) {
        await deleteApi(`/deletedocument?selectedRobot=${selectedRobot}`, {
          documentId,
          knowledgeBaseId
        });
      }
      await getDocumentList();
      utility.loading(false);
      utility.snack('Successfully Delete');
    }
  };
  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    if (!file) {
      utility.snack('Please select File.', 'error');
      return false;
    }
    if (!file.name.match(/\.(pdf|csv|txt)$/)) {
      utility.snack('Please select valid File.', 'error');
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const genarateFile = {
        fileId: uuidv4(),
        value: e.target.result,
        type: file.type
      };
      formik.setFieldValue('file', genarateFile);
      setFnowledgeBaseFile(true);
    };
  };

  const ModalBody = (
    <div className={classes.centerAlign}>
      <h2 id="simple-modal-title">{t('Add Knowledge Document')}</h2>
      <List className={classes.noHorizontalPadding}>
        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            label={t('displayName')}
            id="displayName"
            // margin="normal"
            type="text"
            fullWidth
            name="displayName"
            // value={faqQuestion}
            // onChange={(e) => { addQuestion(e) }}
            error={
              formik.errors.displayName && formik.touched.displayName
                ? true
                : null
            }
            helperText={
              formik.errors.displayName && formik.touched.displayName
                ? formik.errors.displayName
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.displayName}
            onBlur={formik.handleBlur}
          />
        </ListItem>
        <ListItem className={classes.noHorizontalPadding}>
          <FormControl className={classes.formControlModal}>
            <InputLabel id="knowledgeTypes">Knowledge Type</InputLabel>
            <Select
              labelId="knowledgeTypes"
              id="knowledgeTypes"
              name="knowledgeTypes"
              // value={language}
              // onChange={(e) => setKnowledgeTypes(e.target.value)}
              error={
                formik.errors.knowledgeTypes && formik.touched.knowledgeTypes
                  ? true
                  : null
              }
              helperText={
                formik.errors.knowledgeTypes && formik.touched.knowledgeTypes
                  ? formik.errors.knowledgeTypes
                  : ''
              }
              onChange={formik.handleChange}
              value={formik.values.knowledgeTypes}
              onBlur={formik.handleBlur}
            >
              {/* <MenuItem value={'FAQ'}>FAQ</MenuItem> */}
              <MenuItem value={'EXTRACTIVE_QA'}>EXTRACTIVE_QA</MenuItem>
              <MenuItem value={'FAQ'}>FAQ</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem className={classes.noHorizontalPadding}>
          <FormControl className={classes.formControlModal}>
            <InputLabel id="mimeType">Mime Type</InputLabel>
            <Select
              labelId="mimeType"
              id="mimeType"
              name="mimeType"
              disabled={formik.values.knowledgeTypes ? false : true}
              error={
                formik.errors.mimeType && formik.touched.mimeType ? true : null
              }
              helperText={
                formik.errors.mimeType && formik.touched.mimeType
                  ? formik.errors.mimeType
                  : ''
              }
              onChange={formik.handleChange}
              value={formik.values.mimeType}
              onBlur={formik.handleBlur}
              // value={language}
              // onChange={(e) => updateState({ language: e.target.value })}
            >
              {/* {knowledgeTypes === "EXTRACTIVE_QA" && <MenuItem value={'text/pdf'}>Pdf</MenuItem>}
              {knowledgeTypes === "FAQ" && <MenuItem value={'text/csv'}>Csv</MenuItem>} */}
              {formik.values.knowledgeTypes === 'EXTRACTIVE_QA' && (
                <MenuItem value={'application/pdf'}>Pdf</MenuItem>
              )}
              {formik.values.knowledgeTypes === 'EXTRACTIVE_QA' && (
                <MenuItem value={'text/plain'}>Text File</MenuItem>
              )}
              {formik.values.knowledgeTypes === 'FAQ' && (
                <MenuItem value={'text/csv'}>Csv</MenuItem>
              )}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem className={classes.noHorizontalPadding}>
          <input
            accept=".csv , .txt , .pdf"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            disabled={formik.values.mimeType ? false : true}
            onChange={handleUploadClick}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="raised"
              component="span"
              disabled={formik.values.mimeType ? false : true}
              className={classes.uploadButton}
            >
              Upload{' '}
            </Button>
          </label>
        </ListItem>
      </List>
    </div>
  );
  const columns = [
    // {
    //   label: t("Id"),
    //   name: "faqId",
    // },
    {
      label: t('Document Name'),
      name: 'displayName',
      options: {
        customBodyRender: (value) => {
          return <span style={{ color: '#49b9e9' }}>{value}</span>;
        }
      }
    },
    {
      label: t('Knowledge Type'),
      name: 'knowledgeTypes',
      options: {
        customBodyRender: (value) => {
          return [value];
        }
      }
    },
    {
      label: t('Mime Type'),
      name: 'mimeType',
      options: {
        customBodyRender: (value) => {
          return value;
        }
      }
    },
    {
      label: 'Actions',
      name: 'id',
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => deleteDocument(value)}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        }
      }
    }
  ];
  const options = {
    viewColumns: false
  };

  const onHeaderRobotSelected = async (robotId) => {
    setSelectedRobot(robotId);
    await getKnowledgeBase(robotId);
    await getDocumentList(robotId);
  };

  return (
    <>
      {/* <PageTitle title={t('faq.title')} /> */}
      <Header
        title={t('Knowledge Base')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={false}
      />
      <div className={classes.editContainer}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {/* <FormControl className={classes.formControlText}>
              <TextField id="standard-basic" disabled label="KnowledgeBase Title"
                value={title}
              // onChange={(e) => updateState({ title: e.target.value })}
              />
            </FormControl> */}
            <h3>
              KnowledgeBase Title:{' '}
              <span style={{ color: '#49b9e9' }}>{title}</span>
            </h3>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="language">Language</InputLabel>
              <Select
                labelId="language"
                id="language"
                value={language}
                disabled
                // onChange={(e) => updateState({ language: e.target.value })}
              >
                <MenuItem value={'en-US'}>English</MenuItem>
                <MenuItem value={'bg-BG'}>Bulgarian</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip
            title={t('Add Knowledge Document')}
            onClick={() => {
              setActionType('add');
              openModal();
            }}
          >
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="simple-modal-question"
          aria-describedby="simple-modal-answer"
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <form>
              {ModalBody}
              <div className={classes.btnDiv}>
                <Button onClick={closeModal} variant="contained">
                  {t('close')}
                </Button>
                <Button
                  onClick={formik.submitForm}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: '20px' }}
                >
                  {' '}
                  {actiontype === 'add' ? t('save') : t('update')}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <Table
        title={t('KnowledgeBase Documents List')}
        data={documentList}
        columns={columns}
        options={options}
      />
    </>
  );
}

// #######################################################################
