import { Avatar, IconButton, Tooltip, Typography } from '@material-ui/core';
import { AddCircleOutline, Create, Delete } from '@material-ui/icons';
import clsx from 'clsx';
import * as firebase from 'firebase';
import 'firebase/storage';
import * as moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { GetSeletedRobot } from '../../utills';
import Header from '../../components/Header';
import { getApi, putApi } from '../../services/Services';
import Table from '../tables/Tables';
import useStyles from './styles';
import { useSelector } from 'react-redux';

const Products = (props) => {
  const { history } = props;
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const categoriesRef = firebase.database().ref('Shopping');
  const [productList, setProductList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [selectedRobot, setSelectedRobot] = useState('');

  const editRow = (data) => {
    const { rowData } = data;
    history.push('/app/shopping/edit-products/' + rowData[0]);
  };

  const getProductData = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/shopping-products?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
      );
      let responseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        responseData = apiData;
        responseData.forEach((item) => {
          const data = [];
          if (item.productCateList) {
            Object.keys(item.productCateList).forEach((key) => {
              data.push(item.productCateList[key]);
            });
          }
          item.productCateList = data;
        });
      }
      responseData = responseData.filter((item) => !item.isDelete);
      setProductList(responseData);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e);
      setProductList([]);
      utility.loading(false);
    }
  };

  const removeRow = async (data) => {
    const { rowData } = data;
    utility.loading(true);
    const apiData = {
      productId: rowData[0],
      imageLink: rowData[1],
      productTitle: rowData[2],
      product_base_price: rowData[3],
      product_in_stock: rowData[4],
      productDescription: rowData[5],
      createdAt: rowData[0],
      updatedAt: new Date(),
      isDelete: true
    };
    try {
      const response = await putApi(
        `/shopping-product?selectedRobot=${selectedRobot}&&id=${rowData[0]}&language=${language}`,
        apiData
      );
      if (response.statusCode === 200) {
        utility.snack('Product has been successfully deleted.', 'success');
        getProductData();
      } else {
        utility.snack('Something went wrong please try again.', 'error');
      }
      utility.loading(false);
    } catch (e) {
      console.log(e);
      utility.loading(false);
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const getListAvtar = (value) => {
    let avtarUrl = null;

    if (typeof value?.[0] === 'string') {
      //for new records
      avtarUrl = value && value[0] ? value[0] : null;
    } else {
      // for older records
      avtarUrl = value && value[0] && value[0].value ? value[0].value : null;
    }
    return avtarUrl;
  };

  const columns = [
    {
      label: t('Id'),
      name: 'productId'
    },
    {
      label: t('avatar'),
      name: 'imageLink',
      options: {
        customBodyRender: (value) => {
          return <Avatar alt="Remy Sharp" src={getListAvtar(value)} />;
        }
      }
    },
    {
      label: t('name'),
      name: 'productTitle'
    },
    {
      label: t('price'),
      name: 'product_base_price'
    },
    {
      label: t('shopping.product.instock'),
      name: 'product_in_stock',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography
              className={clsx({
                [classes.highlightOn]: value === true,
                [classes.highlightOff]: value === false
              })}
            >
              {value === true
                ? t('shopping.product.instock')
                : t('shopping.product.outofstock')}
            </Typography>
          );
        }
      }
    },
    {
      label: t('description'),
      name: 'productDescription',
      options: {
        customBodyRender: (value) => {
          //Decoding HTML entities
          const element = document.createElement('p');
          element.innerHTML = value || '';
          return <EllipsisText text={element.innerText} length={50} />;
        }
      }
    },
    {
      label: t('date'),
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          return (
            value && <Typography>{moment(value).format('LLLL')}</Typography>
          );
        }
      }
    },
    {
      label: t('actions'),
      name: 'actions',
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => editRow(tableMeta)}
                >
                  <Create fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => removeRow(tableMeta)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const options = {
    viewColumns: false,
  };

  const getCategoriesData = async () => {
    categoriesRef.child('Categories').on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const list = JSON.parse(JSON.stringify(categoryList));
      for (const x in result) {
        if (!result[x]['isDelete']) {
          list.push({
            categoryId: result[x]['categoryId'],
            imageLink: result[x]['imageLink'],
            categoryTitle: result[x]['categoryTitle'],
            categoryDescriotion: result[x]['categoryDescriotion'],
            createdAt: result[x]['createdAt'],
            updatedAt: result[x]['updatedAt'],
            isDelete: result[x]['isDelete']
          });
        }
      }
      setCategoryList(list);
    });
  };

  useEffect(() => {
    const getSeletedRobot = GetSeletedRobot();
    getProductData(getSeletedRobot);
  }, [language]);

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getCategoriesData(robotId);
    getProductData(robotId);
  };

  return (
    <>
      <Header
        title={t('shopping.product.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip
            title={t('shopping.product.createproduct')}
            onClick={() => history.push('/app/shopping/create-product')}
          >
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Table
        title={t('shopping.product.listproduct')}
        data={productList}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default withRouter(memo(Products));
