import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { IconButton, Tooltip } from '@material-ui/core';
import { OpenInNew, Delete } from '@material-ui/icons';

const cardStyle = {
  float: 'left',
  width: '300px',
  height: '300px',
  margin: '10px'
};
export default function MediaLibraryGallery(props) {
  const { item, type, use } = props;

  const extenstion = item.title
    ? item?.title.slice(((item?.title.lastIndexOf('.') - 1) >>> 0) + 1)
    : '';

  const handleDelete = () => {
    props.handleDelete(item, type);
  };

  const handleSelect = () => {
    if (props.handleSelect) props.handleSelect(item, type);
  };

  const cardTitle = (input) => {
    if (input.length > 20) {
      return input.substring(0, 20) + '...';
    }
    return input;
  };

  return (
    <Card style={cardStyle}>
      <CardHeader
        title={cardTitle(
          item.title ? item.title.split('___')[1].replace(extenstion, '') : ''
        )}
      />
      <CardMedia
        component={type === 'videos' ? 'video' : 'img'}
        width="100%"
        height="150px"
        image={type === 'audios' ? 'images/music.png' : item.downloadlink}
        title="Contemplative Reptile"
        style={{ cursor: 'pointer', objectFit: 'contain' }}
        onClick={() => {
          handleSelect(item.id);
        }}
      />
      <CardActions>
        <Tooltip title="Open">
          <IconButton
            edge="end"
            aria-label="Open"
            onClick={() => window.open(item.downloadlink, '_blank')}
          >
            <OpenInNew />
          </IconButton>
        </Tooltip>
        {!use && (
          <Tooltip title="delete">
            <IconButton
              edge="end"
              aria-label="Open"
              onClick={() => handleDelete(item.id)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
}
