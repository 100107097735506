import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const OrderLineChart = (props) => {
  const { data, keyPath } = props;

  return (
    <>
      <ResponsiveContainer width={'100%'} height={300}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="orderDate" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={keyPath}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

OrderLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  keyPath: PropTypes.string
};

OrderLineChart.defaultProps = {
  data: [],
  keyPath: ''
};

export default memo(OrderLineChart);
