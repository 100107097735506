/* eslint-disable no-empty */
import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Typography
} from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Image, MusicVideo, Timer, VideoLibrary } from '@material-ui/icons';
import * as firebase from 'firebase';
import 'firebase/storage';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import MediaItem from '../../components/MediaItem/MediaItem';
import MediaItemWithImage from '../../components/MediaItem/MediaItemWithImage';
import MediaItemHeading from '../../components/MediaItemHeading/MediaItemHeading';
import ProgressBar from '../../components/ProgressBar';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { getApi, postApi, deleteApi } from '../../services/Services';
import useStyles from './styles';
import { getCustomID } from '../../utills';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';

const ScreenSaver = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [uploadingSatus, setUploadingSatus] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [ImageList, setImageList] = useState([]);
  const [VideoList, setVideoList] = useState([]);
  const [MusicList, setMusicList] = useState([]);
  const [DancesImageList, setDancesImageList] = useState([]);
  const [timer, setTimer] = useState('');
  const [selectedRobot, setSelectedRobot] = useState('');
  const [robootDetail, setRobootDetail] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [dancesUploadingSatus, setDancesUploadingSatus] = useState(false);
  const [dancesUploadPercentage, setDancesUploadPercentage] = useState(0);

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  }
  const [modalStyle] = useState(getModalStyle);

  const ModalBody = (
    <div className={classes.centerAlign}>
      <h2 id="simple-modal-title">Timer</h2>
      <TextField
        label="Timer"
        id="Timer"
        margin="normal"
        type="number"
        fullWidth
        value={timer}
        onChange={(e) => setTimer(e.target.value)}
      />
    </div>
  );

  const getScreenSaverImagesList = async (robotID) => {
    utility.loading(false);
    try {
      const response = await getApi(
        `/screen-saver-images?selectedRobot=${
          robotID ? robotID : selectedRobot
        }&language=${language}`
      );
      let list = [];
      if (response.statusCode === 200) {
        list = response.data;
        list = list.map((each) => {
          return {
            id: each.id,
            title: each.title,
            downloadlink: each.url
          };
        });
      }
      setImageList(list);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.loading(false);
    }
  };

  const submitScreenSaverImages = async (data) => {
    const apiData = {
      id: data.makeId,
      title: data.fileName,
      url: data.getDownloadURL
    };
    await postApi(
      `/screen-saver-image?selectedRobot=${selectedRobot}&language=${language}`,
      apiData
    );
    getScreenSaverImagesList();
  };

  const getScreenSaverVideoList = async (robotID) => {
    utility.loading(false);
    try {
      const response = await getApi(
        `/screen-saver-videos?selectedRobot=${
          robotID ? robotID : selectedRobot
        }&language=${language}`
      );
      let list = [];
      if (response.statusCode === 200) {
        list = response.data;
        list = list.map((each) => {
          return {
            id: each.id,
            title: each.title,
            downloadlink: each.url
          };
        });
      }
      setVideoList(list);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.loading(false);
    }
  };

  const submitScreenSaverVideo = async (data) => {
    const apiData = {
      id: data.makeId,
      title: data.fileName,
      url: data.getDownloadURL
    };
    await postApi(
      `/screen-saver-video?selectedRobot=${selectedRobot}&language=${language}`,
      apiData
    );
    getScreenSaverVideoList();
  };

  const getScreenSaverMusicList = async (robotID) => {
    utility.loading(false);
    try {
      const response = await getApi(
        `/screen-saver-music-list?selectedRobot=${
          robotID ? robotID : selectedRobot
        }&language=${language}`
      );
      let list = [];
      if (response.statusCode === 200) {
        list = response.data;
        list = list.map((each) => {
          return {
            id: each.id,
            title: each.title,
            downloadlink: each.url
          };
        });
      }
      setMusicList(list);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.loading(false);
    }
  };

  const submitScreenSaverMusic = async (data) => {
    const apiData = {
      id: data.makeId,
      title: data.fileName,
      url: data.getDownloadURL
    };
    await postApi(
      `/screen-saver-music?selectedRobot=${selectedRobot}&language=${language}`,
      apiData
    );
    getScreenSaverMusicList();
  };

  const getScreenSaverDancesImagesList = async (robotID) => {
    utility.loading(false);
    try {
      const response = await getApi(
        `/screen-saver-dances-images?selectedRobot=${
          robotID ? robotID : selectedRobot
        }&language=${language}`
      );
      let list = [];
      if (response.statusCode === 200) {
        list = response.data;
        list = list.map((each) => {
          return {
            id: each.id,
            title: each.title,
            downloadlink: each.url
          };
        });
      }
      setDancesImageList(list);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.loading(false);
    }
  };

  const submitScreenSaverDancesImage = async (data) => {
    const apiData = {
      id: data.makeId,
      title: data.fileName,
      url: data.getDownloadURL
    };
    await postApi(
      `/screen-saver-dances-image?selectedRobot=${selectedRobot}&language=${language}`,
      apiData
    );
    getScreenSaverDancesImagesList();
  };

  const handleSubmit = (type, data) => {
    try {
      switch (type) {
        case 1:
          submitScreenSaverImages(data);
          break;
        case 2:
          submitScreenSaverVideo(data);
          break;
        case 4:
          submitScreenSaverDancesImage(data);
          break;
        default:
          submitScreenSaverMusic(data);
      }

      utility.loading(false);
      utility.snack('Media has been upload successfully.', 'success');
    } catch (e) {
      console.log('eror === ', e.response);
      utility.snack('Something went wrong please try again.', 'error');
      utility.loading(false);
    }
  };

  const handleOnClick = (event, type) => {
    if (!selectedRobot || !robootDetail.id || !event.target.files[0])
      return false;
    setUploadingSatus(true);
    const file = event.target.files[0];
    const makeId = getCustomID();
    const fileNameGenarate = makeId + '___' + file.name;
    let firebaseAgent;
    try {
      firebaseAgent = firebase.initializeApp(
        {
          projectId: robootDetail.project_id,
          databaseURL: robootDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robootDetail.project_id}.appspot.com`
        },
        robootDetail.id
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
    firebaseAgent = firebase.app(robootDetail.id);
    let storageRef = null;
    switch (type) {
      case 1:
        storageRef = firebaseAgent
          .storage()
          .ref('ScreenSaver/images/' + fileNameGenarate);
        break;
      case 2:
        storageRef = firebaseAgent
          .storage()
          .ref('ScreenSaver/videos/' + fileNameGenarate);
        break;
      default:
        storageRef = firebaseAgent
          .storage()
          .ref('ScreenSaver/music/' + fileNameGenarate);
    }
    const task = storageRef.put(file);
    task.on(
      'state_changed',
      function progress(snapshot) {
        const percentage = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (percentage < 100) {
          setUploadPercentage(percentage);
        }
      },
      function error(err) {
        setUploadingSatus(false);
        console.log(err);
        utility.snack('Something went wrong please try again.', 'error');
      },
      function complete() {
        task.snapshot.ref
          .getDownloadURL()
          .then((url) => {
            setUploadPercentage(100);
            const sendData = {
              fileName: fileNameGenarate,
              getDownloadURL: url,
              makeId
            };
            handleSubmit(type, sendData);
            setTimeout(() => {
              setUploadingSatus(false);
              setUploadPercentage(0);
              utility.snack('Media has been upload successfully.', 'success');
            }, 1000);
          })
          .catch((e) => {
            console.log(e);
            utility.snack('Something went wrong please try again.', 'error');
          });
      }
    );
  };

  const dancesImageHandleOnClick = (event) => {
    event.preventDefault();
    if (!selectedRobot || !robootDetail.id || !event.target.files[0])
      return false;
    setDancesUploadingSatus(true);
    const file = event.target.files[0];
    const makeId = getCustomID();
    const fileNameGenarate = makeId + '___' + file.name;
    let firebaseAgent;
    try {
      firebaseAgent = firebase.initializeApp(
        {
          projectId: robootDetail.project_id,
          databaseURL: robootDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robootDetail.project_id}.appspot.com`
        },
        robootDetail.id
      );
    } catch (e) {}
    firebaseAgent = firebase.app(robootDetail.id);
    const storageRef = firebaseAgent
      .storage()
      .ref('ScreenSaver/DancesImages/' + fileNameGenarate);
    setDancesUploadingSatus(true);
    const task = storageRef.put(file);
    task.on(
      'state_changed',
      function progress(snapshot) {
        const percentage = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (percentage < 100) {
          setDancesUploadPercentage(percentage);
        }
      },
      function error(err) {
        setDancesUploadingSatus(false);
        console.log(err);
        utility.snack('Something went wrong please try again.', 'error');
      },
      function complete() {
        task.snapshot.ref
          .getDownloadURL()
          .then((url) => {
            setDancesUploadPercentage(100);
            const sendData = {
              fileName: fileNameGenarate,
              getDownloadURL: url,
              makeId
            };
            handleSubmit(4, sendData);
            setTimeout(() => {
              setDancesUploadingSatus(false);
              setDancesUploadPercentage(0);
              utility.snack('Media has been upload successfully.', 'success');
            }, 1000);
          })
          .catch((e) => {
            console.log(e);
            utility.snack('Something went wrong please try again.', 'error');
          });
      }
    );
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const actions = [
    {
      icon: <Timer onClick={openModal} />,
      name: 'Timer'
    },
    {
      icon: (
        <>
          <input
            accept="audio/*"
            style={{
              display: 'none'
            }}
            id="contained-button-file-Music"
            multiple
            type="file"
            onChange={(e) => handleOnClick(e, 3)}
          />
          <label htmlFor="contained-button-file-Music">
            <MusicVideo />
          </label>
        </>
      ),
      name: 'Music'
    },
    {
      icon: (
        <>
          <input
            accept="video/*"
            style={{
              display: 'none'
            }}
            id="contained-button-file-Videos"
            multiple
            type="file"
            onChange={(e) => handleOnClick(e, 2)}
          />
          <label htmlFor="contained-button-file-Videos">
            <VideoLibrary />
          </label>
        </>
      ),
      name: 'Videos'
    },
    {
      icon: (
        <>
          <input
            accept="image/*"
            style={{
              display: 'none'
            }}
            id="contained-button-file-Images"
            multiple
            type="file"
            onChange={(e) => handleOnClick(e, 1)}
          />
          <label htmlFor="contained-button-file-Images">
            <Image />
          </label>
        </>
      ),
      name: 'Images'
    }
  ];

  const __renderNodata = () => {
    return (
      <Card className={classes.nodataroot}>
        <CardContent>
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: 'center' }}
          >
            {t('noData')}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  const getScreenSaverTimer = async (robot) => {
    let firebaseAgent;
    try {
      firebaseAgent = firebase.initializeApp(
        {
          projectId: robot.project_id,
          databaseURL: robot.firebaseDatabaseUrl || '',
          storageBucket: `${robot.project_id}.appspot.com`
        },
        robot.id
      );
    } catch (e) {}
    firebaseAgent = firebase.app(robot.id).database().ref('ScreenSaver');
    firebaseAgent.child('Timer').on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const value = result || '';
      setTimer(value);
    });
  };

  const getData = async (robotID, robot) => {
    if (!robotID || !robot.id) return false;
    await getScreenSaverImagesList(robotID);
    await getScreenSaverVideoList(robotID);
    await getScreenSaverTimer(robotID, robot);
    await getScreenSaverDancesImagesList(robotID, robot);
    await getScreenSaverMusicList(robotID, robot);
  };

  const saveData = () => {
    if (robootDetail.id) {
      let firebaseAgent;
      try {
        firebaseAgent = firebase.initializeApp(
          {
            projectId: robootDetail.project_id,
            databaseURL: robootDetail.firebaseDatabaseUrl || '',
            storageBucket: `${robootDetail.project_id}.appspot.com`
          },
          robootDetail.id
        );
      } catch (e) {}
      firebaseAgent = firebase
        .app(robootDetail.id)
        .database()
        .ref('ScreenSaver');
      firebaseAgent
        .child('Timer')
        .set(timer)
        .then(() => {
          utility.snack('Timer has been successfully updated.', 'success');
        })
        .catch((err) => {
          console.log(err);
          utility.snack('Something went wrong please try again.', 'error');
        });
    }
    closeModal();
  };

  const handleDelete = async (item, type) => {
    utility.loading(true);
    if (item && type) {
      const id = item.id;
      try {
        if (item && type) {
          const response = await deleteApi(
            `/screen-saver-${
              type === 'dances' ? 'dances-image' : type
            }?selectedRobot=${selectedRobot}`,
            { id }
          );
          if (response.statusCode === 200) {
            utility.snack('Successfully Deleted.');
            if (type === 'image') {
              getScreenSaverImagesList();
            } else if (type === 'video') {
              getScreenSaverVideoList();
            } else if (type === 'dances') {
              getScreenSaverDancesImagesList();
            } else {
              getScreenSaverMusicList();
            }
          }
          utility.loading(false);
        }
      } catch (err) {
        console.log(err);
        utility.loading(false);
        utility.snack('Something went wrong please try again.', 'error');
      }
    }
  };

  const onHeaderRobotSelected = (robotId, robot) => {
    setSelectedRobot(robotId);
    setRobootDetail(robot);
    getData(robotId, robot);
  };

  useEffect(()=> {
    getData(selectedRobot, robootDetail);
  }, [selectedRobot, language]);

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {ModalBody}
          <div className={classes.btnDiv}>
            <Button onClick={closeModal} variant="contained">
              {t('close')}
            </Button>
            <Button
              onClick={saveData}
              variant="contained"
              color="secondary"
              style={{ marginLeft: '20px' }}
            >
              {' '}
              {t('save')}
            </Button>
          </div>
        </div>
      </Modal>
      <Header
        title={t('screensaver.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <List>
            <ListItem className={classes.removePadding}>
              <ListItemText
                primary={
                  <Typography
                    size="xl"
                    align="left"
                    weight="medium"
                    className={classes.pageTitle}
                  >
                    {t('screensaver.title')}
                  </Typography>
                }
              />
              {!uploadingSatus && (
                <ListItemSecondaryAction>
                  <FloatingButton
                    actions={actions}
                    direction="left"
                    ariaLabel={t('screensaver.title')}
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </List>
        </Grid>
        {uploadingSatus && (
          <Grid item xs={12}>
            <div className={classes.progressContainer}>
              <Typography>Uploading...</Typography>
              <ProgressBar value={uploadPercentage} />
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={classes.removeVerticalPadding}
        >
          <Card className={classes.cardRoot}>
            <MediaItemHeading titile="Images" titleIcon={<Image />} />
            <CardContent>
              {ImageList.length > 0
                ? ImageList.map((each, index) => (
                    <MediaItem
                      item={each}
                      key={index.toString()}
                      handleDelete={handleDelete}
                      type={'image'}
                    />
                  ))
                : __renderNodata()}
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={classes.removeVerticalPadding}
        >
          <Card className={classes.cardRoot}>
            <MediaItemHeading titile="Videos" titleIcon={<VideoLibrary />} />
            <CardContent>
              {VideoList.length > 0
                ? VideoList.map((each, index) => (
                    <MediaItem
                      item={each}
                      key={index.toString()}
                      handleDelete={handleDelete}
                      type={'video'}
                    />
                  ))
                : __renderNodata()}
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={classes.removeVerticalPadding}
        >
          <Card className={classes.cardRoot}>
            <MediaItemHeading titile="Music" titleIcon={<MusicVideo />} />
            <CardContent>
              {MusicList.length > 0
                ? MusicList.map((each, index) => (
                    <MediaItem
                      item={each}
                      key={index.toString()}
                      handleDelete={handleDelete}
                      type={'music'}
                    />
                  ))
                : __renderNodata()}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <List>
            <ListItem className={classes.removePadding}>
              <ListItemText
                primary={
                  <Typography
                    size="xl"
                    align="left"
                    weight="medium"
                    className={classes.pageTitle}
                  >
                    {t('screensaver.dances')}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>

        {dancesUploadingSatus && (
          <Grid item xs={12}>
            <div className={classes.progressContainer}>
              <Typography>Uploading...</Typography>
              <ProgressBar value={dancesUploadPercentage} />
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          className={classes.removeVerticalPadding}
        >
          <Card className={classes.cardRoot}>
            <div className={classes.cardHeading}>
              <MediaItemHeading titile="Images" titleIcon={<Image />} />
              <IconButton aria-label="delete">
                <label htmlFor="btn-upload">
                  <input
                    id="btn-upload"
                    name="btn-upload"
                    style={{ display: 'none' }}
                    type="file"
                    value={[]}
                    onChange={(e) => dancesImageHandleOnClick(e)}
                  />
                  <AddCircleOutlineIcon
                    className={classes.addCircleOutlineIcon}
                  />
                </label>
              </IconButton>
            </div>
            <CardContent>
              {DancesImageList.length > 0
                ? DancesImageList.map((each, index) => (
                    <MediaItemWithImage
                      item={each}
                      key={index.toString()}
                      handleDelete={handleDelete}
                      type={'dances'}
                    />
                  ))
                : __renderNodata()}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(memo(ScreenSaver));
