import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  ModalBtn: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff !important',
    marginTop: '20px',
    zIndex: 99
  },
  btnDiv: {
    marginTop: '30px'
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 80,
    transition: 'background 300ms ease-out',
    '& span': {
      fontSize: 10,
      padding: 7
    },
    '& svg': {
      transition: 'all 300ms ease-out',
      fontSize: '1.3rem'
    },
    '&:hover': {
      backgroundColor: '#d9d9d99c',
      color: '#000'
    }
  },
  addIconbtn: {
    padding: '5px 0'
  }
}));

const EnhancedModal = ({
  open,
  onOk,
  children,
  onCancel,
  actions,
  onClose,
  ...props
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const { t } = useTranslation('common');

  let defaultActions = (
    <>
      <Button
        onClick={() => {
          onClose();
          onCancel();
        }}
        variant="contained"
      >
        {t('close')}
      </Button>
      <Button
        onClick={onOk}
        variant="contained"
        color="secondary"
        style={{ marginLeft: '20px' }}
      >
        {t('save')}
      </Button>
    </>
  );

  if (actions) {
    defaultActions = actions;
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}
    >
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        // onClose={onClose}
        {...props}
      >
        <div style={modalStyle} className={classes.paper}>
          {children}
          <div className={classes.btnDiv}>{defaultActions}</div>
        </div>
      </Modal>
    </div>
  );
};

EnhancedModal.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any),
  open: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};

EnhancedModal.defaultProps = {
  actions: null
};

export default EnhancedModal;
