import { Grid } from '@material-ui/core';
import React from 'react';
import Legend from './Legend';
import { useTranslation } from 'react-i18next';

function Legends({ data }) {
  const { i18n } = useTranslation('common');
  return (
    <Grid
      item
      xs={12}
      className="legends"
      style={{
        width: '100%',
        padding: 15,
        flexDirection: 'row',
        display: 'flex',
        flexFlow: 'wrap'
      }}
    >
      {data.map(({ color, value, id, romaniaText }) => (
        <Legend
          key={id}
          containerStyle={{ width: '50%' }}
          color={color}
          title={i18n.language === 'en' ? value : romaniaText}
        />
      ))}
    </Grid>
  );
}

export default Legends;
