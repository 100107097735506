import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  pageTitle: {
    width: '100%',
    fontWeight: '500',
    fontSize: 'calc(28px)',
    textAlign: 'left',
    marginBottom: '20px'
  },
  dancesTitle: {
    width: '100%',
    fontWeight: '500',
    fontSize: 'calc(28px)',
    textAlign: 'left'
  },
  removeVerticalPadding: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important'
  },
  removePadding: {
    padding: '0px !important'
  },
  cardRoot: {
    minHeight: '175px'
  },
  cardHeading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  addCircleOutlineIcon: {
    fill: 'black'
  },
  progressContainer: {
    root: {
      width: '100%'
    }
  },
  nodataroot: {
    width: '100%',
    boxShadow: 'none'
  },
  centerAlign: {
    textAlign: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  btnDiv: {
    marginTop: '30px'
  }
}));
