import React, {
  Fragment,
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import Loader from '../Loader/Loader';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      // eslint-disable-line no-useless-computed-key
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  ModalBtn: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#fff !important',
    marginTop: '20px',
    zIndex: 99
  },
  btnDiv: {
    marginTop: '30px'
  }
}));

// eslint-disable-next-line react/display-name
const IconModal = forwardRef((props, modalPermissionRef) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const { t } = useTranslation('common');

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleLoadingOpen = useCallback(() => {
    setLoading(true);
  }, []);

  const handleLoadingClose = useCallback(() => {
    setLoading(false);
    setIsSubmit(true);
  }, []);

  const handleSubmitBtn = useCallback(() => {
    setIsSubmit(false);
  }, []);

  const saveData = () => {
    props.handle();
    setOpen(false);
  };

  useImperativeHandle(modalPermissionRef, () => ({
    resetPage() {
      setOpen(false);
    },
    handleOpenControl() {
      handleOpen();
    },
    handleLoadingOpen() {
      handleLoadingOpen();
    },
    handleLoadingClose() {
      handleLoadingClose();
    },
    handleSubmitBtn() {
      handleSubmitBtn();
    }
  }));

  return (
    <div>
      <Fragment>{props.button}</Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {loading ? (
            <Loader show={loading} />
          ) : (
            <div>
              {props.body}
              <div className={classes.btnDiv}>
                <Button onClick={handleClose} variant="contained">
                  {t('close')}
                </Button>
                {isSubmit && (
                  <Button
                    onClick={saveData}
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: '20px' }}
                  >
                    {' '}
                    {t('save')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
});

export default IconModal;
