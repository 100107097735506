import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import {
  AddCircleOutline,
} from '@material-ui/icons';
import * as firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal/Modal';
import { signUp } from '../../context/UserContext';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';

const CryptoJS = require('crypto-js');

export default function Robots() {
  const { t } = useTranslation('common');
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [pwd, setPassword] = useState('');
  const [role, setRole] = useState(1);
  const [userData, setUserData] = useState([]);

  const resetUser = () => {
    setUserName('');
    setPassword('');
    setRole('');
    setEmail('');
  };

  //Utility Functions
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const ModalBody = (
    <div>
      <h2 id="simple-modal-title">{t('expertView.addnewuser')}</h2>
      <TextField
        label={t('username')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={username}
        onChange={(e) => setUserName(e.target.value)}
      />
      <TextField
        label={t('emailaddress')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label={t('newpassword')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={pwd}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel id="role-label">{t('role')}</InputLabel>
        <Select
          defaultValue={1}
          labelId="role-label"
          id="title"
          margin="dense"
          value={role}
          fullWidth
          onChange={(e) => setRole(e.target.value)}
        >
          <option value={0}>Admin</option>
          <option selected value={1}>
            User
          </option>
        </Select>
      </FormControl>
    </div>
  );

  function generatePassword() {
    const length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  async function getUsersList() {
    utility.loading(true);
    const usersRef = firebase.database().ref('users');
    usersRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const userData1 = JSON.parse(JSON.stringify(userData));
      for (const x in result) {
        userData1.push(result[x]);
      }
      setUserData(userData1);
      utility.loading(false);
    });
  }

  function saveData() {
    utility.loading(true);
    const userData1 = JSON.parse(JSON.stringify(userData));
    const cryptedpwd = CryptoJS.AES.encrypt(pwd, 'my-secret-key@123').toString();
    const userTypeArr = {
      username,
      email,
      cryptedpwd,
      role,
      emailVerified: false,
      permissions: null
    };
    setUserData(userData1);
    //add a user via firebase
    signUp(email, pwd, username, userTypeArr, role)
      .then(() => {
        resetUser();
        utility.snack('User added successfully.', 'success');
      })
      .catch((err) => {
        utility.snack(err.response.data.message, 'warning');
        utility.loading(false);
      });
  }

  useEffect(() => {
    (async () => {
      getUsersList();
      setPassword(generatePassword());
    })();
  }, []);

  return (
    <>
      <Modal
        body={ModalBody}
        add={t('expertView.addnewuser')}
        handle={saveData}
        btnType="icon"
        icon={<AddCircleOutline />}
      />
    </>
  );
}

// #######################################################################
