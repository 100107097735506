import React, { useState } from 'react';

// styles

// components

import PageTitle from '../../components/PageTitle/PageTitle';
import Modal from '../../components/Modal/Modal';
import Table from '../tables/Tables';
import {
  ViewModule as ViewModuleIcon,
  ChildCare as ChildCareIcon
} from '@material-ui/icons';
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const datatableData = [
  ['Joe James', '1', '0', '30.09.2020'],
  ['John Walsh', '1', '0', '30.09.2020'],
  ['Bob Herm', '2', '0', '23.09.2020'],
  ['James Houston', '2', '0', '30.05.2020'],
  ['Prabhakar Linwood', '1', '0', '10.11.2020'],
  ['Kaui Ignace', '1', '0', '12.02.2020'],
  ['Esperanza Susanne', '2', '0', '30.09.2020'],
  ['Christian Birgitte', '2', '0', '30.09.2020'],
  ['Meral Elias', '3', '0', '30.09.2020'],
  ['Deep Pau', '1', '0', '30.01.2020'],
  ['Sebastiana Hani', '3', '0', '30.10.2020'],
  ['Marciano Oihana', '2', '0', '30.12.2020'],
  ['Brigid Ankur', '1', '0', '01.09.2020'],
  ['Anna Siranush', '3', '0', '30.07.2020'],
  ['Avram Sylva', '4', '0', '30.03.2020'],
  ['Serafima Babatunde', '1', '0', '22.09.2020'],
  ['Gaston Festus', '3', '0', '11.09.2020']
];

export default function Projects() {
  const { t } = useTranslation('common');
  const [lang, setLang] = useState('');
  const [projectTitle, setprojectTitle] = useState('');
  const [tableData, setdatatableData] = useState(datatableData);
  const columns = [
    {
      name: t('title'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span style={{ color: '#49b9e9' }}>{value}</span>;
        }
      }
    },
    {
      name: t('dialog.title'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <button
              style={{
                background: '#49b9e9',
                padding: '6px 25px',
                border: 'none',
                borderRadius: '20px',
                color: '#fff'
              }}
            >
              <ViewModuleIcon />
              <span
                style={{
                  position: 'relative',
                  top: '-7px',
                  marginLeft: '6px'
                }}
              >
                {value}
              </span>
            </button>
          );
        }
      }
    },
    {
      name: t('robots'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return (
            <div>
              <ChildCareIcon />
              <span
                style={{
                  position: 'relative',
                  top: '-7px',
                  marginLeft: '15px'
                }}
              >
                {value}
              </span>
            </div>
          );
        }
      }
    },
    {
      name: t('date')
    }
  ];
  const ModalBody = (
    <div>
      <h2 id="simple-modal-title">{t('project.modaltitle')}</h2>
      <TextField
        label={t('projectName')}
        id="title"
        margin="normal"
        type="text"
        fullWidth
        value={projectTitle}
        onChange={(e) => setprojectTitle(e.target.value)}
      />
      <FormControl>
        <InputLabel id="language-select">{t('lang')}</InputLabel>
        <Select
          labelId="language-select"
          id="language"
          value={lang}
          onChange={(e) => setLang(e.target.value)}
        >
          <MenuItem value={'en'}>En</MenuItem>
          <MenuItem value={'fr'}>Fr</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  function saveData() {
    datatableData.push([projectTitle, lang, '1', '30.09.2020']);
    const datatableData1 = [];
    for (let index = 0; index < datatableData.length; index++) {
      datatableData1.push(datatableData[index]);
    }
    setdatatableData(datatableData1);
  }
  return (
    <>
      <PageTitle title={t('project.title')} />
      <Modal body={ModalBody} add={t('project.modaltitle')} handle={saveData} />
      <Table title={t('project.title')} data={tableData} columns={columns} />
    </>
  );
}

// #######################################################################
