import { Grid, Tooltip, IconButton, Button } from '@material-ui/core';
import * as firebase from 'firebase';
import 'firebase/storage';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import Table from '../tables/Tables';
import useStyles from './styles';
import { PanelHeader } from '../dialogs/components/actionbar';
import { Create, Delete } from '@material-ui/icons';
import { deleteApi } from '../../services/Services';
import Modal from '@material-ui/core/Modal';
import Header from '../../components/Header';
import { GetSeletedRobot } from '../../utills';

const ManageOrders = (props) => {
  const { history } = props;
  const { t } = useTranslation('common');
  const [orderList, setOrderList] = useState([]);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderID] = useState('');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [selectedRobot, setSelectedRobot] = useState('');

  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  };

  const [modalStyle] = useState(getModalStyle);

  const editRow = (val) => {
    history.push('/app/shopping/edit-order/' + val);
  };

  const closeModal = () => {
    setOpen(false);
    setOrderID('');
  };

  const openDeleteModel = async (val) => {
    setOrderID(val);
    setOpen(true);
  };

  const columns = [
    {
      label: t('shopping.order.date'),
      name: 'orderDate'
    },
    {
      label: t('shopping.order.orderNumber'),
      name: 'orderNumber'
    },
    {
      label: t('shopping.order.Status'),
      name: 'orderStatus'
    },
    {
      label: t('shopping.order.ClientName'),
      name: 'clientName'
    },
    {
      label: t('shopping.order.Products'),
      name: 'productList',
      options: {
        customBodyRender: (value) => {
          return value.join(', ');
        }
      }
    },
    {
      label: t('shopping.order.DiscountCoupon'),
      name: 'discountCoupon'
    },
    {
      label: t('shopping.order.TotalPrice'),
      name: 'totalPrice'
    },
    {
      label: t('shopping.order.TotalDiscountPrice'),
      name: 'totalDiscountPrice'
    },

    {
      label: t('actions'),
      name: 'id',
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    onClick={() => editRow(value)}
                  >
                    <Create fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => openDeleteModel(value)}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        }
      }
    }
  ];

  const options = {
    viewColumns: false,
    selectableRows: 'none'
  };

  const deleteManageOrder = async () => {
    if (orderId) {
      utility.loading(true);
      const response = await deleteApi(
        `/deletemanageorder?selectedRobot=${selectedRobot}`,
        { orderId }
      );
      utility.loading(false);
      if (response.statusCode === 200) {
        utility.snack('Successfully Delete');
      } else {
        utility.snack('Delete Error');
      }
    }
    closeModal();
  };

  const getOrdersList = async (robotID, robotDetail) => {
    utility.loading(true);
    try {
      firebase.initializeApp(
        {
          projectId: robotDetail.project_id,
          databaseURL: robotDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robotDetail.project_id}.appspot.com`
        },
        robotDetail.id
      );
      firebase.app(robotDetail.id);
    // eslint-disable-next-line no-empty
    } catch (e) {}
    const orderRef = firebase
      .app(robotDetail?.id)
      .database()
      .ref('Shopping/Orders');
    orderRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const list = [];
      for (const x in result) {
        const newObj = {};
        newObj['orderDate'] = result[x]['date'];
        newObj['orderNumber'] = result[x]['orderNumber'];
        newObj['id'] = result[x]['userName'] + '+' + result[x]['orderNumber'];
        newObj['orderStatus'] =
          result[x] && result[x]['orderStatus']
            ? result[x]['orderStatus']
            : 'initial';
        newObj['clientName'] = result[x]['userName'];
        newObj['totalPrice'] = parseInt(result[x]['totalPrice'] || 0);
        newObj['totalDiscountPrice'] =
          result[x] && result[x]['totalDiscountPrice']
            ? parseInt(result[x]['totalDiscountPrice'])
            : '-';
        newObj['discountCoupon'] =
          result[x] && result[x]['discountCoupon']
            ? result[x]['discountCoupon']
            : '-';
        const products = result[x]['products'] || [];
        const productNames = [];
        products.forEach((product) => {
          if (product['productName']) productNames.push(product['productName']);
        });
        newObj['productList'] = productNames;

        list.push(newObj);
      }

      setOrderList(list);
      utility.loading(false);
    });
  };

  useEffect(() => {
    const getSeletedRobot = GetSeletedRobot();
    setSelectedRobot(getSeletedRobot);
  }, []);

  const onHeaderRobotSelected = (robotId, robot) => {
    setSelectedRobot(robotId);
    getOrdersList(robotId, robot);
  };

  return (
    <>
      <Header
        title={t('shopping.order.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <PanelHeader title={t('shopping.order.title')} />
      <Grid container spacing={2}>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="simple-modal-question"
          aria-describedby="simple-modal-answer"
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <form>
              <h2> Do you want to delete?" </h2>
              <div className={classes.btnDiv}>
                <Button onClick={closeModal} variant="contained">
                  {t('close')}
                </Button>
                <Button
                  onClick={() => {
                    deleteManageOrder();
                  }}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: '20px' }}
                >
                  {t('delete')}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
        <Grid item xs={12}>
          <Table
            title={t('shopping.order.listorder')}
            data={orderList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(ManageOrders);
