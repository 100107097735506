import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { OpenInNew, Delete } from '@material-ui/icons';
import EllipsisText from 'react-ellipsis-text';
import useStyles from './styles';

const MediaItem = (props) => {
  const { item, type } = props;
  const classes = useStyles();
  const extenstion = item.title
    ? item?.title.slice(((item?.title.lastIndexOf('.') - 1) >>> 0) + 1)
    : '';

  const handleDelete = () => {
    props.handleDelete(item, type);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <List dense className={classes.onItem}>
            <ListItem>
              <ListItemText
                primary={
                  item.title ? (
                    <Tooltip
                      title={item.title.split('___')[1].replace(extenstion, '')}
                    >
                      <EllipsisText
                        text={item.title
                          .split('___')[1]
                          .replace(extenstion, '')}
                        length={30}
                      />
                    </Tooltip>
                  ) : (
                    ''
                  )
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Open">
                  <IconButton
                    edge="end"
                    aria-label="Open"
                    onClick={() => window.open(item.downloadlink, '_blank')}
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
                <Tooltip title="delete">
                  <IconButton
                    edge="end"
                    aria-label="Open"
                    onClick={() => handleDelete(item.id)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
      </div>
    </Card>
  );
};

MediaItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any)
};

MediaItem.defaultProps = {
  item: null
};

export default memo(MediaItem);
