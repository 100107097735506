import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { PanelHeader } from './actionbar';
import ConfirmModal from '../../../components/Utility/ConfirmModal';
import {
  Button,
  FormControl,
  Snackbar,
  TextField,
  List,
  ListItem,
  Grid
} from '@material-ui/core';
import {
  Person,
  MoodOutlined,
  WarningOutlined,
  AddCircleOutline,
  Cancel
} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import { deleteApi, getApi, putApi, postApi } from '../../../services/Services';
import { GetSeletedRobot, GetTranslatedText } from '../../../utills';
import {
  useUtility,
  useUtilityDispatch
} from '../../../context/UtilityContext';

import './component.css';
import { useSelector } from 'react-redux';

let inputref;

export const NoGroupData = () => (
  <div className="no-group-data">
    <WarningOutlined />
    <span>No data available</span>
  </div>
);

export const Item = (props) => {
  const { last, change, value, onDeleteItem, itemIndex, colName } = props;
  return (
    <div className={['textarea', last ? 'onlyone' : ''].join(' ')}>
      <textarea
        rows={5}
        type="text"
        id={`${colName}-${itemIndex}`}
        onChange={change}
        value={value}
        ref={inputref}
      />
      <a className="delete" onClick={onDeleteItem}>
        <Cancel />
      </a>
    </div>
  );
};

export const GroupItem = (props) => {
  const onItemDataChange = (e, index) => {
    const current_items = [...props.items];
    current_items[index] = e.target.value;
    props.onChangeTheInnerItems(current_items, e);
  };
  const { t } = useTranslation('common');

  return (
    <div className="dialog-item">
      {props.name === 'training' ? (
        <div className="left block">
          <div className="header">
            <Person />
            <span>
              {t('dialog.dialogQuestion')}
            </span>
          </div>
        </div>
      ) : (
        <div className="right block">
          <div className="header">
            <MoodOutlined />
            <span>
              {t('dialog.peppersResponse')}
            </span>
          </div>
        </div>
      )}
      <div className="g-item">
        <div className="g-list">
          {props.items.map((item, index) => (
            <Droppable droppableId={`${props.name}-${index}`} key={index}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Item
                    last={props.items.length === 1}
                    onDeleteItem={() => {
                      props.onDeleteItem(index);
                    }}
                    itemIndex={index}
                    colName={props.name}
                    change={(e) => {
                      onItemDataChange(e, index);
                    }}
                    value={item}
                    name={'training'}
                  />
                </div>
              )}
            </Droppable>
          ))}
          <div>
            <a onClick={props.onNewAdd} className="add-icon">
              <AddCircleOutline />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GroupItemRow = (props) => {
  return (
    <div className="group-item-row">
      <div className="col">
        <GroupItem
          onChangeTheInnerItems={(items) => {
            props.onChangeTheInnerItems('q', items);
          }}
          onDeleteItem={(index) => {
            props.onDeleteItem('q', index);
          }}
          onNewAdd={props.onAddQuestion}
          items={props.question.items}
          itemIndex={props.itemIndex}
          index={props.itemIndex}
          name={'training'}
          type={props.type}
        />
      </div>
      <div className="col">
        <GroupItem
          onChangeTheInnerItems={(items) => {
            props.onChangeTheInnerItems('r', items);
          }}
          onDeleteItem={(index) => {
            props.onDeleteItem('r', index);
          }}
          onNewAdd={props.onAddResponse}
          items={props.response.items}
          name="response"
          itemIndex={props.itemIndex}
          index={props.itemIndex}
        />
      </div>
    </div>
  );
};

export const Groups = (props) => {
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const selectedRobot = GetSeletedRobot();
  const language = useSelector((state)=> state.language.value);

  const classes = useStyles();
  inputref = useRef();

  const [groupList, setUpdateGroupList] = useState([]);
  const [actualList, setUpdateActualGroupList] = useState([]);
  const [allSelected, setChangeAllSelectedStatus] = useState(false);
  const [filterText, setUpdateFilterText] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const filterData = (list, search_text) => {
    return list.filter((item) => {
      const questionMatch = item.question.items.find(
        (text) => text.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
      );
      const responseMatch = item.response.items.find(
        (text) => text.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
      );
      return questionMatch || responseMatch;
    });
  };

  useEffect(() => {
    (async () => {
      let list = [...props.actualList];

      // Old logic when get questions this logic change language in 'ro'
      // const translatedQuestion = [];
      // if (list[0] && list[0].question.items.length > 0) {
      //   await list[0].question.items.forEach(async (item) => {
      //     const translatedText = await GetTranslatedText(item, 'ro');
      //     translatedQuestion.push(translatedText);
      //   });
      //   list[0].question.items = translatedQuestion;
      // }

      if (filterText) {
        list = filterData(list, filterText);
      }

      if (list[0] && list[0].response) {
        let newDataList = [];
        for(let item of list[0].response.items) {
          const splitText = item.split('\n');
          for(let splitItem of splitText) {
            newDataList.push(splitItem);
          }
        }
        list[0].response.items =  newDataList;
      }

      setUpdateGroupList([...list]);
      setUpdateActualGroupList(props.actualList);
    })();
  }, [props.actualList]);

  const onGroupCheck = (e, index) => {
    const list = [...groupList];
    list[index].checked = e.target.checked;
    setUpdateGroupList(list);
  };

  const onAddQuestion = (index) => {
    const list = [...groupList];
    list[index].question.items = [...list[index].question.items, ''];
    setUpdateGroupList(list);
    setTimeout(() => {
      document
        .getElementById(`training-${list[index].question.items.length - 1}`)
        .focus();
    }, 100);
  };

  const onAddResponse = (index) => {
    const list = [...groupList];
    list[index].response.items = [...list[index].response.items, ''];
    setUpdateGroupList(list);
    setTimeout(() => {
      document
        .getElementById(`response-${list[index].response.items.length - 1}`)
        .focus();
    }, 100);
  };

  const onChangeResponse = (index, items) => {
    const list = [...groupList];
    list[index].response.items = [...items];
    setUpdateGroupList(list);
    // props.onUpdate(list);
  };

  const onChangeQuestion = (index, items) => {
    const list = [...groupList];
    list[index].question.items = [...items];
    setUpdateGroupList(list);
  };

  const onSearchCancel = () => {
    setUpdateFilterText('');
    setUpdateGroupList(actualList);
  };

  const onChangeSearchText = (e) => {
    const search_text = e.target.value;
    if (search_text === '') {
      onSearchCancel(e);
    }
    let list = [...props.actualList];
    list = filterData(list, search_text);
    setUpdateGroupList(list);
  };

  const onDuplicate = () => {
    const list = [...groupList];
    const list_selected = list
      .filter((_i) => _i.checked)
      .map((it) => it.dataIndex);
    const remaining = actualList.filter(
      (item) => list_selected.indexOf(item.dataIndex) >= 0
    );
    const temp_list = [...actualList].map((_i) => {
      return {
        ..._i,
        checked: false
      };
    });

    remaining.forEach((it) => {
      temp_list.unshift({
        ...it,
        question: {
          items: [...it.question.items]
        },
        response: {
          items: [...it.response.items]
        },
        dataIndex: temp_list.length,
        checked: false
      });
    });

    props.onUpdate(temp_list);
  };

  const selectAll = () => {
    const list = [...groupList];
    list.forEach((_i) => {
      _i.checked = !allSelected;
    });

    setUpdateGroupList(list);
    setChangeAllSelectedStatus(!allSelected);
  };

  const onDeleteGroupItem = () => {
    setOpenDeleteModal(true);
  };

  const onDeleteItem = (type, itemIndex, dataIndex) => {
    const dataItem = actualList.find((item) => item.dataIndex === dataIndex);
    if (type === 'q') {
      dataItem.question.items.splice(itemIndex, 1);
    } else if (type === 'r') {
      dataItem.response.items.splice(itemIndex, 1);
    }
    props.onUpdate(actualList);
  };

  const isAnyChecked = groupList.some((_i) => _i.checked);

  const { dialogName } = props;

  const deleteDialog = async () => {
    let re = new RegExp(`(^en_)|(^EN_)|(^ro_)|(^RO_)`, 'gi');
    const pageURL = window.location.href;
    const dialogID = pageURL.substr(pageURL.lastIndexOf('/') + 1);
    utility.loading(true);
    const deleteResponse = await deleteApi(
      `/intents?selectedRobot=${selectedRobot}`,
      {
        intentids: [dialogID]
      }
    );

    let title = dialogName.replace(/(PRESENTATION)/gi, '').trim();
    let lang = null;

    if (title.match(re)) {
      lang = title.split('_')[0].toLowerCase();
    }

    deleteApi(`/storyteller?selectedRobot=${selectedRobot}&language=${lang||language}`, {
      title: title.replace(re, '')
    })
    utility.loading(false);
    if (deleteResponse.statusCode === 200) {
      utility.snack('Dialog has been successfully deleted.');
      setOpenDeleteModal(false);
      window.location.assign(`${window.location.origin}/#/app/dialogs/custom`);
    }
  };
  return (
    <div className={classes.groups}>
      <div className="edit-panel">
        <PanelHeader
          settings={{
            search: true,
            delete: true
          }}
          onAddNewGroup={props.addNew}
          count={groupList.length}
          onSelectAll={selectAll}
          allSelected={allSelected}
          isSelected={isAnyChecked}
          onSearch={onChangeSearchText}
          onDuplicate={onDuplicate}
          onDelete={onDeleteGroupItem}
          onCancel={onSearchCancel}
        />
      </div>
      <div className="group-data">
        {groupList.length ? (
          <div className="group-data-list">
            {groupList.map((item, index) => (
              <GroupItemRow
                itemIndex={index}
                onChangeTheInnerItems={(type, items) => {
                  if (type === 'q') {
                    onChangeQuestion(index, items, item.dataIndex);
                  } else {
                    onChangeResponse(index, items, item.dataIndex);
                  }
                }}
                onDeleteItem={(type, itemIndex) => {
                  onDeleteItem(type, itemIndex, item.dataIndex, index);
                }}
                onAddQuestion={() => {
                  onAddQuestion(index, item.dataIndex);
                }}
                onAddResponse={() => {
                  onAddResponse(index, item.dataIndex);
                }}
                key={index}
                onCheck={(e) => {
                  onGroupCheck(e, index);
                }}
                checked={item.checked}
                question={item.question}
                response={item.response}
              />
            ))}
          </div>
        ) : (
          <NoGroupData />
        )}
      </div>
      <ConfirmModal
        open={openDeleteModal}
        onOk={deleteDialog}
        onCancel={() => setOpenDeleteModal(false)}
        onClose={() => setOpenDeleteModal(false)}
      >
        <List>
          <ListItem>
            <div>Do you want to delete this Dialog?</div>
          </ListItem>
        </List>
      </ConfirmModal>
    </div>
  );
};

export default function DialogsEdit(props) {
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const selectedRobot = GetSeletedRobot();
  const [robotAnimationVideos, setRobotAnimationVideos] = useState([]);
  const [videoURl, setVideoURl] = useState('');
  const [dialogName, setDialogName] = useState('')
  const language = useSelector(state => state.language.value);

  const {
    history,
    match: { params }
  } = props;
  const [snakbar, updateSnackbar] = useState({
    vertical: 'top',
    horizontal: 'right',
    open: false,
    message: '',
    status: 'success'
  });

  const showSnackBar = (msg, status = 'success', time = 2000) => {
    updateSnackbar({
      ...snakbar,
      ...{
        message: msg,
        open: true,
        status
      }
    });

    setTimeout(() => {
      updateSnackbar({
        ...snakbar,
        ...{
          message: '',
          open: false,
          status: 'success'
        }
      });
    }, time);
  };
  const classes = useStyles();

  const [state, setState] = useState({
    title: props.name,
    language: language,
    intent: null,
    groups: [],
    filtered: []
  });

  const updateState = (newState) => {
    setState((state) => ({
      ...state,
      ...newState
    }));
  };

  const getIntentData = async (language) => {
    utility.loading(true);
    try {
      const intentResponse = await getApi(
        `/intent?selectedRobot=${selectedRobot}&&id=${params.id}&&language=${language}`
      );
      // let apiData = intentResponse.data;
      if (intentResponse.statusCode === 200) {
        const { question, response, displayName } = intentResponse.data;
        setDialogName(displayName.replace(/(PRESENTATION)/gi, '').trim())
        const items = [
          {
            response,
            question,
            dataIndex: 1,
            checked: false,
            active: true
          }
        ];
        updateState({
          title: displayName,
          groups: items,
          filtered: items
        });
      }
      utility.loading(false);
    } catch (e) {
      utility.snack('Something went wrong please try again.', 'error');
      utility.loading(false);
    }
  };

  const getRobotVideos = async () => {
    try {
      const response = await getApi('/get-robot-videos-list');
      const apiData = response.data;
      if (response.statusCode === 200) setRobotAnimationVideos(apiData);
    } catch (e) {
      console.log('eror === ', e.response);
    }
  };

  //Fetch edit data
  useEffect(() => {
    (async () => {
      await getIntentData(language);
      await getRobotVideos();
    })();
  }, [params.id, language]);

  const { t } = useTranslation('common');

  const updateGroups = (groups) => {
    setState({ ...state, groups });
  };

  const onFilter = (items, allSelected) => {
    if (items === true) {
      updateState({
        filtered: [...state.groups].map((_i) => {
          return {
            ..._i,
            checked: allSelected
          };
        })
      });
    } else {
      updateState({
        filtered: items.map((_i) => {
          return {
            ..._i,
            checked: allSelected
          };
        })
      });
    }
  };

  const addNew = () => {
    const { groups } = { ...state };
    groups.unshift({
      dataIndex: groups.length,
      checked: false,
      active: true,
      question: {
        items: ['']
      },
      response: {
        items: ['']
      }
    });
    updateState({ groups: [...groups] });
  };

  const { title } = state;

  const onSaveIntent = async () => {

    // Old logic when change bubles this logic get values and translate there in Elnglish
    // const translatedQuestion = [];
    // const translatedResponse = [];
    // state.groups[0].question.items.forEach(async (item) => {
    //   const translatedText = await GetTranslatedText(item, 'en');
    //   translatedQuestion.push(translatedText);
    // });
    // state.groups[0].response.items.forEach(async (item) => {
    //   const translatedText = await GetTranslatedText(item, 'en');
    //   translatedResponse.push(translatedText);
    // });
    
    utility.loading(true);
    
    const re = new RegExp(dialogName, 'g');
    const newTitle = title.trim();
    const questionArr = state.groups[0].question.items.map(q => q.replace(re, newTitle));
    const responseArr = state.groups[0].response.items.map(q => q.replace(/(\(updated\))/g, '').replace(re, newTitle + ' (updated)'));
    const data = {
      id: params.id,
      name: newTitle,
      question: { items: questionArr },
      response: { items: responseArr },
      language: language,
    };

    setTimeout(() => {
      putApi(`/intent?selectedRobot=${selectedRobot}`, data)
        .then((response) => {
          let re_lang = new RegExp(`(^en_)|(^EN_)|(^ro_)|(^RO_)`, 'gi');
          if (response.statusCode === 200) {
            showSnackBar(response.message);
            getApi(`/storyteller?selectedRobot=${selectedRobot}&title=${dialogName.replace(re_lang, '')}&language=${language}`)
            .then(({data}) => {
              postApi(`/storyteller?selectedRobot=${selectedRobot}&language=${language}${dialogName?`&oldTitle=${dialogName.replace(re_lang, '')}`:''}`, {presentations : [...data.presentations], title: newTitle.replace(re_lang, '')})
            });
          } else {
            showSnackBar(response.message);
          }
          utility.loading(true);
        })
        .catch(() => {
          console.error();
          utility.loading(false);
        })
        .then(() => {
          utility.loading(false);
          history.push('/app/dialogs/custom');
        });
    }, 1000);
  };

  const handleSnakbarClose = () => {};
  const { vertical, horizontal, open, message, status } = snakbar;

  const handleOnDragEnd = async (result) => {
    const { groups: groupsData } = { ...state };
    const targetName = result.destination.droppableId.split('-');
    if (
      !result.destination ||
      (result.destination === 'videoList' && targetName[0] !== 'response')
    )
      return false;
    const listItemName = `{${result.draggableId}}`;

    const targetResponseIndex = parseInt(targetName[1]);

    let selectedInput = groupsData[0].response.items[targetResponseIndex];

    selectedInput = selectedInput + listItemName;

    groupsData[0].response.items[targetResponseIndex] = selectedInput;
    updateState({ groups: [...groupsData] });

    await robotAnimationVideos.forEach((robotListItem) => {
      if (robotListItem.title.includes(result.draggableId)) {
        setVideoURl(robotListItem.url);
      }
    });
  };
  const renderRobotAnimationList = () => {
    return (
      <ul className={classes.robotVideoListContainer}>
        {robotAnimationVideos &&
          robotAnimationVideos.length > 0 &&
          robotAnimationVideos.map(({ url, title }, index) => {
            return (
              <Draggable key={url} draggableId={title} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListItem
                      className={classes.robotVideoList}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <div onClick={() => setVideoURl(url)}>{title}</div>
                    </ListItem>
                  </div>
                )}
              </Draggable>
            );
          })}
      </ul>
    );
  };

  const renderVideoPlayer = () => {
    if (videoURl) {
      return (
        <ReactPlayer url={videoURl} playing={true} loop={true} height="300px" />
      );
    }
  };

  const renderTip = () => {
    return (
      <div className={classes.tipText}>{`${t('tip')}: ${t(
        'dialog.tipText'
      )}`}</div>
    );
  };

  return (
    <>
      <PageTitle title={t('dialog.title')} />
      <div className={classes.editContainer}>
        <Button
          variant="contained"
          style={{
            position: 'fixed',
            top: 12,
            right: 15,
            zIndex: 999
          }}
          onClick={onSaveIntent}
          color="primary"
        >
          Save
        </Button>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <FormControl className={classes.formControlText}>
              <label>{t('dialog.title')}</label>
              <TextField
                id="standard-basic"
                value={title}
                onChange={(e) => updateState({ title: e.target.value })}
              />
            </FormControl>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleSnakbarClose}
          message=""
          key={vertical + horizontal}
        >
          <Alert onClose={handleSnakbarClose} severity={status}>
            {message}
          </Alert>
        </Snackbar>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div>
            <Groups
              dialogName={dialogName}
              groups={state.filtered}
              onUpdate={updateGroups}
              addNew={addNew}
              onFilter={onFilter}
              actualList={state.groups}
            />
          </div>
          <Droppable droppableId="videoList">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {renderTip()}
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6} lg={6}>
                    {renderRobotAnimationList()}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <ListItem className={classes.noHorizontalPadding}>
                      {renderVideoPlayer()}
                    </ListItem>
                  </Grid>
                </Grid>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
