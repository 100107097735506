import React, { useState } from 'react';
import { Typography, Button, Box, Modal, Fade } from '@material-ui/core';
import useStyles from './styles';

export default function ConfirmModal({ children, confirmText, confirmAction }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      {children(toggleModal)}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        className={classes.modal}
        onClose={() => setIsModalOpen(!isModalOpen)}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isModalOpen}>
          <Box className={classes.paper}>
            <Typography>{confirmText}</Typography>
            <Box className={classes.actionBtnRow}>
              <Button
                variant="contained"
                onClick={() => toggleModal()}
                style={{ marginRight: 10 }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  confirmAction();
                  toggleModal();
                }}
              >
                DELETE
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
