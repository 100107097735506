import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 'en',
    status: 'idle',
}

const languageStore = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLeng: (state, action) => {  
            if (state.value === action.payload) {
                return;
            }
            state.value = action.payload;
        }
    },
})

export const { changeLeng } = languageStore.actions;

export const language = (state) => state.language.value;

export default languageStore.reducer;