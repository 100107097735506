import Axios from 'axios';
import * as firebase from 'firebase';

export const putApi = (apiUrl, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: 'put',
      url: apiUrl,
      data,
      headers
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return promise;
};

export const postApi = (apiUrl, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: 'post',
      url: apiUrl,
      data,
      headers
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return promise;
};

export const getApi = (apiUrl, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: 'get',
      params: data,
      url: apiUrl,
      headers
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
  return promise;
};

export const deleteApi = (apiUrl, data, headers) => {
  const promise = new Promise((resolve, reject) => {
    Axios({
      method: 'delete',
      url: apiUrl,
      data,
      headers
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return promise;
};

export const uploadApi = async (path, data, type) => {
  const metadata = {
    contentType: type
  };
  // let path = `PresenterImages/${id + "." + type.replace("image/", "")}`;
  const mdata = data.replace('data:' + type + ';base64,', '');
  const ref = firebase.storage().ref(path);
  const snapshot = await ref.putString(mdata, 'base64', metadata);
  return snapshot.ref.getDownloadURL();
};

export const createFB = async (target, data, rootId) => {
  const updates = {};
  if (data) {
    updates[rootId] = data;
    try {
      await firebase.database().ref(target).update(updates);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  return false;
};

export const updateFB = async (target, data, id) => {
  try {
    await firebase.database().ref(target).child(id).update(data);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const deleteFB = async (target, id) => {
  try {
    await firebase.database().ref(target).child(id).remove();
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const uploadImageAsync = async (
  path,
  id,
  data,
  type,
  action = 'add',
  noPrevFile,
  oldImage = ''
) => {
  const metadata = {
    contentType: 'image/' + type
  };
  if (action === 'add') {
    const mdata = data.replace(`data:${type};base64,`, '');
    const ref = firebase
      .storage()
      .ref(`${path}/${id + '.' + type.replace('image/', '')}`);
    const snapshot = await ref.putString(mdata, 'base64', metadata);
    return await snapshot.ref.getDownloadURL();
  }
  if (noPrevFile && !oldImage) {
    console.log('Plase provide old image file name.');
    return false;
  }
  if (noPrevFile) {
    await firebase.storage().ref(`${path}/${oldImage}`).delete();
    const mdata = data.replace(`data:image/${type};base64,`, '');
    const ref = firebase
      .storage()
      .ref(`${path}/${id + '.' + type.replace('image/', '')}`);
    const snapshot = await ref.putString(mdata, 'base64', metadata);
    return await snapshot.ref.getDownloadURL();
  } else {
    const onlyExtension = type.replace('image/', '');
    const mdata = data.replace(`data:image/${onlyExtension};base64,`, '');
    const ref = firebase.storage().ref(`${path}/${id + '.' + onlyExtension}`);
    const snapshot = await ref.putString(mdata, 'base64', metadata);
    return await snapshot.ref.getDownloadURL();
  }
};

export const formatedFBData = async (data) => {
  const updates = {};
  data.forEach((each) => {
    updates[each.categoryId] = each;
  });
  return updates;
};

export const formatedCategoryFBData = async (data) => {
  const updates = [];
  data.forEach((each) => {
    updates.push(each.categoryId);
  });
  return updates;
};

export const formatedRevertFBData = (data = {}) => {
  const updates = [];
  Object.keys(data).forEach((key) => {
    updates.push(data[key]);
  });
  return updates;
};
