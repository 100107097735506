import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { AddCircleOutline, Delete } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import Table from '../tables/Tables';
import useStyles from './styles';
import { getCustomID, GetSeletedRobot } from '../../utills';
import Header from '../../components/Header';
import { getApi, postApi, deleteApi } from '../../services/Services';
import { useSelector } from 'react-redux';

const generateCoupon = () => {
  const length = 6,
    charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const initialValues = {
  id: getCustomID(),
  coupon_name: generateCoupon(),
  coupon_type: '',
  coupon_value: 0,
  created_at: new Date().toISOString()
};

const validationSchema = Yup.object().shape({
  coupon_name: Yup.string().label('coupon_name').required('Required'),
  coupon_type: Yup.string().label('coupon_type').required('Required'),
  coupon_value: Yup.number().label('coupon_value').required('Required'),
  created_at: Yup.date()
});

const ShoppingCoupons = () => {
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [couponData, setCouponData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState('');
  const classes = useStyles();
  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  };
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [modalStyle] = useState(getModalStyle);

  const closeModal = () => {
    setOpen(false);
  };

  const getCouponsData = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/shopping-coupons?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
      );
      const responseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        // Todo fix/change this older data format.
        apiData.forEach((item) => {
          responseData.push([
            item.id,
            item.coupon_name,
            item.coupon_type,
            item.coupon_value,
            item.created_at
          ]);
        });
      }
      setCouponData(responseData);
      utility.loading(false);
    } catch (e) {
      setCouponData([]);
      utility.loading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      closeModal();
      utility.loading(true);
      try {
        const response = await postApi(
          `/shopping-coupons?selectedRobot=${selectedRobot}&language=${language}`,
          values
        );
        if (response.statusCode === 200) {
          utility.snack('Coupon has been successfully created.', 'success');
          formik.resetForm();
          getCouponsData();
        } else {
          utility.snack('Something went wrong please try again.', 'error');
        }
        utility.loading(false);
      } catch (e) {
        formik.resetForm();
        utility.loading(false);
        utility.snack('Something went wrong please try again.', 'error');
      }
    }
  });

  const openModal = () => {
    setOpen(true);
    formik.setFieldValue('id', getCustomID());
    formik.setFieldValue('coupon_name', generateCoupon());
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getCouponsData(robotId);
  };

  const ModalBody = (
    <div className={classes.centerAlign}>
      <h2 id="simple-modal-title">{t('shopping.coupon.create')}</h2>
      <List className={classes.noHorizontalPadding}>
        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            label={t('shopping.coupon.name')}
            margin="normal"
            type="text"
            fullWidth
            name="coupon_name"
            error={
              formik.errors.coupon_name && formik.touched.coupon_name
                ? true
                : null
            }
            helperText={
              formik.errors.coupon_name && formik.touched.coupon_name
                ? formik.errors.coupon_name
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.coupon_name}
            onBlur={formik.handleBlur}
          />
        </ListItem>

        <ListItem className={classes.noHorizontalPadding}>
          <FormControl
            fullWidth
            error={
              formik.errors.coupon_type && formik.touched.coupon_type
                ? true
                : null
            }
          >
            <InputLabel>{t('shopping.coupon.type')}</InputLabel>
            <Select
              name="coupon_type"
              onChange={formik.handleChange}
              value={formik.values.coupon_type}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="pc">Percentage</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </Select>
            {formik.errors.coupon_type && formik.touched.coupon_type && (
              <FormHelperText>{formik.errors.coupon_type}</FormHelperText>
            )}
          </FormControl>
        </ListItem>

        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            label={t('shopping.coupon.value')}
            margin="normal"
            type="number"
            fullWidth
            name="coupon_value"
            error={
              formik.errors.coupon_value && formik.touched.coupon_value
                ? true
                : null
            }
            helperText={
              formik.errors.coupon_value && formik.touched.coupon_value
                ? formik.errors.coupon_value
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.coupon_value}
            onBlur={formik.handleBlur}
          />
        </ListItem>
      </List>
    </div>
  );

  const removeRow = async ({ rowData }) => {
    utility.loading(true);
    const id = rowData[0];
    try {
      const response = await deleteApi(
        `/shopping-coupons?selectedRobot=${selectedRobot}`,
        { id }
      );
      if (response.statusCode === 200) {
        formik.resetForm();
        utility.snack('Coupon has been successfully deleted.', 'success');
        getCouponsData();
      } else {
        utility.snack('Something went wrong please try again.', 'error');
      }
      utility.loading(false);
    } catch (e) {
      formik.resetForm();
      utility.loading(false);
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const columns = [
    {
      label: 'ID',
      name: 'id'
    },
    {
      label: t('shopping.coupon.name'),
      name: 'coupon_name'
    },
    {
      label: t('shopping.coupon.type'),
      name: 'coupon_type'
    },
    {
      label: t('shopping.coupon.value'),
      name: 'coupon_value'
    },
    {
      label: t('created'),
      name: 'created_at',
      options: {
        customBodyRender: (value) => {
          return (
            value && <Typography>{moment(value).format('LLLL')}</Typography>
          );
        }
      }
    },
    {
      label: 'Actions',
      options: {
        customBodyRender: (tableMeta, itemRow) => {
          return (
            <>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => removeRow(itemRow)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const options = {
    viewColumns: false,
    selectableRows: 'none'
  };

  useEffect(() => {
    const getSeletedRobot = GetSeletedRobot();
    setSelectedRobot(getSeletedRobot);
    getCouponsData(getSeletedRobot);
  }, [language]);

  return (
    <>
      <Header
        title={t('shopping.coupon.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList
      />
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip title={t('shopping.coupon.create')} onClick={openModal}>
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <form>
              {ModalBody}
              <div className={classes.btnDiv}>
                <Button onClick={closeModal} variant="contained">
                  {t('close')}
                </Button>
                <Button
                  onClick={formik.submitForm}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: '20px' }}
                >
                  {t('save')}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <Table
        title={t('shopping.coupon.list')}
        data={couponData}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default memo(ShoppingCoupons);
