import React, { memo } from 'react';
import { Switch } from '@material-ui/core';
import useStyles from './styles';

const CustomSwitch = (props) => {
  const classes = useStyles();

  return (
    <>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    </>
  );
};

export default memo(CustomSwitch);
