import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Create, Delete } from '@material-ui/icons';
import 'firebase/storage';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import { getApi, deleteApi } from '../../services/Services';
import { GetSeletedRobot } from '../../utills';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { useSelector } from 'react-redux';

const membersData = [];

export default function Robots({ history }) {
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [memberData, setMemberData] = useState(membersData);
  const [selectedRobot, setSelectedRobot] = useState('');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const getmembersList = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/team-members?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
      );
      let memberReseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        memberReseData = apiData;
        memberReseData.forEach((item) => {
          item.id = item.memberId;
        });
      }
      setMemberData(memberReseData);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      setMemberData([]);
      utility.loading(false);
    }
  };

  const removeRow = async (row) => {
    utility.loading(true);
    try {
      const response = await deleteApi(
        `/team-member?selectedRobot=${selectedRobot}`,
        { id: row.id }
      );
      if (row.intent_id) {
        await deleteApi(`/intents?selectedRobot=${selectedRobot}`, {
          intentids: [row.intent_id]
        });
      }
      if (response.statusCode === 200) {
        utility.snack('Team Member has been successfully deleted.', 'success');
        let memberData1 = JSON.parse(JSON.stringify(memberData));
        memberData1 = memberData1.filter((e) => e.id !== row.id);

        setMemberData(memberData1);
      } else {
        utility.snack('Something went wrong please try again.', 'error');
      }
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.snack('Something went wrong please try again.', 'error');
      utility.loading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const getSeletedRobot = GetSeletedRobot();
      setSelectedRobot(getSeletedRobot);
      getmembersList(getSeletedRobot);
    })();
  }, [language]);
  const contentPlaceHolder = <p>{t('notDataFound')}</p>;

  const columns = [
    {
      name: 'memberId',
      numeric: false,
      disablePadding: false,
      label: t('memberId'),
      customBodyRender: (value) => {
        return <Typography>{value}</Typography>;
      }
    },
    {
      name: 'memberImage',
      numeric: false,
      disablePadding: false,
      label: t('memberImage'),
      options: {
        customBodyRender: (value) => {
          return <Avatar alt="member image" src={value} />;
        }
      }
    },
    {
      name: 'memberName',
      numeric: false,
      disablePadding: false,
      label: t('memberName'),
      options: {
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        }
      }
    },
    {
      name: 'trainingPhaseTitle',
      numeric: false,
      disablePadding: false,
      label: t('trainingPhaseTitle'),
      customBodyRender: (row) => {
        return <Typography>{row.trainingPhaseTitle}</Typography>;
      }
    },
    {
      name: 'Actions',
      numeric: false,
      disablePadding: false,
      label: t('actions'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => history.push('teamMemberForm/edit/' + row.rowData[0])}
                >
                  <Create fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => removeRow(memberData[row.rowIndex])}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      position: 'absolute',
      width: 400,
      '@media (max-width:767px)': {
        width: '80% !important',
        left: '10% !important',
        top: '20% !important',
        transform: 'none !important'
      },
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3)
    },
    ModalBtn: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#fff !important',
      marginTop: '20px',
      zIndex: 99
    },
    btnDiv: {
      marginTop: '30px'
    },
    centerAlign: {
      textAlign: 'center'
    },
    addIconbtn: {
      padding: '5px 0'
    }
  }));

  const classes = useStyles();

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getmembersList(robotId);
  };

  return (
    <>
      <Header
        title={t('teamMember.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip title={t('teamMember.title')}>
            <IconButton>
              <Link
                to={{
                  pathname: 'teamMemberForm/create'
                }}
              >
                <AddCircleOutline />
              </Link>
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <EnhancedDataTable
        title={t('teamMember.title')}
        contentPlaceHolder={contentPlaceHolder}
        rows={memberData}
        columns={columns}
        rowsPerPageOptions={paginationRowPerArr}
        responsive='vertical'
      />
    </>
  );
}
