import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import {
  PanelHeader,
  TooltipIconButton
} from '../dialogs/components/actionbar';
import EnhancedModal from '../../components/Utility/EnhancedModal';
import ConfirmModal from '../../components/Utility/ConfirmModal';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import './survey.css';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import CPie from './pie';
import Legends from './Legends';
import { getApi, postApi, putApi, deleteApi } from '../../services/Services';
import { getCustomID } from '../../utills';
import { useSelector } from 'react-redux';

const COLORS = ['#2f3640', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function Survey(props) {
  const { selectedRobot } = props;
  const { t } = useTranslation('common');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const classes = useStyles();
  const language = useSelector(state => state.language.value);
  const [chartData, setChartData] = useState([]);
  const [checked, setCheked] = useState([]);
  const [actualList, setActualList] = useState([]);
  const [openAddModal, setopenAddModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = React.useState({
    question: ''
  });
  const [selectedChartQuestion, setChartSelectedQuestion] = React.useState({
    question: ''
  });
  const [optionInput, setOptionInput] = React.useState('');
  const [editQuestionId, setEditQuestionId] = useState('');
  const [selectID, setSelectID] = useState('');
  const [notEmpty, setNotEmpty] = useState({
    question: false,
  });

  const getQuestions = async () => {
    const serveyResponse = await getApi(
      `/survey?selectedRobot=${selectedRobot}&language=${language}`
    );
    if (serveyResponse.data && Object.values(serveyResponse.data).length > 0) {
      setQuestions(Object.values(serveyResponse.data));
      setActualList(Object.values(serveyResponse.data));
    } else {
      setQuestions([]);
      setActualList([]);
    }
    utility.loading(false);
  };

  const refreshList = async () => {
    utility.loading(true);
    getQuestions();
  };

  const showChart = (row) => {
    if (row.questionOptions.length > 0) {
      row.questionOptions.forEach((item, index) => {
        item.color = COLORS[index];
      });
    }
    setChartSelectedQuestion(row);
    setChartData(row.questionOptions);
  };

  const editQuestion = (row) => {
    setSelectedQuestion(row);
    setOptions(row.questionOptions);
    setEditQuestionId(row.id);
    setopenAddModal(true);
  };

  const deleteQuestion = async () => {
    utility.loading(true);
    const deleteResponse = await deleteApi(
      `/survey?id=${selectID}&&selectedRobot=${selectedRobot}`
    );
    if (deleteResponse.statusCode === 200) {
      utility.snack('Successfully Delete');
      refreshList();
    }
    setOpenConfirmModal(false);
    utility.loading(false);
  };

  useEffect(() => {
    setSelectID(selectedRobot);
    if (selectedRobot) {
      refreshList();
    }
  }, [selectedRobot, language]);

  const deleteQuestionModal = async (id) => {
    setSelectID(id);
    setOpenConfirmModal(true);
  };

  const colums = [
    {
      id: 'question',
      name: 'question',
      numeric: false,
      disablePadding: false,
      label: t('survey.name'),
      options: {
        customBodyRender: (row) => {
          return <Typography>{row}</Typography>;
        }
      }
    },
    {
      id: 'action',
      name: 'action',
      numeric: false,
      sortable: false,
      disablePadding: false,
      label: t('survey.action'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <div className="table-actions" style={{ display: 'flex' }}>
              <TooltipIconButton
                size="small"
                title="Survey"
                onClick={() => showChart(questions[row.rowIndex])}
                icon={<AssessmentIcon />}
              />
              <TooltipIconButton
                size="small"
                title="Edit"
                onClick={() => {
                  editQuestion(questions[row.rowIndex]);
                }}
                icon={<EditIcon />}
              />
              <TooltipIconButton
                size="small"
                title="Delete"
                onClick={() => {
                  deleteQuestionModal(questions[row.rowIndex].id);
                }}
                icon={<DeleteIcon />}
              />
            </div>
          );
        }
      }
    }
  ];

  const onSelect = useCallback((selected) => {
    setCheked(selected);
  }, []);

  const onSelectOptions = (updatedOptions) => {
    const newArr = options.map((item) => {
      if(item.id === updatedOptions.id) {
        return {
          ...item,
          is_correct_answer: !!!item.is_correct_answer
        }
      }
      return item
    })
    setOptions(newArr);
  };

  const onRemoveOptions = (updatedOptions) => {
    const newArr = options.filter(item => item.id !== updatedOptions.id);
    setOptions(newArr);
  };

  const createSurvey = async () => {
    let response = {};
    if (selectedQuestion.question) {
      utility.loading(true);
      if (editQuestionId) {
        const res = await putApi(`/survey?selectedRobot=${selectedRobot}&language=${language}`, {
          id: editQuestionId,
          question: selectedQuestion.question
        });
        response = res;
      } else {
        const res = await postApi(`/survey?selectedRobot=${selectedRobot}&language=${language}`, {
          question: selectedQuestion.question
        });
        response = res;
      }

      if (response.statusCode === 200) {
        utility.loading(false);
        setSelectedQuestion({ question: '' });
        setopenAddModal(false);
        setEditQuestionId('');
        refreshList();
        setOptions([]);
        setNotEmpty({question: false})
      }
    }else{
      const emotyVal = {
        question: false,
      };
      if (!selectedQuestion.question) emotyVal.question = true;
      if (options.length < 1) emotyVal.options = true;

      setNotEmpty(emotyVal)
      refreshList();
    }
  };

  const onModalClose = () => {
    setEditQuestionId('');
    setSelectedQuestion({ question: '' });
    setOptions([]);
  };

  const onCancelSearch = () => {
    setQuestions(actualList);
  };

  const onSearch = (e) => {
    const search_text = e.target.value;

    const list = actualList.filter(
      (item) =>
        item.question.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
    );

    if (search_text === '') {
      return onCancelSearch();
    }
    setQuestions(list);
  };

  const onOptionChange = async (e) => {
    if (e.key === 'Enter' && e.target.value) {
      const value = e.target.value;
      setOptions([
        ...options || [],
        {
          id: getCustomID(),
          value,
          chartValue: 0
        }
      ]);
      setOptionInput('');
    }
  };

  const onQuestionChange = (e) => {
    const value = e.target.value;
    setSelectedQuestion({
      ...selectedQuestion,
      question: value
    });
  };

  const chart =
    chartData.length > 0 ? (
      <>
        <Legends data={chartData} />
        {selectedChartQuestion.question ? (
          <span className={classes.questionInChart}>
            {selectedChartQuestion.question}
          </span>
        ) : null}
        <CPie data={chartData} />
      </>
    ) : (
      <span>{t('survey.chartNotFound')}</span>
    );

  const renderQuestionModal = () => {
    return (
      <EnhancedModal
        open={openAddModal}
        onOk={createSurvey}
        onCancel={() => setopenAddModal(false)}
        onClose={onModalClose}
      >
        <List>
          <ListItem>
            <TextField
              className={notEmpty.question ? classes.notEmty:''}
              id="question"
              label={t('survey.name')}
              variant="outlined"
              name="question"
              fullWidth
              multiline
              rows={4}
              onChange={onQuestionChange}
              value={selectedQuestion.question}
            />
          </ListItem>
        </List>
      </EnhancedModal>
    );
  };

  const renderConfirmModal = () => {
    return (
      <ConfirmModal
        open={openConfirmModal}
        onOk={() => deleteQuestion()}
        onCancel={() => setOpenConfirmModal(false)}
        onClose={() => setOpenConfirmModal(false)}
        conf
      >
        <List>
          <ListItem>
            <div>Do you want to delete?</div>
          </ListItem>
        </List>
      </ConfirmModal>
    );
  };

  const onAddModal = () => {
    setopenAddModal(!openAddModal);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PanelHeader
            title={t('survey.title')}
            padding="0"
            onCancelSearch={onCancelSearch}
            onSearch={onSearch}
            bgColor="transparent"
            onAdd={onAddModal}
            count={questions.length}
            isSelected={checked.length > 0}
            btnTitle={{
              add: 'Add Question'
            }}
            settings={{
              search: true,
              addNew: true
            }}
          />
          {renderQuestionModal()}
          {renderConfirmModal()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <EnhancedDataTable
            onSelect={onSelect}
            multiselect={false}
            showCheckbox={false}
            rows={questions}
            columns={colums}
            rowsPerPageOptions={paginationRowPerArr}
            hideCheckbox={true}
            responsive='vertical'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200
            }}
          >
            {chart}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
