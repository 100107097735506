import { useState } from 'react';
import * as firebase from 'firebase';
import { getCustomID } from '../utills';
import { postApi } from '../services/Services';
import { useUtility, useUtilityDispatch } from '../context/UtilityContext';

const useMediaUpload = ({ robotDetail, onSuccess, selectedRobot }) => {
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const [uploadingStatus, setUploadingSatus] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handleSubmit = async (data, type) => {
    try {
      let mediaData;
      if (type === 'images') {
        mediaData = {
          imageId: data.makeId,
          imageName: data.fileName,
          imageUrl: data.getDownloadURL
        };
      } else if (type === 'videos') {
        mediaData = {
          videoId: data.makeId,
          videoName: data.fileName,
          videoUrl: data.getDownloadURL,
          isVideo: true
        };
      } else {
        mediaData = {
          soundId: data.makeId,
          soundName: data.fileName,
          soundUrl: data.getDownloadURL,
          isSound: true
        };
      }
      await postApi(`/media-library?selectedRobot=${selectedRobot}`, mediaData);
      onSuccess(mediaData, type);
    } catch (e) {
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const handleChange = (event, type) => {
    let firebaseAgent;
    try {
      firebaseAgent = firebase.initializeApp(
        {
          projectId: robotDetail.project_id,
          databaseURL: robotDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robotDetail.project_id}.appspot.com`
        },
        robotDetail.id
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    firebaseAgent = firebase.app(robotDetail.id);
    setUploadingSatus(true);
    const files = event.target.files;
    for (const file of files) {
      if (!file) continue;
      const makeId = getCustomID();
      const fileNameGenarate = makeId + '___' + file.name;
      const storageRef = firebaseAgent
        .storage()
        .ref(`MediaLibrary/${type}/` + fileNameGenarate);

      const task = storageRef.put(file);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (percentage < 100) {
            setUploadPercentage(percentage);
          }
        },
        function error() {
          setUploadingSatus(false);
          utility.snack('Something went wrong please try again.', 'error');
        },
        function complete() {
          task.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              setUploadPercentage(100);
              const sendData = {
                fileName: fileNameGenarate,
                getDownloadURL: url,
                makeId
              };
              setTimeout(async () => {
                setUploadingSatus(false);
                setUploadPercentage(0);
                await handleSubmit(sendData, type);
                utility.snack('Media has been upload successfully.', 'success');
              }, 1000);
            })
            .catch((e) => {
              console.log(e);
              utility.snack('Something went wrong please try again.', 'error');
            });
        }
      );
    }
  };

  return {
    uploadingStatus,
    uploadPercentage,
    handleChange
  };
};

export default useMediaUpload;
