import React, { useState } from 'react';
import useStyles from './styles';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import './survey.css';
import SurveySection from './SurveySection';
import SurveyUserSection from './SurveyUserSection';
import Header from '../../components/Header';
// import Typography from '@material-ui/core/Typography';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* <Typography> */}
            {children}
          {/* </Typography> */}
        </Box>
      )}
    </div>
  );
}

export default function SurveyRoot() {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [selectedRobot, setSelectedRobot] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const renderTabs = () => {
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label={t('survey.surveyTab')} />
            <Tab label={t('survey.responsesTab')} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <SurveySection selectedRobot={selectedRobot} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <SurveyUserSection selectedRobot={selectedRobot} />
          </TabPanel>
        </SwipeableViews>
      </div>
    );
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
  };

  return (
    <div className={classes.root}>
      <Header
        title={t('survey.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      {renderTabs()}
    </div>
  );
}
