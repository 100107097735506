import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import 'firebase/storage';
import MediaLibraryGallery from '../../pages/mediaLibrary/components/MediaLibraryGallery';
import useStyles from '../../pages/presenter/styles';
import { getApi } from '../../services/Services';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MediaPicker = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    handleSelect,
    handleDelete,
    open,
    selectedRobotID,
    type,
    showVideo
  } = props;
  const [ImageList, setImageList] = useState([]);

  const getData = async () => {
    if (!selectedRobotID) return false;
    // utility.loading(true);
    try {
      const imageResponse = await getApi(
        `/all-media-library-images?selectedRobot=${selectedRobotID}`
      );
      if (imageResponse && imageResponse.statusCode === 200) {
        const imagesData = [];
        await imageResponse.data.forEach((item) => {
          imagesData.push({
            id: item.imageId,
            title: item.imageName,
            downloadlink: item.imageUrl
          });
        });

        setImageList(imagesData);
      }

      if (showVideo) {
        const videoResponse = await getApi(
          `/all-media-library-videos?selectedRobot=${selectedRobotID}`
        );
        if (videoResponse && videoResponse.statusCode === 200) {
          const videoData = [];
          await videoResponse.data.forEach((item) => {
            videoData.push({
              id: item.videoId,
              title: item.videoName,
              downloadlink: item.videoUrl
            });
          });
        }
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, [selectedRobotID]);

  return (
    <>
      <Grid container spacing={4}>
        <div>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  Gallery{' '}
                </Typography>
              </Toolbar>
            </AppBar>
            <div>
              {type === 'image' &&
                ImageList &&
                ImageList.length > 0 &&
                ImageList.map((each, index) => {
                  return (
                    <div key={index}>
                      <MediaLibraryGallery
                        item={each}
                        handleDelete={handleDelete}
                        type={type}
                        use={true}
                        handleSelect={handleSelect}
                      />
                    </div>
                  );
                })}
            </div>
          </Dialog>
        </div>
      </Grid>
    </>
  );
};

export default MediaPicker;
