import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Table from '../tables/Tables';
import { AddCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import { Fab, Tooltip, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Create, Delete } from '@material-ui/icons';
import { deleteApi, getApi, deleteFB } from '../../services/Services';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import * as firebase from 'firebase';
import 'firebase/storage';

const Robots = (props) => {
  const styles = {
    backgroundColor: 'green',
    color: '#fff',
    width: 34,
    height: 20
  };
  const { history } = props;
  const { t } = useTranslation('common');
  const [robotData, setRobotData] = useState([]);
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [userData, setUserData] = useState([]);

  const getRobotList = async () => {
    const response = await getApi('/robotlist');
    if (response.statusCode === 200) {
      setRobotData(response.data);
    }
  };

  const deleteRobot = async (keyId) => {
    utility.loading(true);
    if (window.confirm('Do you want to delete?')) {
      const deleteResponse = await deleteApi('/deleterobot', { keyId });
      if (deleteResponse.statusCode === 200) {
        utility.snack('Successfully Delete');
      }
    }
    utility.loading(false);
    await getRobotList();
  };

  const columns = [
    {
      label: t('robot.robotName'),
      name: 'robotName',
      id: 'robotName',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <span style={{ color: '#49b9e9' }}>{value}</span>;
        }
      }
    },
    {
      label: t('robot.user'),
      name: t('selectedUsers'),
      id: 'selectedUsers',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value && value.length > 0
            ? value.length > 1
              ? `${value[0].username}, ...`
              : value[0].username
            : '';
        }
      }
    },
    {
      label: t('robot.status'),
      name: t('status'),
      id: 'status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value === 'on') {
            return (
              <Tooltip title="Active">
                <Fab style={styles}>
                  <CheckIcon />
                </Fab>
              </Tooltip>
            );
          }
        }
      }
    },
    {
      label: t('actions'),
      name: 'key',
      options: {
        customBodyRender: (value) => {
          if (value) {
            return (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    onClick={() => {
                      history.push(`/app/editform/${value}`);
                    }}
                  >
                    <Create fontSize="inherit" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => deleteRobot(value)}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </>
            );
          }
        }
      }
    }
  ];

  async function getUsersList() {
    utility.loading(true);
    const usersRef = firebase.database().ref('users');
    usersRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const userData1 = JSON.parse(JSON.stringify(userData));
      for (const x in result) {
        userData1.push(result[x]);
      }
      console.log(userData1);
      setUserData(userData1);
      utility.loading(false);
    });
  }

  useEffect(() => {
    getUsersList();
    getRobotList();
  }, []);

  const onMultiDelete = async (items) => {
    const { data } = items;
    let showAlert = false;
    if (data.length > 0 && robotData.length > 0) {
      utility.loading(true);
      // Todo: make api for multi-delete robot and use it
      await data.forEach(async (row) => {
        showAlert = true;
        const seletedRobot = robotData[row.dataIndex];
        if (seletedRobot && seletedRobot.id)
          await deleteFB('Robots', seletedRobot.id);
      });
      utility.loading(false);
      if (showAlert) {
        utility.snack('Robots has been successfully deleted.');
        getRobotList();
      }
    }
  };
  return (
    <>
      <Header title={t('robot.title')} showRobotList={false} />
      <div>
        <Tooltip title={t('teamMember.title')}>
          <Link
            to={{
              pathname: 'robotform'
            }}
          >
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Link>
        </Tooltip>
      </div>
      <Table
        title={t('robot.title')}
        data={robotData}
        columns={columns}
        options={{ viewColumns: false }}
        onMultiDelete={onMultiDelete}
      />
    </>
  );
};

export default Robots;

// #######################################################################
