import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';

const DateRangeSection = (props) => {
  const { startText, endText, actionDateChange, views } = props;
  const [selectedStartDate, setSelectedStartDate] = useState(
    new Date().setMonth(new Date().getMonth() - 1)
  );
  const [selectedEndDate, setselectedEndDate] = useState(
    new Date().setMonth(new Date().getMonth())
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setselectedEndDate(date);
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate)
      actionDateChange([selectedStartDate, selectedEndDate]);
  }, [selectedStartDate, selectedEndDate]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={views}
              label={startText}
              helperText="Start from year selection"
              value={selectedStartDate}
              onChange={handleStartDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              openTo="year"
              views={views}
              label={endText}
              helperText="Start from year selection"
              value={selectedEndDate}
              onChange={handleEndDateChange}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </>
  );
};

DateRangeSection.propTypes = {
  actionDateChange: PropTypes.func,
  views: PropTypes.arrayOf(PropTypes.string)
};

DateRangeSection.defaultProps = {
  startText: 'Start-date',
  endText: 'End-date',
  actionDateChange: null,
  views: ['year', 'month']
};

export default memo(DateRangeSection);
