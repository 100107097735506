import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Avatar,
  Button,
  IconButton,
  TextField,
  Grid,
  ListItem,
  InputAdornment,
  Paper,
  ListItemIcon,
  Typography
} from '@material-ui/core';
import { AddCircleOutline, Delete, Clear, Backup } from '@material-ui/icons';
import 'firebase/storage';
import { useTranslation } from 'react-i18next';
import MediaPicker from '../../components/MediaPicker';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import clsx from 'clsx';
import useStyles from './styles';
import defaultImage from '../../images/default-image.png';
import { getApi, postApi, putApi } from '../../services/Services';
import {
  getCustomID,
  createOrUpdateIntent,
  updateIntentWithFind,
  removeEmptyItemInArray,
  GetSeletedRobot,
  GetTranslatedText
} from '../../utills';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';

const initialValues = {
  memberId: getCustomID(),
  memberName: '',
  image: '',
  training: [],
  response: [],
  intent_id: '',
  trainingPhaseTitle: ''
};

const validationSchema = Yup.object().shape({
  memberId: Yup.string().label('memberId').required('Required'),
  memberName: Yup.string().label('memberName').required('Required'),
  image: Yup.object()
    .shape({
      value: Yup.string().required('Required'),
      type: Yup.string().required('Required')
    })
    .required('Required'),
  training: Yup.array().label('training').required('Required'),
  response: Yup.array().label('response').required('Required'),
  intent_id: Yup.string().label('intent_id'),
  trainingPhaseTitle: Yup.string().label('trainPhaseTitle').required('Required')
});

export default function TeamMemberForm(props) {
  const classes = useStyles();
  const { history, match } = props;
  const { params } = match;
  const { id } = params;
  const teamMemberId = id;
  const language = useSelector(state => state.language.value);
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('add');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const timerRef = useRef(null);
  const [selectedRobot, setSelectedRobot] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let newtraining = await removeEmptyItemInArray(values.training);
      const newResponse = await removeEmptyItemInArray(values.response);
      utility.loading(true);
      const traniningPhrase = [];
      if (newtraining.length > 0) {
        await newtraining.forEach(async (item) => {
          const translatedText = await GetTranslatedText(item, 'en');
          traniningPhrase.push(translatedText);
        });
      }
      newtraining = traniningPhrase;
      if (type === 'add') {
        try {
          const intent = await createOrUpdateIntent({
            name: values.trainingPhaseTitle,
            training: newtraining,
            response: newResponse,
            selectedRobotID: selectedRobot
          });
          values.intent_id = intent && intent.data ? intent.data.id : '';
          values.memberImage = values.image.value;
          delete values.image;
          const response = await postApi(
            `/team-member?selectedRobot=${selectedRobot}&language=${language}`,
            values
          );
          if (response.statusCode === 200) {
            utility.snack(
              'TeamMembers has been successfully added.',
              'success'
            );
            timerRef.current = setTimeout(() => {
              history.push('/app/teamMember');
            }, 1000);
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
          utility.loading(false);
        } catch (e) {
          console.log(e);
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      } else {
        try {
          await updateIntentWithFind({
            name: values.trainingPhaseTitle,
            training: newtraining,
            response: newResponse,
            selectedRobotID: selectedRobot,
            id: values.intent_id
          });
          values.memberImage = values.image.value;
          delete values.image;
          const response = await putApi(
            `/team-member?selectedRobot=${selectedRobot}&&id=${teamMemberId}&&language=${language}`,
            values
          );
          if (response.statusCode === 200) {
            utility.snack(
              'TeamMembers has been successfully updated.',
              'success'
            );
            timerRef.current = setTimeout(() => {
              history.push('/app/teamMember');
            }, 1000);
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
        } catch (e) {
          console.log(e);
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      }
      utility.loading(false);
    }
  });

  const handleDelete = useCallback(() => {
    const genarateImage = {
      value: '',
      type: ''
    };
    formik.setFieldValue('image', genarateImage);
  }, []);

  const handleTrainingPhase = () => {
    let training = formik.values.training;
    if (!training || training.length === 0) {
      training = [];
    }
    training.push('');
    formik.setFieldValue('training', training);
  };
  const handleResponse = () => {
    let response = formik.values.response;
    if (!response || response.length === 0) {
      response = [];
    }
    response.push('');
    formik.setFieldValue('response', response);
  };

  const onChangeTrainingPhase = (i, e) => {
    if (formik.values.training.length > 0) {
      const training = formik.values.training;
      training[i] = e.target.value;
      formik.setFieldValue('training', training);
    }
  };
  const onChangeResponse = (i, e) => {
    if (formik.values.response.length > 0) {
      const response = formik.values.response;
      response[i] = e.target.value;
      formik.setFieldValue('response', response);
    }
  };
  const onCancelField = (index, isResponse) => {
    if (!isResponse) {
      formik.setFieldValue(
        'training',
        formik.values.training.filter((_, i) => i !== index)
      );
    } else {
      formik.setFieldValue(
        'response',
        formik.values.response.filter((_, i) => i !== index)
      );
    }
  };
  const getMemberInfo = async () => {
    utility.loading(true);
    if (teamMemberId) {
      setType('edit');
      try {
        const getSeletedRobot = GetSeletedRobot();
        setSelectedRobot(getSeletedRobot);
        const response = await getApi(
          `/team-member?id=${teamMemberId}&selectedRobot=${getSeletedRobot}&language=${language}`
        );
        if (
          response.statusCode === 200 &&
          response.data &&
          response.data.memberId
        ) {
          const result = response.data;
          const training =
            (result.training ? result.training : result.training_phase) || [];
          const translatedTraining = [];
          if (training && training.length > 0) {
            await training.forEach(async (item) => {
              const translatedText = await GetTranslatedText(item, 'ro');
              translatedTraining.push(translatedText);
            });
            result.training = translatedTraining;
          }

          setTimeout(() => {
            formik.setFieldValue('memberId', result.memberId);
            formik.setFieldValue('memberName', result.memberName);
            formik.setFieldValue('training', result.training);
            formik.setFieldValue('response', result.response);
            formik.setFieldValue(
              'trainingPhaseTitle',
              result.trainingPhaseTitle
            );
            formik.setFieldValue('intent_id', result.intent_id);
            formik.setFieldValue('image', {
              value: result.memberImage ? result.memberImage : '',
              type: result.memberImage
                ? result.memberImage.split('.').pop().split('?')[0]
                : ''
            });
          }, 1000);
        }
      } catch (e) {
        console.log(e.response);
        history.push('/app/teamMember');
        utility.snack('Data not found.', 'error');
      }
      utility.loading(false);
    } else {
      const id = getCustomID();
      formik.setFieldValue('memberId', id);
    }
  };
  useEffect(() => {
    if (teamMemberId) {
      getMemberInfo();
    } else {
      const id = getCustomID();
      formik.setFieldValue('memberId', id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (item, type) => {
    if (item && type && type === 'image') {
      const genarateImage = {
        value: item.downloadlink,
        type: item.downloadlink.split('.').pop().split('?')[0]
      };
      formik.setFieldValue('image', genarateImage);
      handleClose();
    }
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
  };

  return (
    <div>
      <Header
        title={
          teamMemberId ? t('teamMember.editTitle') : t('teamMember.createTitle')
        }
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <MediaPicker
        handleClose={handleClose}
        handleSelect={handleSelect}
        handleDelete={() => {}}
        open={open}
        selectedRobotID={selectedRobot}
        showVideo={false}
        type="image"
      />
      <form
        className="presenter-form"
        style={{ width: '100%', marginTop: 20 }}
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper style={{ padding: 15, width: '100%' }}>
              <h2 id="simple-modal-title">{t('teamMember.title')}</h2>
              <ListItemIcon>
                <Typography>Image</Typography>
              </ListItemIcon>

              <ListItem className={classes.concenterAlign}>
                <div className={classes.concenterAlign}>
                  <Avatar
                    src={
                      formik.values.image?.value
                        ? formik.values.image?.value
                        : defaultImage
                    }
                    variant="circular"
                    style={{
                      width: 100,
                      height: 100
                    }}
                  />
                </div>
              </ListItem>
              {formik.values.image?.value ? (
                <ListItem
                  className={clsx([
                    classes.concenterAlign,
                    classes.noHorizontalPadding
                  ])}
                >
                  <Avatar onClick={handleDelete}>
                    <Delete />
                  </Avatar>
                </ListItem>
              ) : (
                <ListItem
                  className={clsx([
                    classes.concenterAlign,
                    classes.noHorizontalPadding
                  ])}
                >
                  <>
                    <label
                      htmlFor="contained-button-file"
                      onClick={() => setOpen(true)}
                    >
                      <Avatar>
                        <Backup />
                      </Avatar>
                    </label>
                  </>
                </ListItem>
              )}
              <TextField
                label={t('memberName')}
                id="name"
                name="memberName"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.memberName && formik.touched.memberName
                    ? true
                    : null
                }
                helperText={
                  formik.errors.memberName && formik.touched.memberName
                    ? formik.errors.memberName
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.memberName}
                onBlur={formik.handleBlur}
              />
              <TextField
                label={t('presenter.trainingPhraseTitle')}
                id="trainingPhaseTitle"
                name="trainingPhaseTitle"
                variant="outlined"
                margin="normal"
                type="text"
                fullWidth
                error={
                  formik.errors.trainingPhaseTitle &&
                  formik.touched.trainingPhaseTitle
                    ? true
                    : null
                }
                helperText={
                  formik.errors.trainingPhaseTitle &&
                  formik.touched.trainingPhaseTitle
                    ? formik.errors.trainingPhaseTitle
                    : ''
                }
                onChange={formik.handleChange}
                value={formik.values.trainingPhaseTitle}
                onBlur={formik.handleBlur}
              />
              <Grid
                container
                spacing={4}
                // style={{ overflow: "scroll", overflowY: "auto", overflowX: "auto" }}
              >
                <Grid item xs={12} md={6} lg={6}>
                  <IconButton
                    onClick={() => {
                      handleTrainingPhase();
                    }}
                  >
                    <label style={{ fontSize: '15px' }}>
                      {t('presenter.RowAddTraning')}
                    </label>{' '}
                    <AddCircleOutline />
                  </IconButton>
                  {formik.values.training.map((v, i) => {
                    return (
                      <ListItem key={i}>
                        <TextField
                          id="training"
                          label={t('presenter.trainingPhrase')}
                          variant="outlined"
                          fullWidth
                          name="training"
                          error={
                            formik.errors.training && formik.touched.training
                              ? true
                              : null
                          }
                          helperText={
                            formik.errors.training && formik.touched.training
                              ? formik.errors.training
                              : ''
                          }
                          onChange={(e) => {
                            onChangeTrainingPhase(i, e);
                          }}
                          value={v}
                          onBlur={formik.handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  onClick={() => onCancelField(i, false)}
                                >
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <IconButton
                    onClick={() => {
                      handleResponse();
                    }}
                  >
                    <label style={{ fontSize: '15px' }}>
                      {t('presenter.RowAddResponse')}
                    </label>{' '}
                    <AddCircleOutline />
                  </IconButton>
                  {formik.values.response &&
                    formik.values.response.map((v, i) => {
                      return (
                        <ListItem key={i}>
                          <TextField
                            id="response"
                            label={t('presenter.response')}
                            variant="outlined"
                            name="response"
                            fullWidth
                            // multiline
                            // rows={4}
                            error={
                              formik.errors.response && formik.touched.response
                                ? true
                                : null
                            }
                            helperText={
                              formik.errors.response && formik.touched.response
                                ? formik.errors.response
                                : ''
                            }
                            onChange={(e) => {
                              onChangeResponse(i, e);
                            }}
                            value={v}
                            onBlur={formik.handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    onClick={() => {
                                      onCancelField(i, true);
                                    }}
                                  >
                                    <Clear />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </ListItem>
                      );
                    })}
                </Grid>
              </Grid>
              <ListItem>
                <Button variant="contained" color="primary" type="submit">
                  Save{' '}
                </Button>
              </ListItem>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
