import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from '../../context/LayoutContext';

// styles
import useStyles from './styles';
import menuList from '../Layout/menu.json';

const Sidebar = ({ location }) => {
  const [structure, setStructure] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  useEffect(() => {
    (async () => {
      const logoutMenu = {
        id: Date.now(),
        label: 'logout.title',
        link: '/app/logout',
        icon: <Icons.ExitToApp />
      };
      const newMenu = [];
      for (const x in menuList) {
        let obj = {};
        obj = menuList[x];
        obj['labelfbId'] = x;
        newMenu.push(obj);
      }
      // const allMenu = await getMenuList();
      const allMenu = newMenu.map((each) => {
        const SpeacificIcon = Icons[each.icon];
        return {
          ...each,
          icon: <SpeacificIcon />
        };
      });
      if (allMenu.length > 0) {
        const user = localStorage.getItem('user');
        const returnUserdata = JSON.parse(user);
        const temp = [];
        if (
          returnUserdata &&
          returnUserdata.permissions &&
          returnUserdata.permissions.length > 0
        ) {
          const userSelected = returnUserdata.permissions;
          if (userSelected) {
            if (userSelected && userSelected.length > 0) {
              userSelected.forEach((each) => {
                const index = allMenu
                  .map(function (e) {
                    return e.labelfbId;
                  })
                  .indexOf(each);
                if (index !== -1) {
                  temp.push({
                    ...allMenu[index]
                  });
                }
              });
            }
          }
        }
        temp.push({ ...logoutMenu });
        setStructure(temp);
      }
    })();
  }, []);

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}
    >
      <div className={classes.togglebtn}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>☰</IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
};

export default withRouter(Sidebar);
