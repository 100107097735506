import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { Grid, Paper, Select, MenuItem } from '@material-ui/core';
import { PanelHeader } from '../dialogs/components/actionbar';
import './survey.css';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import CBar from './bar';
import { getApi } from '../../services/Services';

export default function Survey(props) {
  const { t } = useTranslation('common');
  const { selectedRobot } = props;
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [surveyResponse, setSurveyResponse] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('select');

  const getQuestions = async () => {
    const serveyResponse = await getApi(
      `/survey-responses?selectedRobot=${selectedRobot}`
    );
    if (serveyResponse.data && Object.values(serveyResponse.data).length > 0) {
      setSurveyResponse(Object.values(serveyResponse.data));
    } else {
      setSurveyResponse([]);
    }
  };

  const refreshList = (callback) => {
    utility.loading(true);
    getQuestions().then((qlist) => {
      if (callback) callback(qlist);
      utility.loading(false);
    });
  };

  useEffect(() => {
    if (selectedRobot) {
      refreshList();
    }
  }, [selectedRobot]);

  const handelSurveyChange = (e) => {
    const value = e.target.value;
    setSelectedSurvey(value);
    if (value && value !== 'select') {
      surveyResponse.length > 0 &&
        surveyResponse.forEach((item) => {
          if (value === item.id) {
            setChartData(item.responses);
          }
        });
    }
  };

  const chart =
    chartData.length > 0 ? (
      <>
        <CBar data={chartData} />
      </>
    ) : (
      <span>No chart data available.</span>
    );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PanelHeader
            title={t('survey.responses.title')}
            padding="0"
            onCancelSearch={() => {}}
            onSearch={() => {}}
            bgColor="transparent"
            onAdd={() => {}}
            isSelected={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={t('robot.user')}
            id="user"
            name="user"
            onChange={handelSurveyChange}
            value={selectedSurvey}
          >
            <MenuItem value="select"> Select Survey Response</MenuItem>
            {surveyResponse &&
              surveyResponse.length > 0 &&
              surveyResponse.map((v, i) => {
                return (
                  <MenuItem key={i} value={v.id}>
                    {v.id}
                  </MenuItem>
                );
              })}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200
            }}
            className={classes.paper}
          >
            {chart}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
