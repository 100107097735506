import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    marginBottom: '30px'
  },
  rootCard: {
    marginBottom: '30px'
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500
  },
  textField: {
    width: '100%'
  }
}));
