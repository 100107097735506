import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import {
  Button,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { AddCircleOutline, Create, Delete } from '@material-ui/icons';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { getApi, postApi, putApi, deleteApi } from '../../services/Services';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import { useSelector } from 'react-redux';

export default function Faq() {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const language = useSelector(state => state.language.value);
  const [open, setOpen] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqId, setFaqId] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');
  const [actiontype, setActionType] = useState('');
  const [key, setKey] = useState('');
  const [selectedRobot, setSelectedRobot] = useState('');
  const [notEmpty, setNotEmpty] = useState({
    question: false,
    answer: false
  });
  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  };
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const [modalStyle] = useState(getModalStyle);

  const getFaqList = async (robotId) => {
    utility.loading(true);
    try {
      const selectedRobotID = robotId ? robotId : selectedRobot;
      if(selectedRobotID){
        const response = await getApi(
          `/faqslist?selectedRobot=${selectedRobotID}&language=${language}`
        );
        if (response && response.data && response.data.faq) {
          const faqData = response.data.faq;
          if (faqData.length > 0) {
            setFaqList(faqData);
            utility.loading(false);
          } else {
            setFaqList([]);
            utility.loading(false);
          }
        }
      }
    } catch (err) {
      setFaqList([]);
      utility.loading(false);
      throw new Error("Faq list Error", err);
    }
  };

  useEffect(() => {
    (async () => {
      await getFaqList();
    })();
  }, [language]);

  const openModal = () => {
    setOpen(true);
    // formik.setFieldValue("id", uuidv4());
  };

  const closeModal = () => {
    setOpen(false);
    setFaqAnswer('');
    setFaqQuestion('');
    setFaqId('');
    setKey('');
  };

  const addQuestion = (e) => {
    if (e.target.value || faqQuestion) {
      setFaqQuestion(e.target.value);
    }
  };

  const addAnswer = (e) => {
    if (e.target.value || faqAnswer) {
      setFaqAnswer(e.target.value);
    }
  };

  const notEmptyValid = ()=> {
    let notEmptyData = {
      question: false,
      answer: false
    }
    if (!faqQuestion) notEmptyData.question = true;
    if (!faqAnswer) notEmptyData.answer = true;
    setNotEmpty(notEmptyData);
  }

  const saveFaqs = async () => {
    utility.loading(true);
    const faqId = uuidv4();
    const postData = {
      faqId,
      faqQuestion,
      faqAnswer
    };
    notEmptyValid();
    if (faqQuestion && faqAnswer) {
      closeModal();
      try {
        const r = await postApi(
          `/savefaq?selectedRobot=${selectedRobot}&language=${language}`,
          postData
        );
        if (r.statusCode === 200) {
          utility.snack('Successfully Save Faq');
        }
        await getFaqList();
      } catch (e) {
        utility.snack(e.response.data.message, 'error');
      }
    }
    utility.loading(false);
  };

  const openDialogEdit = (keyId) => {
    setActionType('edit');
    const e = faqList.find((e) => e.key === keyId);
    setKey(keyId);
    setFaqAnswer(e.faqAnswer);
    setFaqQuestion(e.faqQuestion);
    setFaqId(e.faqId);
    setOpen(true);
  };

  const editFaqs = async () => {
    utility.loading(true);
    const editData = {
      faqId,
      faqQuestion,
      faqAnswer
    };
    notEmptyValid();

    if (faqQuestion && faqAnswer) {
      if (key) {
        closeModal();
        const r = await putApi(
          `/editfaq?selectedRobot=${selectedRobot}&&key=${key}&language=${language}`,
          editData
        );
        if (r.statusCode === 200) {
          utility.snack('Successfully update Faq');
        }
      }
      await getFaqList();
    }
    utility.loading(false);
  };

  const deleteFaq = async (keyId) => {
    utility.loading(true);
    const d = await deleteApi(`/deletefaq?selectedRobot=${selectedRobot}`, {
      keyId
    });
    if (d.statusCode === 200) {
      utility.snack('Successfully Delete');
    }
    utility.loading(false);
    await getFaqList();
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getFaqList(robotId);
  };

  const ModalBody = (
    <div className={classes.centerAlign}>
      <h2 id="simple-modal-title">{t('faq.title')}</h2>
      <List className={classes.noHorizontalPadding}>
        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            className={notEmpty.question ? classes.notEmpty:''}
            label={t('Question')}
            id="faqQuestion"
            margin="normal"
            type="text"
            fullWidth
            name="faqQuestion"
            value={faqQuestion}
            onChange={(e) => {
              addQuestion(e);
            }}
          />
        </ListItem>
        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            className={notEmpty.answer ? classes.notEmpty:''}
            label={t('Answer')}
            id="faqAnswer"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            name="faqAnswer"
            value={faqAnswer}
            onChange={(e) => {
              addAnswer(e);
            }}
          />
        </ListItem>
      </List>
    </div>
  );
  const columns = [
    {
      label: t('faq.question'),
      name: 'faqQuestion',
      options: {
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        }
      }
    },
    {
      label: t('faq.answer'),
      name: 'faqAnswer',
      options: {
        customBodyRender: (value) => {
          return <Typography>{value}</Typography>;
        }
      }
    },
    {
      name: t('action'),
      numeric: false,
      sortable: false,
      disablePadding: false,
      label: t('action'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <div className="table-actions" style={{ display: 'flex' }}>
            <Tooltip title={t('faq.editFaq')}>
              <IconButton
                aria-label="Edit"
                color="primary"
                onClick={() => openDialogEdit(faqList[row.rowIndex].key)}
              >
                <Create fontSize="inherit" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('delete')}>
              <IconButton
                aria-label="delete"
                color="secondary"
                onClick={() => deleteFaq(faqList[row.rowIndex].key)}
              >
                <Delete fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
          );
        }
      }
    }
  ];

  const onSelect = useCallback(() => {}, []);

  return (
    <>
      <Header
        title={t('faq.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <div>
        <div className={classes.addIconbtn}>
          <Tooltip
            title={t('faq.title')}
            onClick={() => {
              setActionType('add');
              openModal();
            }}
          >
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="simple-modal-question"
          aria-describedby="simple-modal-answer"
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <form>
              {ModalBody}
              <div className={classes.btnDiv}>
                <Button onClick={closeModal} variant="contained">
                  {t('close')}
                </Button>
                <Button
                  onClick={() => {
                    actiontype === 'add' ? saveFaqs() : editFaqs();
                  }}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: '20px' }}
                >
                  {' '}
                  {actiontype === 'add' ? t('save') : t('update')}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <EnhancedDataTable
        title={t('List FAQS')}
        onSelect={onSelect}
        multiselect={false}
        showCheckbox={false}
        rows={faqList}
        columns={columns}
        rowsPerPageOptions={paginationRowPerArr}
        hideCheckbox={true}
        responsive='vertical'
      />
    </>
  );
}

// #######################################################################
