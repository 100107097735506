import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  pageTitle: {
    fontWeight: '500',
    fontSize: 'calc(22px)',
    textAlign: 'left'
  },
  storytellingListWrapper: {
    marginTop: 10,
    borderRadius: '6px',
    padding: '15px 30px'
  },
  storytellingList: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  storytellingImg: {
    marginTop: 10,
    marginRight: 10
  },
  addStorytellingListWrapper: {
    marginTop: 10,
    borderRadius: '6px',
    padding: '15px 30px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: 300,
    minHeight: 300
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    height: '100%'
  },
  saveButton: {
    marginLeft: 'auto'
  },
  mediaContainer: {
    width: '100%',
    flex: 1,
    padding: '15px 30px',
    display: 'flex'
  },
  responseContainer: {
    width: '100%',
    flex: 1,
    padding: '15px 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  mediaWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    height: '300px',
    minHeight: 'calc(100% - 45px)',
    padding: '1px 5px 5px 5px',
    [theme.breakpoints.down('sm')]: {
      height: '300px'
    }
  },
  soundsTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    }
  },
  animationWrapper: {
    width: 140,
    height: 100,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  animationItem: {
    width: '100%',
    padding: '5px 20px',
    borderRadius: 5,
    boxShadow: '0px 1px 5px #999',
    marginTop: 10
  },
  animationTitle: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  soundItem: {
    width: '100%',
    height: 50,
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    borderRadius: '50px',
    marginTop: 10,
    boxShadow: '0px 1px 5px #999'
  },
  soundName: {
    width: 210,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  uploadAudioBtn: {
    cursor: 'pointer',
    width: 24,
    height: 24
  },
  storytellingInputCard: {
    padding: '15px',
    borderRadius: '14px',
    boxShadow: '0 1px 5px #999',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px',
    minWidth: '400px',
    height: '260px',
    marginRight: 20,
    position: 'relative'
  },
  removeCard: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  storytellingDescInput: {
    background: '#e3f4fc',
    height: '120px',
    marginTop: 'auto',
    width: '100%',
    borderRadius: '6px',
    padding: '5px 10px',
    border: 0,
    resize: 'none',
    fontFamily: 'unset',
    '&:focus': {
      outline: 'none'
    }
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%'
  },
  storytellerItemCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 15
  },
  storytellerItemThumbnail: {
    width: '200px',
    height: '150px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 16
  },
  storytellerItemTitleWrapper: {
    padding: '2px 15px',
    width: '170px',
    background: '#e3f4fc',
    borderRadius: 6,
    marginTop: 10
  },
  storytellerTitle: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  videoWrapper: {
    position: 'relative',
    height: '-webkit-fill-available',
    padding: 15,
    [theme.breakpoints.down('sm')]: {
      height: 300,
      marginTop: 20
    }
  }
}));
