import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import * as Yup from 'yup';
import * as axios from 'axios';
import { useFormik } from 'formik';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Typography,
  Paper,
  Button,
  IconButton,
  Grid,
  Box,
  TextField,
  ListItem,
  InputAdornment
} from '@material-ui/core';
import { AddCircleOutline, Clear, HelpOutline } from '@material-ui/icons';
import 'firebase/storage';
import Header from '../../components/Header';
import { getApi, postApi, putApi } from '../../services/Services';
import ImagePicker from './components/imagePicker';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import ProgressBar from '../../components/ProgressBar';
import useMediaUpload from '../../hooks/useMediaUpload';
import useStyles from './styles';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { removeEmptyItemInArray } from '../../utills';

const initialValues = {
  title: '',
  presentations: [],
  training: [],
  response: [],
};

const validationSchema = Yup.object().shape({
  title: Yup.string().label('title').required('Required'),
  presentations: Yup.array()
    .of(
      Yup.object().shape({
        text: Yup.string().required('Required'),
        image: Yup.string().required('Required')
      })
    )
    .label('presentations')
    .nullable(),
  training: Yup.array().label('training'),
  response: Yup.array().label('response'),
});

function CreateStortytelling(props) {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const { history, match } = props;
  const { params } = match;
  const { id } = params;

  const audioPlayerRef = useRef();
  const language = useSelector(state => state.language.value);
  const [robotAnimationVideos, setRobotAnimationVideos] = useState([]);
  const [sounds, setSounds] = useState([]);
  const [selectedRobot, setSelectedRobot] = useState();
  const [ImageList, setImageList] = useState([]);
  const [robotDetail, setRobotDetail] = useState({});
  const [videoURl, setVideoURL] = useState('');
  const [playAudio, setPlayAudio] = useState(null);
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [selectedPresentation, setSelectedPresentation] = useState();
  const [isHovering, setIsHovering] = useState(false);

  const getSoundsList = async () => {
    if (selectedRobot) {
      const soundRespose = await getApi(
        `/all-media-library-sounds?selectedRobot=${selectedRobot}`
      );
      if (soundRespose && soundRespose.statusCode === 200){
        setSounds(soundRespose.data);
      }
    }
  };

  const getActionText = (text) => {
    const textLength = text.length;
    let result = '';
    const soundNameList = [];
    for (let i = 0; i < textLength + 1; i++) {
      const animationRegArray = [...result.matchAll('{(.*)}')];
      const soundsRegArray = [...result.matchAll('[(.*)]')];
      if (animationRegArray[0]?.index || soundsRegArray[0]?.index) {
        const animationName = result.substring(
          result.indexOf('{') + 1,
          result.lastIndexOf('}')
        );
        const soundName = result.substring(
          result.indexOf('[') + 1,
          result.lastIndexOf(']')
        );
        if (animationName) {
          result = result.replace(
            /\{(.*?)\}/,
            `<mark name='${animationName}'/>`
          );
        }
        if (soundName) {
          result = result.replace(/\[(.*?)\]/, `<mark name='${soundName}'/>`);
          soundNameList.push(soundName);
        }
      }
      if (text[i]) {
        result += text[i];
      }
    }
    let finishData = {
      actionText: `<speak>${result}</speak>`,
    }
    if (sounds.length > 0) {
      finishData.soundsList = sounds
          .filter((item) => soundNameList.includes(item.soundName))
          .map((final) => final.soundUrl)
    }
    return finishData;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let newtraining = await removeEmptyItemInArray(values.training);
      let newResponse = await removeEmptyItemInArray(values.response);
      utility.loading(true);
      const saveData = {
        title: values.title,
        presentations: values.presentations
          .filter((item) => item.image !== '' && item.text !== '')
          .map((presentationItem) => {
            const { actionText, soundsList } = getActionText(
              presentationItem.text
            );
            return {
              actionText,
              imageUrl: presentationItem.image,
              printText: presentationItem.text,
              sounds: soundsList
            };
          }),
        training: newtraining,
        response: newResponse,
      };

      postApi(`/storyteller?selectedRobot=${selectedRobot}&language=${language}${id?`&oldTitle=${id}`:''}`, saveData)
      .then(( resp ) => {
          if (resp.data && resp.statusCode === 200) {
            newtraining.push("presentation_"+resp.data.title+"");
            newResponse.push("");

            if (newtraining.length > newResponse.length) {
              newResponse = [...newResponse, Array(newtraining.length - newResponse.length).fill("")]
            }

            if(id){
              axios
              .get(`/allintents?selectedRobot=${selectedRobot}`)
              .then(({data}) => {
                let re = new RegExp(`(^${language.toLowerCase()}_)|(^${language.toUpperCase()}_)`, 'gi');
                let isExists = false;

                for (let intent of data.intents) {
                  if (intent.name.replace(/(PRESENTATION )/gi, '').replace(re, '') == id) {
                    getApi(`/intent?selectedRobot=${selectedRobot}&&id=${intent.id}&&language=${language}`)
                    .then(({data}) => {
                      putApi(`/intent?selectedRobot=${selectedRobot}`, {
                        id: data.id,
                        name: 'PRESENTATION ' + resp.data.title,
                        question: {items: newtraining},
                        response: {items: newResponse},
                        language: language
                      }).then(() => {
                        utility.loading(false);
                        history.push('/app/storytellings/list');
                      })
                    })
                    break;
                  }
                }

                if (!isExists) {
                  axios
                    .post(`/intent?selectedRobot=${selectedRobot}&language=${language}`, {
                      name: 'PRESENTATION' + ' ' + resp.data.title,
                      language: language,
                      question: { items: newtraining},
                      response: { items: newResponse },
                    })
                    .then(() => {
                      utility.loading(false);
                      history.push('/app/storytellings/list');
                    })
                    .catch(function (error) {
                      console.error(error);
                    })
                }
              })
            } else {
              axios
                .post(`/intent?selectedRobot=${selectedRobot}&language=${language}`, {
                  name: 'PRESENTATION' + ' ' + resp.data.title,
                  language: language,
                  question: { items: newtraining},
                  response: { items: newResponse },
                })
                .then(() => {
                  utility.loading(false);
                  history.push('/app/storytellings/list');
                })
                .catch(function (error) {
                  console.error(error);
                })
              }
            }
            })
        .catch((error) => {
          console.error(error);
          utility.loading(false);
          utility.snack(error.response.data.message, 'error');
        });
    }
  });

  const changeStorytellingItem = (item, index) => {
    const temp = [...formik.values.presentations];
    temp[index] = item;

    formik.setFieldValue('presentations', temp);
  };

  const { uploadingStatus, uploadPercentage, handleChange } = useMediaUpload({
    robotDetail,
    selectedRobot,
    onSuccess: (mediaData) => {
      if (openImagePicker) {
        const newImageItem = {
          id: mediaData.imageId,
          title: mediaData.imageName,
          downloadlink: mediaData.imageUrl
        };
        setImageList([...ImageList, newImageItem]);
        changeStorytellingItem(
          { ...selectedPresentation?.item, image: mediaData.imageUrl },
          selectedPresentation?.index
        );
        setOpenImagePicker(false);
      } else {
        getSoundsList();
      }
    }
  });

  useEffect(() => {
    if (robotAnimationVideos.length > 0) {
      setVideoURL(robotAnimationVideos[0].url);
    }
  }, [robotAnimationVideos]);

  const getData = async () => {
    try {
      if (selectedRobot) {
        const imageResponse = await getApi(
          `/all-media-library-images?selectedRobot=${selectedRobot}`
        );

        if (imageResponse && imageResponse.statusCode === 200) {
          const imagesData = [];
          imageResponse.data.forEach((item) => {
            imagesData.push({
              id: item.imageId,
              title: item.imageName,
              downloadlink: item.imageUrl
            });
          });

          setImageList(imagesData);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    (async () => {
      if (selectedRobot) {
        utility.loading(true);

        if (id) {
          const { data } = await getApi(
            `/storyteller?selectedRobot=${selectedRobot}&title=${id}&language=${language}`
          );
          formik.setFieldValue('title', data.title);          
          formik.setFieldValue(
            'presentations',
            (data.presentations || []).map((item) => {
              return {
                image: item.imageUrl,
                text: item.printText
              };
            })
          );
        }

        getData();
        getSoundsList();

        (async () => {
          const response = await getApi('/get-robot-videos-list');
          const apiData = response.data;
          if (response.statusCode === 200) setRobotAnimationVideos(apiData);
        })();
        utility.loading(false);
      }
    })();
  }, [id, selectedRobot]);

  useEffect(() => {
    if (formik.values.presentations.length === 0) {
      formik.setFieldValue('presentations', [
        {
          image: '',
          text: ''
        }
      ]);
    }
  }, [formik.values.presentations]);

  const handleOnDragEnd = (result) => {
    if (
      !result.destination ||
      !result.destination.droppableId.includes('presentation')
    ) {
      return;
    }
    const tempStorytellers = [...formik.values.presentations];
    const targetPresentationIndex = parseInt(
      result.destination.droppableId.split('-')[1]
    );
    const actionName =
      result.source.droppableId === 'animationList'
        ? `{${robotAnimationVideos[result.source.index].title}}`
      : (sounds[result.source.index] ? `[${sounds[result.source.index].soundName}]` : '');

    if (actionName) {
      tempStorytellers[targetPresentationIndex].text += (' ' + actionName);
    }

    if(result.source.droppableId !== 'animationList'){
      const [reorderedItem] = tempStorytellers.splice(result.source.index, 1);
      tempStorytellers.splice(result.destination.index, 0, reorderedItem);
    }

    formik.setFieldValue('presentations', tempStorytellers);
  };

  const onHeaderRobotSelected = (robotId, robot) => {
    setSelectedRobot(robotId);
    getData(robotId);
    if (robot) setRobotDetail(robot);
  };

  const handleTrainingPhase = () => {
    let training = formik.values.training;
    if (!training || training.length === 0) {
      training = [];
    }
    training.push('');
    formik.setFieldValue('training', training);
  };

  const handleResponse = () => {
    let response = formik.values.response;
    if (!response || response.length === 0) {
      response = [];
    }
    response.push('');
    formik.setFieldValue('response', response);
  };

  const onChangeTrainingPhase = (i, e) => {
    if (formik.values.training.length > 0) {
      const training = formik.values.training;
      training[i] = e.target.value;
      formik.setFieldValue('training', training);
    }
  };

  const onChangeResponse = (i, e) => {
    if (formik.values.response.length > 0) {
      const response = formik.values.response;
      response[i] = e.target.value;
      formik.setFieldValue('response', response);
    }
  };

  const onCancelField = (index, isResponse) => {
    if (!isResponse) {
      formik.setFieldValue(
        'training',
        formik.values.training.filter((_, i) => i !== index)
      );
    } else {
      formik.setFieldValue(
        'response',
        formik.values.response.filter((_, i) => i !== index)
      );
    }
  };

  return (
    <>
      <Header
        title={t('storytelling.createTitle')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-image-input"
        multiple
        type="file"
        onChange={(e) => handleChange(e, 'images')}
      />
      <form
        className={classes.pageWrapper}
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <TextField
          name="title"
          label={t('storytelling.storytellerTitle')}
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.title && formik.touched.title ? true : null}
          variant="standard"
          helperText={
            formik.errors.title && formik.touched.title
              ? formik.errors.title
              : ''
          }
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          type="submit"
        >
          {t('storytelling.save')}
        </Button>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Paper className={classes.addStorytellingListWrapper}>
            {formik.values.presentations.map((item, i) => (
              <Droppable droppableId={`presentation-${i}`} key={i}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <Draggable key={i} draggableId={`presentation-${i}`} index={i}>
                        {(provided) => (
                      <div
                      key={i}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                    <Box
                      className={classes.storytellingInputCard}
                      style={{
                        border:
                          formik.submitCount > 0 &&
                          formik?.errors?.presentations &&
                          (formik?.errors?.presentations[i]?.text ||
                            formik?.errors?.presentations[i]?.image)
                            ? '1px solid red'
                            : 'none'
                      }}
                    >
                      {formik.values.presentations.length !== 1 && (
                        <IconButton
                          edge="start"
                          color="inherit"
                          size="small"
                          className={classes.removeCard}
                          onClick={() => {
                            const temp = [...formik.values.presentations];
                            temp.splice(i, 1);
                            formik.setFieldValue('presentations', temp);
                          }}
                        >
                          <CloseIcon style={{ fontSize: 16, color: '#999' }} />
                        </IconButton>
                      )}
                      <ImagePicker
                        image={item?.image}
                        imageList={ImageList}
                        uploadingStatus={uploadingStatus}
                        uploadPercentage={uploadPercentage}
                        handleChange={handleChange}
                        setOpenImagePicker={setOpenImagePicker}
                        onClickPickerOpen={() =>
                          setSelectedPresentation({
                            item,
                            index: i
                          })
                        }
                        openImagePicker={openImagePicker}
                        onChangeHandler={(imageLink) => {
                          changeStorytellingItem(
                            { ...selectedPresentation.item, image: imageLink }, selectedPresentation.index );
                        }}
                        handleRemoveImage={() =>
                          changeStorytellingItem({ ...item, image: '' }, i)
                        }
                      />
                      <textarea
                        value={item?.text}
                        placeholder={t('storytelling.inputPlaceholder')}
                        onChange={(e) => {
                          changeStorytellingItem(
                            { ...item, text: e.target.value },
                            i
                          );
                        }}
                        className={classes.storytellingDescInput}
                      />
                    </Box>
                    <span>
                      {provided.placeholder}
                    </span>
                    </div>
                        )}
                    </Draggable>
                  </div>
                  )}
              </Droppable>
            ))}
            <IconButton
              onClick={() =>
                formik.setFieldValue('presentations', [
                  ...formik.values.presentations,
                  { text: '', image: '' }
                ])
              }
            >
              <AddCircleOutline />
            </IconButton>
          </Paper>
          <Box
            position="relative"
            width="100%"
            marginTop="20px"
            style={{ height: '20px' }}
          >
            {uploadingStatus && (
              <div className={classes.progressContainer}>
                <ProgressBar value={uploadPercentage} style={{ flex: 1 }} />
              </div>
            )}
          </Box>
          <Paper className={classes.responseContainer}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6} >
                <div style={{display:  'flex'}}>
                  <IconButton
                    onClick={() => {
                      handleTrainingPhase();
                    }}
                  >
                    <label style={{ fontSize: '15px' }}>
                      {t('presenter.RowAddTraning')}
                    </label>{' '}
                    <AddCircleOutline />
                  </IconButton>
                  <IconButton 
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}>
                    <HelpOutline />
                  </IconButton>
                </div>
                  {isHovering && <p style={{color: 'grey'}} >{t('presenter.hoverTxt')}</p>}
                {formik.values.training ? formik.values.training.map((v, i) => {
                  return (
                    <ListItem key={i}>
                      <TextField
                        id="training"
                        label={t('presenter.trainingPhrase')}
                        variant="outlined"
                        fullWidth
                        name="training"
                        error={
                          formik.errors.training && formik.touched.training
                            ? true
                            : null
                        }
                        helperText={
                          formik.errors.training && formik.touched.training
                            ? formik.errors.training
                            : ''
                        }
                        onChange={(e) => {
                          onChangeTrainingPhase(i, e);
                        }}
                        value={v}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                onClick={() => onCancelField(i, false)}
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </ListItem>
                  );
                }) : ''}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <IconButton
                  onClick={() => {
                    handleResponse();
                  }}
                >
                  <label style={{ fontSize: '15px' }}>
                    {t('presenter.RowAddResponse')}
                  </label>{' '}
                  <AddCircleOutline />
                </IconButton>
                {formik.values.response &&
                  formik.values.response.map((v, i) => {
                    return (
                      <ListItem key={i}>
                        <TextField
                          id="response"
                          label={t('presenter.response')}
                          variant="outlined"
                          name="response"
                          fullWidth
                          error={
                            formik.errors.response && formik.touched.response
                              ? true
                              : null
                          }
                          helperText={
                            formik.errors.response && formik.touched.response
                              ? formik.errors.response
                              : ''
                          }
                          onChange={(e) => {
                            onChangeResponse(i, e);
                          }}
                          value={v}
                          onBlur={formik.handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() => {
                                    onCancelField(i, true);
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </ListItem>
                    );
                  })}
              </Grid>
            </Grid>
          </Paper>
          <Box
            position="relative"
            width="100%"
            marginTop="20px"
            style={{ height: '20px' }}
          >
            {uploadingStatus && (
              <div className={classes.progressContainer}>
                <ProgressBar value={uploadPercentage} style={{ flex: 1 }} />
              </div>
            )}
          </Box>
          <Paper className={classes.mediaContainer}>
            <Grid
              container
              style={{
                height: '100%',
                justifyContent: 'space-between'
              }}
            >
              <Droppable droppableId="animationList">
                {(provided) => (
                  <Grid
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Typography
                      size="sm"
                      align="left"
                      style={{ padding: '10px 0' }}
                    >
                      {t('storytelling.animations')}
                    </Typography>
                    <div className={classes.mediaWrapper}>
                      {robotAnimationVideos &&
                        robotAnimationVideos.length > 0 &&
                        robotAnimationVideos.map((item, i) => (
                          <Draggable
                            key={`${item.title}-${i}`}
                            draggableId={`${item.title}-${i}`}
                            index={i}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classes.animationItem}
                                onClick={() => setVideoURL(item.url)}
                              >
                                <Typography
                                  align="left"
                                  className={classes.animationTitle}
                                >
                                  {item.title}
                                </Typography>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                    </div>
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
              <Grid item xs={12} sm={7} md={5} className={classes.videoWrapper}>
                <ReactPlayer
                  url={videoURl}
                  playing={true}
                  loop={true}
                  width="auto"
                  height="95%"
                  style={{ position: 'absolute' }}
                />
              </Grid>
              <Droppable droppableId="soundsList">
                {(provided) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Box className={classes.soundsTitleWrapper}>
                      <Typography size="sm" align="left">
                        {t('storytelling.sounds')}
                      </Typography>
                      <IconButton type="button">
                        <label
                          htmlFor="upload-audio-input"
                          className={classes.uploadAudioBtn}
                        >
                          <AddCircleOutline />
                        </label>
                      </IconButton>
                    </Box>
                    <div className={classes.mediaWrapper}>
                      {sounds.map((sound, i) => (
                        <Draggable
                          key={sound.soundId}
                          draggableId={`${sound.soundName}-${i}`}
                          index={i}
                        >
                          {(provided) => (
                            <Box
                              key={sound.soundId}
                              className={classes.soundItem}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Typography
                                size="sm"
                                className={classes.soundName}
                              >
                                {sound.soundName}
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  if (playAudio) {
                                    setPlayAudio(null);
                                    audioPlayerRef.current.pause();
                                  } else {
                                    setPlayAudio(sound.soundUrl);
                                  }
                                }}
                              >
                                {playAudio === sound.soundUrl &&
                                playAudio !== null ? (
                                  <PauseIcon />
                                ) : (
                                  <PlayArrowIcon />
                                )}
                              </IconButton>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      <audio
                        controls
                        src={playAudio}
                        autoPlay
                        ref={audioPlayerRef}
                        onEnded={() => setPlayAudio(null)}
                        style={{ display: 'none' }}
                      ></audio>
                    </div>
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </Paper>
        </DragDropContext>
      </form>
    </>
  );
}

export default withRouter(CreateStortytelling);
