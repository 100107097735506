import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import {
  AddCircleOutline,
  GroupAdd,
  VerifiedUser,
  Delete
} from '@material-ui/icons';
import * as firebase from 'firebase';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import ViewListSharpIcon from '@material-ui/icons/ViewListSharp';
import Checkbox from '@material-ui/core/Checkbox';
import Modal from '../../components/Modal/Modal';
import IconModal from '../../components/Modal/IconModal';
import { signUp } from '../../context/UserContext';
import Table from '../tables/Tables';
import { getApi, putApi, deleteApi } from '../../services/Services';
import { userStyles } from './styles';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import Header from '../../components/Header';

const CryptoJS = require('crypto-js');

export default function Robots() {
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [username, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [pwd, setPassword] = useState('');
  const [role, setRole] = useState(1);
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuListselected, setMenuListselected] = useState([]);
  const [menuListselectedistselected, setMenuListselectedSubmit] = useState([]);
  const modalPermissionRef = useRef();
  const classes = userStyles();

  const resetUser = () => {
    setUserName('');
    setPassword('');
    setRole('');
    setEmail('');
  };

  //Utility Functions
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const handleToggle = (value) => () => {
    const currentIndex = menuListselected.indexOf(
      menuListselected.find((e) => e.id === value.id)
    );
    const newChecked = [...menuListselected];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setMenuListselected(newChecked);
    setMenuListselectedSubmit(newChecked);
  };

  const ModalPermissionUser = (
    <div>
      <h2 id="simple-modal-title">{t('expertView.permision_title')}</h2>
      <List className={classes.listRoot}>
        {menuList.map((option) => {
          const value = option.labelfbId;
          const labelId = `checkbox-list-label-${value}`;
          return (
            <ListItem
              key={value}
              role={undefined}
              dense
              button
              onClick={handleToggle(option)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={
                    menuListselected.map((i) => i.labelfbId).indexOf(value) !==
                    -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={option.title} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const getUserInfo = async (id) => {
    return await getApi(`/user?id=${id}`)
      .then((response) => {
        if (response.statusCode === 200) {
          if (response.data) {
            return response.data;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMenuList = async () => {
    const list = [];
    return await getApi('/menus/list')
      .then((response) => {
        if (response.data.length > 0) {
          const tempdata = response.data.map((each) => {
            return {
              ...each,
              title: t(each.label)
            };
          });
          modalPermissionRef.current.handleLoadingClose();
          return tempdata;
        }
        modalPermissionRef.current.handleLoadingClose();
        return list;
      })
      .catch((error) => {
        console.error(error);
        modalPermissionRef.current.handleLoadingClose();
        modalPermissionRef.current.handleSubmitBtn();
        return list;
      });
  };

  const permissionEvent = useCallback(async (uid) => {
    setUserId(uid);
    setMenuList([]);
    setMenuListselected([]);
    modalPermissionRef.current.handleOpenControl();
    modalPermissionRef.current.handleLoadingOpen();
    const allMenu = await getMenuList();
    setMenuList(allMenu);
    if (allMenu.length > 0) {
      const returnUserdata = await getUserInfo(uid);
      if (returnUserdata) {
        if (
          returnUserdata &&
          returnUserdata.permissions &&
          returnUserdata.permissions.length > 0
        ) {
          const temp = [];
          returnUserdata.permissions.forEach((each) => {
            const index = allMenu
              .map(function (e) {
                return e.labelfbId;
              })
              .indexOf(each);
            if (index !== -1) {
              temp.push({
                ...allMenu[index]
              });
            }
          });
          setMenuListselected(temp);
          setMenuListselectedSubmit(temp);
        }
      }
    }
  }, []);

  const saveDataPermissionRest = useCallback((ids, userId) => {
    utility.loading(true);
    const menus =
      ids.length > 0
        ? ids.map(function (obj) {
            return obj.labelfbId;
          })
        : [];
    console.log(menus, userId);
    putApi('/user', {
      id: userId,
      menus
    })
      .then((response) => {
        utility.loading(true);
        utility.snack(response.message, 'success');
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
        utility.snack(error.response.data.message, 'error');
      })
      .then(() => {
        utility.loading(false);
      });
  }, []);

  function saveDataPermission() {
    saveDataPermissionRest(menuListselectedistselected, userId);
  }

  const columns = [
    {
      label: t('name'),
      name: 'username',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return <span style={{ color: '#49b9e9' }}>{value}</span>;
          }
        }
      }
    },
    {
      label: t('email'),
      name: 'email',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (value) {
            return <span>{t(`${value}`)}</span>;
          }
        }
      }
    },
    {
      label: t('userManagement.role'),
      name: 'role',
      options: {
        customBodyRender: (value) => {
          if (value === 0) return 'Admin';
          else if (value === 1) return 'User';
        }
      }
    },
    {
      label: t('userManagement.emailVerify'),
      name: 'emailVerified',
      options: {
        customBodyRender: (value) => {
          if (value)
            return (
              <Tooltip title="Verified">
                <IconButton aria-label="Verified">
                  <VerifiedUser className={classes.verified} />
                </IconButton>
              </Tooltip>
            );
          else
            return (
              <Tooltip title="Invite">
                <IconButton
                  aria-label="Invite"
                  color="primary"
                  onClick={() => inviteMember()}
                >
                  <GroupAdd fontSize="inherit" />
                </IconButton>
              </Tooltip>
            );
        }
      }
    },
    {
      label: t('userManagement.permission'),
      name: 'uid',
      options: {
        customBodyRender: (value) => (
          <div>
            <IconModal
              ref={modalPermissionRef}
              body={ModalPermissionUser}
              button={
                <Tooltip title="Permission">
                  <IconButton
                    aria-label="Permission"
                    onClick={() => permissionEvent(value)}
                  >
                    <ViewListSharpIcon />
                  </IconButton>
                </Tooltip>
              }
              handle={saveDataPermission}
            />
          </div>
        )
      }
    },
    {
      label: t('actions'),
      name: 'uid',
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => removeRow(value)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const ModalBody = (
    <div>
      <h2 id="simple-modal-title">{t('expertView.addnewuser')}</h2>
      <TextField
        label={t('username')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={username}
        onChange={(e) => setUserName(e.target.value)}
      />
      <TextField
        label={t('emailaddress')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label={t('newpassword')}
        id="title"
        margin="dense"
        type="text"
        fullWidth
        value={pwd}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel id="role-label">{t('role')}</InputLabel>
        <Select
          labelId="role-label"
          id="title"
          margin="dense"
          defaultValue={role}
          fullWidth
          onChange={(e) => setRole(e.target.value)}
        >
          <option value={0}>Admin</option>
          <option value={1}>User</option>
        </Select>
      </FormControl>
    </div>
  );

  async function removeRow(uid) {
    utility.loading(true);
    const res = await deleteApi('/delete-user', { uid });
    utility.loading(false);
    if (res.statusCode === 200) {
      utility.snack('User has been successfully deleted.', 'success');
    }
  }

  function generatePassword() {
    const length = 8,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  async function getUsersList() {
    // old
    utility.loading(true);
    const usersRef = firebase.database().ref('users');
    usersRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const userData1 = JSON.parse(JSON.stringify(userData));
      // let userDataId1 = JSON.parse(JSON.stringify(userDataId));
      for (const x in result) {
        userData1.push(result[x]);
      }
      setUserData(userData1);
      utility.loading(false);
    });
  }

  const sendVerification = async (email) => {
    utility.loading(true);
    const redirecturl = `${process.env.REACT_APP_CLIENT_URL}/#/email-verify`;
    // older api
    Axios.post(`/send-mail?email=${email}&language=${language}`, {
      redirecturl
    })
      .then(() => {
        utility.snack(
          'Verification email has been sent successfully.',
          'success'
        );
      })
      .catch((err) => {
        console.log('err.response', err.response);
        utility.snack(err.response.data.message, 'warning');
      })
      .then(() => {
        utility.loading(false);
        console.log('Verification call ended');
      });
  };

  function saveData() {
    utility.loading(true);
    const userData1 = JSON.parse(JSON.stringify(userData));
    const cryptedpwd = CryptoJS.AES.encrypt(
      pwd,
      'my-secret-key@123'
    ).toString();
    const userTypeArr = {
      username,
      email,
      cryptedpwd,
      role,
      emailVerified: false,
      permissions: null
    };
    // userData1.push([username, email, cryptedpwd, role, false, null]);
    setUserData(userData1);
    //add a user via firebase
    signUp(email, pwd, username, userTypeArr, role)
      .then((res) => {
        console.log('USER CREATION', res);
        resetUser();
        sendVerification(email);
      })
      .catch((err) => {
        utility.snack(err.response.data.message, 'warning');
        utility.loading(false);
      });
  }

  function updateUsersList(userData1) {
    const usersRef = firebase.database().ref('users');
    usersRef.set(userData1);
  }

  function removeAuthenticatedUser(username, email) {
    console.log(`firebase del user: ${username}, ${email}`);
  }

  function inviteMember(data) {
    if (!data.rowData[1]) {
      utility.snack('Invaid email provided.', 'error');
    } else {
      console.log('send');
      sendVerification(data.rowData[1]);
    }
  }

  const options = {
    viewColumns: false,
    onRowsDelete: (rowsDeleted, newData) => {
      updateUsersList(newData);
      for (const x in rowsDeleted.data) {
        const delindex = rowsDeleted.data[x].dataIndex;
        removeAuthenticatedUser(userData[delindex][0], userData[delindex][1]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      // sendVerification("raza.hameed@ropstam.com")
      getUsersList();
      setPassword(generatePassword());
    })();
  }, []);

  const onMultiDelete = async ({ data }) => {
    const showAlert = false;
    if (data && data.length > 0 && userData.length > 0) {
      utility.loading(true);
      // Todo: make api for multi-delete user and use it
      await data.map(async (row) => {
        try {
          await deleteApi('/delete-user', {
            uid: userData[row.dataIndex].uid
          });
        } catch (e) {
          console.log('user delete eror === ', e.response);
        }
      });

      if (showAlert) {
        utility.snack('Users has been successfully deleted.', 'success');
      }
    }
    utility.loading(false);
  };

  const onHeaderRobotSelected = () => {};

  return (
    <>
      <Header
        title={t('robot.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={false}
      />
      <Modal
        body={ModalBody}
        add={t('expertView.addnewuser')}
        handle={saveData}
        btnType="icon"
        icon={<AddCircleOutline />}
      />
      <Table
        title={t('expertView.userManagement')}
        data={userData}
        columns={columns}
        options={options}
        kind="user"
        onMultiDelete={onMultiDelete}
      />
    </>
  );
}
