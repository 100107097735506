import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
    pageTitle: {
        width: '100%',
        fontWeight: '500',
        fontSize: 'calc(28px)',
        textAlign: 'left',
        marginBottom: '20px'
    },
    cardContaner: {
        textAlign: 'center'
    }
}));
