import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { getApi } from '../services/Services';
import Cookies from 'js-cookie';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

// components
import Layout from './Layout';

// pages
import Error from '../pages/error';
import Login from '../pages/login';

// context
import { useUserState } from '../context/UserContext';
import EmailVerify from '../pages/Email-Verify';
import ForgotPassword from '../pages/login/ForgotPassword';

export default function App() {
  const { isAuthenticated } = useUserState();
  const [showError, setShowError] = useState(false);

  const checkTokenSession = async () => {
    const token = Cookies.get('token');
    const uid = Cookies.get('u_id');
    const verifyToken = await getApi(`/verify-token?token=${token}&uid=${uid}`);
    if (
      verifyToken.statusCode === 200 &&
      verifyToken &&
      !verifyToken.isTokenValid
    ) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        window.location.assign(`${window.location.origin}/#/app/logout`);
      }, 1000);
    }
    Cookies.set('isAdmin', verifyToken.isAdmin || false, { expires: 365 });
  };

  useEffect(() => {
    checkTokenSession();
    // refreshLogFiles();
  }, []);

  const renderError = () => {
    const snackbarData = {
      vertical: 'top',
      horizontal: 'right',
      type: 'error',
      msg: 'Session expired.'
    };
    const { vertical, horizontal, msg, type } = snackbarData;

    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={showError}
        key={vertical + horizontal}
      >
        <Alert severity={type}>{msg}</Alert>
      </Snackbar>
    );
  };

  if (window.location.href.includes('email-verify?token=')) {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" render={(props) => {
              const params = new URLSearchParams(props.location.search);
              const reset = params.get('mode') === 'resetPassword';

              return reset ? <Redirect to={`/reset-password${props.location.search}`} /> : <Redirect to="/login" />
            }
          } />
          <Route path="/login" component={Login} />
          <Route path="/email-verify" component={EmailVerify} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    );
  }
  return (
    <>
      {renderError()}
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
                const params = new URLSearchParams(props.location.search);
                const reset = params.get('mode') === 'resetPassword';

                return reset ? <Redirect to={`/reset-password${props.location.search}`} /> : <Redirect to="/app/dashboard" />
              }
            }
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route path="/reset-password" component={ForgotPassword} />
          <PublicRoute path="/email-verify" component={EmailVerify} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    console.log("component >>>> ", component);
    console.log("rest >>>> ", rest);
    console.log("isAuthenticated ----- > ", isAuthenticated);
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/'
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
