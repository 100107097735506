import React, { useState } from 'react';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import useStyles from './styles';
import logo from './logo.png';
import { putApi } from '../../services/Services';

const CryptoJS = require('crypto-js');

function ForgotPassword(props) {
    const { history } = props;
    const classes = useStyles();
    const language = useSelector(state => state.language.value);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState('Something is wrong with your login or password :(');
    const [loginValue, setLoginValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [repeatPasswordValue, setRepeatPasswordValue] = useState('');
    const [loginValidation, setLoginValidation] = useState({
        login: false,
        password: false,
        repeatPassword: false
    });

    const errorTag = error ? (
        <Fade in={error}>
        <Typography color="secondary" className={classes.errorMessage}>
            {errorMessage}
        </Typography>
        </Fade>
    ) : (
        ''
    );

    const params = new URLSearchParams(props.location.search);

    async function updateUserPassword() {
        const body = {
            email: loginValue,
            password: passwordValue,
            repeatPassword: repeatPasswordValue,
            oobCode: params.get('oobCode')
        }
        const res = await putApi('/user-password-reset', body, {
            "ContentType": "application/json"
        });
        if (res.success) {
            history.push('/login');
            console.log('res -> ', res)
        }
    }

    const onOptionValidate = async (e) => {
        if (!e.key || e.key === 'Enter') {
            if (!passwordValue || !repeatPasswordValue || !loginValue) {
                setLoginValidation({repeatPassword: !repeatPasswordValue, password: !passwordValue, login: !loginValue});
                setError(true);
                setErrorMessage('Something is wrong with your login or password :(');
                return false;
            }
            if (passwordValue.length < 6) {
                setLoginValidation({repeatPassword: false, password: true, login: false});
                setError(true);
                setErrorMessage('Password must contain at least 6 symbols.');
                return false;
            }
            if(passwordValue !== repeatPasswordValue) {
                setLoginValidation({reapeatPassword: true, password: true, login: false});
                setError(true);
                setErrorMessage('Password and Repeat Password do not match :(');
                return false;
            }
            setLoginValidation({login: false, password: false, repeatPassword: false,})
            await updateUserPassword();
        }
    }

    return (
        <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
            <img src={logo} alt="logo" className={classes.logotypeImage} />
            <Typography className={classes.logotypeText}>IRIS Robotics</Typography>
        </div>
        <div className={classes.formContainer}>
            <div className={classes.form}>
            <React.Fragment>
                <Typography className={classes.quoteText} variant="h1">
                Change Password
                </Typography>
                {errorTag}
                <TextField
                label="Email"
                id="email"
                className={loginValidation.login ? classes.notEmpty:''}
                InputProps={{
                    classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                    }
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                onKeyDown={onOptionValidate}
                margin="normal"
                type="email"
                fullWidth
                />
                <TextField
                label="Password"
                id="newPassword"
                className={loginValidation.password ? classes.notEmpty:''}
                InputProps={{
                    classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                    }
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyDown={onOptionValidate}
                margin="normal"
                type="password"
                fullWidth
                />
                <TextField
                label="Repeat new password"
                id="repeatPassword"
                className={loginValidation.repeatPassword ? classes.notEmpty:''}
                InputProps={{
                    classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                    }
                }}
                value={repeatPasswordValue}
                onChange={(e) => setRepeatPasswordValue(e.target.value)}
                onKeyDown={onOptionValidate}
                margin="normal"
                type="password"
                fullWidth
                />
                <div className={classes.formButtons}>
                {isLoading ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                    <Button
                    disabled={
                        loginValue.length ===0 || passwordValue.length === 0 || repeatPasswordValue.length === 0
                    }
                    onClick={onOptionValidate}
                    variant="contained"
                    color="primary"
                    size="large"
                    >
                    Save
                    </Button>
                )}
                </div>
            </React.Fragment>
            </div>
        </div>
        </Grid>
    );
}

export default withRouter(ForgotPassword);
