import React from 'react';

function Legend({ color, title, colorStyle, textStyle, containerStyle }) {
  return (
    <span style={{ flexBasis: '50%', paddingRight: 10, ...containerStyle }}>
      <span
        style={{
          padding: '0px 20px',
          marginRight: 10,
          backgroundColor: color,
          ...colorStyle
        }}
      ></span>
      {title ? <span style={{ ...textStyle }}>{title}</span> : null}
    </span>
  );
}

export default Legend;
