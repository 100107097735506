import {
  Avatar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  CardMedia
} from '@material-ui/core';
import { Create, Delete } from '@material-ui/icons';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import EnhancedDataTable, { paginationRowPerArr } from '../../components/EnhancedDataTable';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { getApi, deleteApi } from '../../services/Services';
import { PanelHeader } from '../dialogs/components/actionbar';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';

const ListPresenter = () => {
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [listPresenteres, setListPresenteres] = useState([]);
  const [actualList, setActualPresenteres] = useState([]);
  const [selectedPresenteres, setSelectedPresenteres] = useState([]);
  const [selectedRobot, setSelectedRobot] = useState('');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const editRedirect = (id) => {
    window.location.assign(
      `${window.location.origin}/#/app/presenter/edit/${id}?selectedRobotID=${selectedRobot}`
    );
  };

  const getPresenteresData = useCallback(async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/all-presenters?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
      );
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        setListPresenteres(apiData);
        setActualPresenteres([...apiData]);
      } else {
        setListPresenteres([]);
        setActualPresenteres([]);
      }
    } catch (e) {
      setListPresenteres([]);
      setActualPresenteres([]);
    }
    utility.loading(false);
  }, [selectedRobot, language]);

  useEffect(() => {
    if (selectedRobot) {
      getPresenteresData();
    }
  }, [selectedRobot, language, getPresenteresData]);

  const handleDelete = async (id) => {
    utility.loading(true);
    const seletedItem = listPresenteres.filter((each) => each.id === id);

    if (seletedItem.length > 0) {
      try {
        await deleteApi(`/intents?selectedRobot=${selectedRobot}`, {
          intentids: [seletedItem[0].intentID]
        });
      } catch (e) {
        console.log(e);
      }
    }
    try {
      const presenterResponse = await deleteApi(
        `delete-presenter?selectedRobot=${selectedRobot}`,
        { id }
      );

      utility.loading(false);
      if (presenterResponse) {
        utility.snack('Presenter has been successfully deleted.', 'success');
        getPresenteresData(selectedRobot);
      }
    } catch (e) {
      utility.snack('Something went wrong please try again.', 'error');
    }
    utility.loading(false);
  };

  const contentPlaceHolder = <p>{t('notDataFound')}</p>;

  const columnsCustom_enhanced = [
    {
      name: 'title',
      numeric: false,
      disablePadding: false,
      label: t('presenter.RowTitle'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <Typography
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: '#49b9e9'
            }}
            onClick={() => editRedirect(listPresenteres[row.rowIndex].id)}
          >
            {listPresenteres[row.rowIndex].title}
          </Typography>
          );
        }
      }
    },
    {
      name: 'category_name',
      numeric: false,
      disablePadding: false,
      label: t('presenter.RowCategory'),
      render: (row) => {
        return <Typography>{row.category_name}</Typography>;
      }
    },
    {
      name: 'imageLink',
      numeric: false,
      disablePadding: false,
      label: t('presenter.RowImage'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <Avatar>
              <CardMedia
                component={listPresenteres[row.rowIndex].mediaType === 'video' ? 'video' : 'img'}
                width="100%"
                height="auto"
                image={listPresenteres[row.rowIndex].imageLink}
                title={listPresenteres[row.rowIndex].title}
                style={{ cursor: 'pointer' }}
                // onClick={() => { handleSelect(item.id) }}
              />
            </Avatar>
          )
        }
      }
    },
    {
      name: 'Actions',
      numeric: false,
      disablePadding: false,
      label: t('actions'),
      options: {
        customBodyRender: (_, row) => {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => editRedirect(listPresenteres[row.rowIndex].id)}
                >
                  <Create fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(listPresenteres[row.rowIndex].id);
                  }}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const onSelect = (selected) => {
    setSelectedPresenteres(selected);
  };

  const onCancelSearch = () => {
    setListPresenteres(actualList);
  };

  const onSearch = (e) => {
    const search_text = e.target.value;
    const list = actualList.filter(
      (item) =>
        item.title.toLowerCase().indexOf(search_text.toLowerCase()) >= 0 ||
        item.category_name.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
    );
    if (search_text === '') {
      return onCancelSearch();
    }
    setListPresenteres(list);
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getPresenteresData(robotId);
  };

  const onMultiDelete = async (data) => {
    utility.loading(true);
    if (data.length > 0 && listPresenteres.length > 0) {
      data.forEach((item) => {
        listPresenteres.map(async (pr) => {
          if (pr.id === item) {
            try {
              if (pr.intentID) {
                await deleteApi(`/intents?selectedRobot=${selectedRobot}`, {
                  intentids: [pr.intentID]
                });
              }
            } catch (e) {
              console.log(e);
            }
          }
        });
      });
      try {
        const deleteRes = await deleteApi(
          `/delete-presenters?selectedRobot=${selectedRobot}`,
          { ids: data }
        );
        utility.loading(false);
        if (deleteRes.statusCode === 200) {
          utility.snack('Presenters has been successfully deleted.');
          getPresenteresData(selectedRobot);
        }
      } catch (e) {
        utility.loading(false);
        utility.snack('Something went wrong please try again.', 'error');
      }
    }
  };

  return (
    <>
      <Header
        title={t('presenter.listtitle')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <Grid item lg={12}>
        <PanelHeader
          title={t('presenter.listtitle')}
          onCancelSearch={onCancelSearch}
          onSearch={onSearch}
          bgColor="transparent"
          count={listPresenteres ? listPresenteres.length : 0}
          isSelected={selectedPresenteres.length > 0}
          settings={{
            search: true
          }}
        />

        <EnhancedDataTable
          title={t('presenter.listtitle')}
          onSelect={onSelect}
          contentPlaceHolder={contentPlaceHolder}
          rows={listPresenteres}
          columns={columnsCustom_enhanced}
          rowsPerPageOptions={paginationRowPerArr}
          onMultiDelete={onMultiDelete}
          responsive='vertical'
        />
      </Grid>
    </>
  );
};

export default withRouter(memo(ListPresenter));
