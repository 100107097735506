import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  tablediv: {
    paddingTop: '20px'
  },
  editContainer: {

  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlText: {
    margin: theme.spacing(1),
    minWidth: '80%',
  },
  groups: {
    marginTop: 30,
    border: '1px solid #e8e8e7'
  },
  actionPanel: {
    display: 'flex',
    height: 70,
    padding: '10px',
    backgroundColor: '#fff'
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // padding: 7,
    margin: '0 10px',
    textAlign: 'center',
    width: 60,
    // lineHeight: 0,
    // borderRadius: 50,
    transition: 'background 300ms ease-out',
    '& span': {
      fontSize: 10,
      padding: 7
    },
    '& svg': {
      transition: 'all 300ms ease-out',
      fontSize: '1.3rem'
    },
    '&:hover': {
      backgroundColor: '#d9d9d99c',
      color: '#000'
    },
    '&:hover svg': {
      // fontSize: "1.7rem"
    }
  },
  groupPanel: {
    display: 'flex'
  },
  actionbar: {
    display: 'flex'
  },
  actions: {
    // margin: "20px 0",
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    '& span': {
      display: 'flex'
    }
  }
}));

