import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import {
  Typography,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Box,
  Tooltip,
  Button
} from '@material-ui/core';
import useStyles from './styles';
import MediaLibraryGallery from '../../mediaLibrary/components/MediaLibraryGallery';
import ProgressBar from '../../../components/ProgressBar';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagePicker = ({
  image,
  onChangeHandler,
  imageList,
  handleRemoveImage,
  uploadingStatus,
  uploadPercentage,
  handleChange,
  openImagePicker,
  setOpenImagePicker,
  onClickPickerOpen
}) => {
  const classes = useStyles();
  return (
    <div>
      <input
        accept="audio/*"
        style={{ display: 'none' }}
        id="upload-audio-input"
        multiple
        type="file"
        onChange={(e) => handleChange(e, 'audios')}
      />
      {image === '' ? (
        <IconButton
        className={classes.addImageIcon}
        onClick={() => {
          setOpenImagePicker(!openImagePicker);
          onClickPickerOpen();
        }}
        >
          <img
            src="/images/storytelling/picture-add-4.png"
            alt="Add an image"
          />
        </IconButton>
      ) : (
        <Box className={classes.imageWrapper}>
          <IconButton
            edge="start"
            color="inherit"
            size="small"
            className={classes.removeImgBtn}
            onClick={handleRemoveImage}
          >
            <CloseIcon className={classes.removeImgIcon} />
          </IconButton>
          <img
            src={image}
            alt="Presentation Image"
            style={{ width: '100%', height: 100 }}
          />
        </Box>
      )}
      <Dialog
        fullScreen
        open={openImagePicker}
        onClose={() => setOpenImagePicker(!openImagePicker)}
        TransitionComponent={Transition}
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenImagePicker(!openImagePicker)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Gallery </Typography>
            <Tooltip title="Add images from local">
              <label htmlFor="upload-image-input" style={{ marginLeft: 5 }}>
                <Button variant="text" component="span">
                  <BackupOutlinedIcon style={{ color: '#fff' }} />
                </Button>
              </label>
            </Tooltip>
          </Toolbar>
          {uploadingStatus && (
            <ProgressBar
              value={uploadPercentage}
              style={{ flex: 1 }}
              hidePercentageLabel
            />
          )}
        </AppBar>
        <Box paddingTop="80px">
          {imageList &&
            imageList.length > 0 &&
            imageList.map((each) => {
              return (
                <MediaLibraryGallery
                  item={each}
                  key={each.id.toString()}
                  type={'image'}
                  use={true}
                  handleSelect={(imageItem) => {
                    onChangeHandler(imageItem.downloadlink);
                    setOpenImagePicker(!openImagePicker);
                  }}
                />
              );
            })}
        </Box>
      </Dialog>
    </div>
  );
};

export default ImagePicker;
