import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  }
}));

export const userStyles = makeStyles((theme) => ({
  verified: {
    color: 'green'
  },
  listRoot: {
    width: '100%',
    maxWidth: 360,
    height: 300,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper
  }
}));
