import React, { memo, useEffect, useRef, useState } from 'react';
import CKEditor from 'ckeditor4-react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  FormHelperText
} from '@material-ui/core';
import { Backup, Delete, Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { PanelHeader } from '../dialogs/components/actionbar';
import useStyles from './styles';
import MediaPicker from '../../components/MediaPicker';
import { getCustomID, GetSeletedRobot } from '../../utills';
import Header from '../../components/Header';
import { getApi, postApi } from '../../services/Services';
import { useSelector } from 'react-redux';

CKEditor.editorUrl = process.env.PUBLIC_URL + '/ckeditor/ckeditor.js';

const cardStyle = {
  float: 'left',
  width: '150px',
  height: 'auto',
  margin: '10px'
};

const initialValues = {
  id: '',
  product_name: '',
  product_in_stock: true,
  description: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  isDelete: false,
  image: [],
  productCateList: [],
  product_base_price: 0
  // currency: '$'
};

const validationSchema = Yup.object().shape({
  product_name: Yup.string().label('product_name').required('Required'),
  product_in_stock: Yup.bool()
    .label('product_in_stock')
    .default(true)
    .required('Required'),
  description: Yup.string().label('description').required('Required'),
  image: Yup.array().required('Required'),
  createdAt: Yup.date(),
  updatedAt: Yup.date().min(Yup.ref('createdAt')),
  isDelete: Yup.bool().default(false),
  productCateList: Yup.array(),
  // currency: Yup.string(),
  product_base_price: Yup.number()
    .typeError('Price must be a number')
    .positive('Price must be greater than zero')
    .label('product_base_price')
    .required('Required')
});

const ManageProduct = (props) => {
  const { history, match } = props;
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [categoryList, setCategoryList] = useState([]);
  const [productDeletedList, setProductDeletedList] = useState([]);
  const [categoryListselected, setCategoryListselected] = useState([]);
  const [description, setDescription] = useState(null);
  const [actiontype, setActionType] = useState('add');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const timerRef = useRef(null);
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [formImages, setFormImages] = useState([]);
  const [selectedRobot, setSelectedRobot] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      utility.loading(true);
      const imageLinkForApi = [];
      values.image.forEach((item) => {
        imageLinkForApi.push(item.value || item);
      });
      const data = {
        productId: values.id,
        imageLink: imageLinkForApi,
        productTitle: values.product_name,
        product_base_price: values.product_base_price,
        product_in_stock: values.product_in_stock,
        productDescription: values.description,
        productCateList: values.productCateList,
        // currency: values.currency,
        imagesCount: Object.values(values.image).length
          ? Object.values(values.image).length
          : 1,
        categoriesCount:
          values.productCateList && values.productCateList.length
            ? values.productCateList.length
            : 0,
        createdAt: values.createdAt,
        updatedAt: values.updatedAt,
        isDelete: values.isDelete
      };

      if (actiontype === 'add') {
        try {
          const response = await postApi(
            `/shopping-product?selectedRobot=${selectedRobot}&id=${data.productId}&language=${language}`,
            data
          );
          if (response.statusCode === 200) {
            utility.snack('Product has been successfully created.', 'success');
            timerRef.current = setTimeout(() => {
              history.push('/app/shopping/products/');
            }, 1000);
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
          utility.loading(false);
          formik.resetForm();
        } catch (e) {
          console.log(e);
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      } else {
        try {
          const response = await postApi(
            `/shopping-product?selectedRobot=${selectedRobot}&id=${data.productId}&language=${language}`,
            data
          );

          if (response.statusCode === 200) {
            utility.snack('Product has been successfully updated.', 'success');
            timerRef.current = setTimeout(() => {
              history.push('/app/shopping/products/');
            }, 1000);
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
          utility.loading(false);
          formik.resetForm();
        } catch (e) {
          console.log(e);
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      }
    }
  });

  const handleDeleteImage = (id) => {
    const images = formImages;
    const filterimage = images.filter((e) => e.id !== id);
    formik.setFieldValue('image', filterimage);
    setFormImages(filterimage);
    const deleteProduct = images.find((e) => e.id === id);
    const isStored = deleteProduct.value.includes('firebasestorage');
    if (isStored) {
      productDeletedList.push(deleteProduct);
      setProductDeletedList(productDeletedList);
    }
  };

  const handleToggle = (value) => () => {
    const categoryID = value.categoryId;
    let newCategoryListselected = [];

    if (!categoryListselected) return false;

    let isExist = false;
    if (categoryListselected.length && categoryListselected.length !== 0) {
      newCategoryListselected = [...categoryListselected];
      isExist = categoryListselected.includes(categoryID);
    } else {
      //for older records
      Object.values(categoryListselected).forEach((item) => {
        newCategoryListselected.push(item.categoryId);
      });
    }
    // let isExist = categoryListselected.length ? categoryListselected.includes(categoryID) : false;
    if (!isExist) {
      newCategoryListselected.push(categoryID);
    } else {
      newCategoryListselected = newCategoryListselected.filter(
        (item) => item !== categoryID
      );
    }
    console.log('newCategoryListselected', newCategoryListselected);
    setCategoryListselected(
      newCategoryListselected ? newCategoryListselected : []
    );
    formik.setFieldValue('productCateList', newCategoryListselected);
  };

  const getProductData = async (robotId) => {
    utility.loading(true);
    const { params } = match;
    const { id } = params;

    try {
      const response = await getApi(
        `/shopping-product?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`,
        {
          id
        }
      );
      const result = response.data;
      if (result && result.productId) {
        const formatdata = result.productCateList || [];

        formik.setFieldValue('id', id);
        formik.setFieldValue('image', result.imageLink);
        // Fix for older records
        let getImagesArray = [];
        if (typeof result?.imageLink?.[0] === 'string') {
          result.imageLink.forEach((value, i) => {
            getImagesArray.push({
              id: i,
              value
            });
          });
        } else {
          getImagesArray = result.imageLink;
        }
        setFormImages(getImagesArray);
        formik.setFieldValue('product_name', result.productTitle);
        formik.setFieldValue('product_base_price', result.product_base_price);
        formik.setFieldValue('product_in_stock', result.product_in_stock);
        formik.setFieldValue('description', result.productDescription);
        setDescription(result.productDescription);
        formik.setFieldValue('productCateList', formatdata);
        setCategoryListselected(formatdata);
        formik.setFieldValue('createdAt', new Date().toISOString());
        formik.setFieldValue('updatedAt', new Date().toISOString());
        formik.setFieldValue('isDelete', result.isDelete);
        // formik.setFieldValue('currency', result.currency ? result.currency : '$');
        setActionType('edit');
      } else {
        history.push('/app/shopping/products/');
        utility.snack('Product not found.-', 'error');
      }
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e);
      history.push('/app/shopping/products/');
      utility.snack('Product not found.+', 'error');
      utility.loading(false);
    }
  };

  const getCategoriesData = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/shopping-categories?selectedRobot=${
          robotId ? robotId : selectedRobot
        }&language=${language}`
      );
      let responseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        responseData = apiData;
      } else {
        history.push('/app/shopping/products/');
        utility.snack('Product not found.', 'error');
        utility.loading(false);
      }
      setCategoryList(responseData);
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e);
      setCategoryList([]);
      utility.loading(false);
    }
  };

  useEffect(() => {
    const { params } = match;
    const { id } = params;
    const getSeletedRobot = GetSeletedRobot();
    setSelectedRobot(getSeletedRobot);
    getCategoriesData(getSeletedRobot);
    if (id) {
      getProductData(getSeletedRobot);
    } else {
      const id = getCustomID();
      formik.setFieldValue('id', id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleClose = () => {
    setOpenImagePicker(false);
  };

  const handleSelect = (item, type) => {
    if (item && type && type === 'image') {
      const images = formImages || [];
      const genarateImage = {
        id: getCustomID(),
        value: item.downloadlink,
        type: item.downloadlink.split('.').pop().split('?')[0]
      };

      images.push(genarateImage);
      formik.setFieldValue('image', images);
      setFormImages(images);
      handleClose();
    }
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getCategoriesData(robotId);
  };

  return (
    <>
      <MediaPicker
        handleClose={handleClose}
        handleSelect={handleSelect}
        handleDelete={() => {}}
        open={openImagePicker}
        type="image"
        selectedRobotID={selectedRobot}
      />
      <PanelHeader
        title={
          actiontype === 'add'
            ? t('shopping.product.createproduct')
            : t('shopping.product.editProduct')
        }
      />
      <Header
        title={
          actiontype === 'add'
            ? t('shopping.product.createproduct')
            : t('shopping.product.editProduct')
        }
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={actiontype === 'add' ? true : false}
      />
      <div>
        <form className="presenter-form" onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={12} lg={8}>
              <Card>
                <CardContent>
                  <List>
                    <ListItem
                      className={clsx([
                        classes.concenterAlign,
                        classes.noHorizontalPadding
                      ])}
                    >
                      <>
                        <label htmlFor="contained-button-file">
                          <Avatar onClick={() => setOpenImagePicker(true)}>
                            <Backup />
                          </Avatar>
                        </label>
                      </>
                    </ListItem>

                    <ListItem>
                      {formImages &&
                        formImages.length > 0 &&
                        formImages.map((item, i) => {
                          return (
                            <Card style={cardStyle} key={i}>
                              <CardHeader
                                title="card title"
                                subtitle="card title"
                              />
                              <CardMedia
                                component="img"
                                width="100%"
                                height="100px"
                                image={item.value}
                                title="Contemplative Reptile"
                              />
                              <CardActions>
                                <Tooltip title="delete">
                                  <IconButton
                                    edge="end"
                                    aria-label="Open"
                                    onClick={() => handleDeleteImage(item.id)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </CardActions>
                            </Card>
                          );
                        })}
                    </ListItem>

                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.product.productname')}
                        variant="outlined"
                        id="product_name"
                        margin="normal"
                        type="text"
                        fullWidth
                        name="product_name"
                        error={
                          formik.errors.product_name &&
                          formik.touched.product_name
                            ? true
                            : null
                        }
                        helperText={
                          formik.errors.product_name &&
                          formik.touched.product_name
                            ? formik.errors.product_name
                            : ''
                        }
                        onChange={formik.handleChange}
                        value={formik.values.product_name}
                        onBlur={formik.handleBlur}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('price')}
                        variant="outlined"
                        id="product_base_price"
                        margin="normal"
                        type="number"
                        fullWidth
                        name="product_base_price"
                        error={
                          formik.errors.product_base_price &&
                          formik.touched.product_base_price
                            ? true
                            : null
                        }
                        helperText={
                          formik.errors.product_base_price &&
                          formik.touched.product_base_price
                            ? formik.errors.product_base_price
                            : ''
                        }
                        onChange={formik.handleChange}
                        value={formik.values.product_base_price}
                        onBlur={formik.handleBlur}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <Typography>{t('shopping.product.instock')}</Typography>
                      <CustomSwitch
                        checked={formik.values.product_in_stock}
                        name="product_in_stock"
                        id="product_in_stock"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </ListItem>

                    <div>
                      <h2
                        id="simple-modal-title"
                        className={classes.listRootHeading}
                      >
                        {t('shopping.category.Categoryname')}
                        {/* Add New <Add /> */}
                        <Link
                          to={{
                            pathname: 'categories'
                          }}
                        >
                          <IconButton style={{ float: 'right' }}>
                            <label style={{ fontSize: '14px' }}>
                              {' '}
                              {t('Add New Category')}{' '}
                            </label>

                            <Add style={{ fontSize: '16px' }} />
                          </IconButton>
                        </Link>
                      </h2>
                      {formik.errors.productCateList &&
                        formik.touched.productCateList && (
                          <Typography className={classes.errorMessage}>
                            Required
                          </Typography>
                        )}
                      <List className={classes.listRoot}>
                        {categoryList.map((option) => {
                          const value = option.categoryId;
                          const labelId = `checkbox-list-label-${value}`;
                          return (
                            <ListItem
                              key={value}
                              role={undefined}
                              dense
                              button
                              onClick={handleToggle(option)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={
                                    categoryListselected &&
                                    categoryListselected.length
                                      ? categoryListselected.includes(value)
                                      : false
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={option.categoryTitle}
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </div>

                    <Typography style={{ color: '#6E6E6E' }}>
                      Description
                    </Typography>
                    {formik.errors.description && (
                      <FormHelperText error className={classes.errorContainer}>
                        {formik.errors.description}
                      </FormHelperText>
                    )}
                    <ListItem>
                      <CKEditor
                        type="classic"
                        placeholder="description"
                        name="description"
                        data={description || ''}
                        onChange={(e) => {
                          const data = e.editor.getData();
                          setDescription(data);
                          formik.setFieldValue('description', data);
                        }}
                      />
                    </ListItem>
                    <ListItem>
                      <Button type="submit" variant="contained" color="primary">
                        {t('save')}
                      </Button>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default withRouter(memo(ManageProduct));
