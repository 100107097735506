import React from 'react';
import * as firebase from 'firebase';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { getApi } from '../services/Services';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    case 'CHANGE_LANGUAGE':
      return { ...state, ...action.data };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!Cookies.get('token'),
    language: 'en'
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function changeLanguage(dispatch, data) {
  dispatch({ type: 'CHANGE_LANGUAGE', data });
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  signUp,
  changeLanguage
};

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError
) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    await firebase
      .auth()
      .signInWithEmailAndPassword(login, password)
      .then(async function (result) {
        if (result.user.uid) {
          const userResponse = await getApi(
            `/generate-token?id=${result.user.uid}`
          );
          Cookies.set('u_id', JSON.stringify(result.user.uid), {
            expires: 365
          });
          if (userResponse && userResponse.data && userResponse.data.user) {
            Cookies.set('token', userResponse.data.token, { expires: 365 });
            localStorage.setItem(
              'user',
              JSON.stringify(userResponse.data.user)
            );
            Cookies.set(
              'isAdmin',
              userResponse.data.user.role === 0 ? true : false
            ); // admin role is 0
          }
          setError(null);
          dispatch({ type: 'LOGIN_SUCCESS' });

          setTimeout(async () => {
            history.push('/app/dashboard');
          }, 2000);
        }
      })
      .catch(function (error) {
        setError(error);
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  firebase.auth().signOut();
  Cookies.remove('u_id');
  Cookies.remove('token');
  Cookies.remove('selectedRobot');
  localStorage.removeItem('user');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}

function signUp(email, password, username, userTypeArr, role) {
  return Axios.post('/user', {
    email,
    emailVerified: false,
    password,
    displayName: username,
    photoURL: 'http://www.example.com/12345678/photo.png',
    disabled: false,
    role,
    userTypeArr
  });
}
