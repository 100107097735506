import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const Loader = (props) => {
  const { show } = props;
  const classes = useStyles();
  return (
    show && (
      <div className={classes.root}>
        <CircularProgress color="primary" />
      </div>
    )
  );
};

Loader.propTypes = {
  show: PropTypes.bool
};

Loader.defaultProps = {
  show: false
};

export default Loader;
