import React, { createRef, useEffect, useState } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';

export const TooltipIconButton = ({
  onClick,
  icon,
  title,
  disabled,
  active,
  ...rest
}) => (
  <Tooltip title={title}>
    <span style={disabled ? { cursor: 'not-allowed' } : {}}>
      <IconButton
        className={active ? 'active' : ''}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {icon}
      </IconButton>
    </span>
  </Tooltip>
);

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: '500',
    fontSize: 'calc(22px)',
    textAlign: 'left'
  },
  actionPanel: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: 50,
    padding: '15px 0',
    marginBottom: 10
  },
  actionItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 10px',
    textAlign: 'center',
    width: 60,
    transition: 'background 300ms ease-out',
    '& span': {
      fontSize: 10,
      padding: 7
    },
    '& svg': {
      transition: 'all 300ms ease-out',
      fontSize: '1.3rem'
    },
    '&:hover': {
      backgroundColor: '#d9d9d99c',
      color: '#000'
    },
    '&:hover svg': {}
  },
  groupPanel: {
    display: 'flex'
  },
  actionbar: {
    display: 'flex'
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    '& span': {
      display: 'flex'
    }
  }
});

export const SearchBar = (props) => {
  const inputRef = createRef();
  const search = props.search;

  const [searchText, onChangeSearchText] = useState('');
  useEffect(() => {
    props.search ? inputRef.current.focus() : inputRef.current.blur();
  }, [props.search]);

  const onClear = () => {
    if (props.onCancel) props.onCancel();
    onChangeSearchText('');
  };

  return (
    <div className={['search-bar', search ? 'active' : ''].join(' ')}>
      <input
        ref={inputRef}
        type="text"
        onChange={(e) => {
          if (e.target && props.onSearch) {
            props.onSearch(e);
            onChangeSearchText(e.target.value);
          }
        }}
        value={searchText}
        className="search-text"
      />
      <Tooltip title="Cancel">
        <IconButton className="clear-ic" onClick={onClear}>
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export const PanelHeader = (props) => {
  const classes = useStyles();
  let style = {};

  if (props.allSelected) style.width = '70px';
  const [search, setSearch] = useState(false);

  const onCancel = () => {
    if (props.onCancelSearch) props.onCancelSearch();
    setSearch(false);
  };

  const [actionSettings, setSettings] = useState({
    addNew: false,
    search: false,
    active: false,
    duplicate: false,
    delete: false,
    all: false
  });

  const { ...rest } = props;

  useEffect(() => {
    if (props.settings) {
      setSettings({
        ...actionSettings,
        ...props.settings
      });
    }
  }, [props.settings]);
  style = { backgroundColor: props.bgColor };
  if (props.padding) {
    style.padding = props.padding + 'px';
  }

  let title = null;

  if (props.title) {
    if (typeof props.title === 'function') {
      title = props.title();
    } else {
      title = (
        <Typography
          size="md"
          align="left"
          weight="medium"
          className={classes.pageTitle}
        >
          {props.title}
        </Typography>
      );
    }
  }

  return (
    <div className={classes.actionPanel} style={style}>
      {title}

      <div className="action-bar">
        <SearchBar
          search={search}
          onSearch={props.onSearch}
          onCancel={onCancel}
        />
        <div
          style={{
            // flex: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <Actionbar
            settings={actionSettings}
            onsearch={setSearch}
            search={search}
            {...rest}
          />
        </div>
      </div>
    </div>
  );
};

export const Actionbar = (props) => {
  const { settings, disableDelete } = props;

  const checkAllText = (props.isAllSelected ? 'Uncheck' : 'Check') + ' All';
  return (
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {!props.search && settings.search ? (
        <TooltipIconButton
          onClick={props.onsearch}
          icon={<SearchIcon />}
          title="Search"
        />
      ) : null}
      {settings.active ? (
        <TooltipIconButton
          onClick={props.onActive}
          icon={<VisibilityIcon />}
          title="Active"
          disabled={!props.isSelected}
        />
      ) : null}
      {settings.duplicate ? (
        <TooltipIconButton
          onClick={props.onDuplicate}
          icon={<FileCopyOutlinedIcon />}
          title="Duplicate"
          disabled={!props.isSelected}
        />
      ) : null}
      {settings.delete ? (
        <TooltipIconButton
          onClick={props.onDelete}
          icon={<DeleteIcon />}
          title="Delete"
          disabled={disableDelete}
        />
      ) : null}
      {settings.all ? (
        <TooltipIconButton
          onClick={props.onSelectAll}
          icon={<DoneAllOutlinedIcon />}
          title={checkAllText}
          disabled={props.count === 0}
        />
      ) : null}
      {props.custom}
      {settings.addNew ? (
        <TooltipIconButton
          onClick={props.onAdd}
          icon={<AddCircleOutline />}
          title={props?.btnTitle?.add}
        />
      ) : null}
    </span>
  );
};
