import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  formControlModal: {
    flex: 1
  },
  formControlModalFullWidth: {
    margin: '0px 20px 20px 20px',
    width: '100% !important'
  },
  addUserBtn: {
    paddingTop: '5px'
  }
}));
