import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

// context
import { useUserDispatch, signOut } from '../../context/UserContext';

function Logout(props) {
  const userDispatch = useUserDispatch();
  signOut(userDispatch, props.history);

  return <Redirect to="/login" />;
}

export default withRouter(Logout);
