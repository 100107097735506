import React, { memo, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as firebase from 'firebase';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Header from '../../components/Header';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import useStyles from './styles';
const CryptoJS = require('crypto-js');

const initialValuesProfile = {
  username: '',
  email: '',
  firstname: '',
  lastname: '',
  uid: ''
};

const initialValuesSettings = {
  old_password: '',
  new_password: '',
  conform_password: '',
  uid: ''
};

const validationSchemaProfile = Yup.object().shape({
  username: Yup.string().label('username').required('Required'),
  email: Yup.string().label('email').required('Required'),
  firstname: Yup.string().label('firstname').required('Required'),
  lastname: Yup.string().label('lastname').required('Required'),
  uid: Yup.string()
});

const validationSchemaSettings = Yup.object().shape({
  old_password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .label('old_password')
    .required('Required'),
  new_password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .label('new_password')
    .required('Required'),
  conform_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .label('conform_password')
    .required('Required'),
  uid: Yup.string()
});

const UserProfile = () => {
  const { t } = useTranslation('common');
  const userRef = firebase.database().ref('users');
  const classes = useStyles();
  const [memberData, setMemberData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConformPassword, setShowConformPassword] = useState(false);
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const user = firebase.auth().currentUser;
  const formatedUser = async (data) => {
    const updates = {};
    data.forEach((each) => {
      if (each) {
        const uid = each.new_userid;
        delete each.new_userid;
        updates[uid] = each;
      }
    });
    return updates;
  };

  const updateUsersList = async (userData) => {
    return firebase.database().ref('users').set(userData);
  };

  const formikProfile = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesProfile,
    onSubmit: async (values) => {
      const { username, email } = values;
      utility.loading(true);
      user
        .updateProfile({
          displayName: values.firstname + ' ' + values.lastname
        })
        .then(async () => {
          if (userInfo.email !== values.email) {
            await firebase.auth().currentUser.updateEmail(email);
          }
          setUserInfo((preState) => {
            const clone = preState || {};
            clone.username = username;
            return {
              ...clone
            };
          });

          memberData.forEach((each, index) => {
            if (userInfo.id === each.id) {
              memberData[index].email = email;
            }
          });
          const newUserData = await formatedUser(memberData);
          return await updateUsersList(newUserData);
        })
        .then(() => {
          utility.loading(false);
          utility.snack(
            'User profile has been successfully updated.',
            'success'
          );
        })
        .catch((error) => {
          console.log(error);
          utility.loading(false);
          if (error.code === 'auth/requires-recent-login') {
            utility.snack(error.message, 'error', 5000);
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
        });
    },
    validationSchema: validationSchemaProfile
  });

  const formikSettings = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesSettings,
    onSubmit: async (values) => {
      utility.loading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(
          formikProfile.values.email,
          values.old_password
        )
        .then(() => {
          return user.updatePassword(values.new_password);
        })
        .catch((error) => {
          console.log(error);
          utility.loading(false);
          utility.snack('Old password incorrect.', 'error');
        })
        .then(async () => {
          const cryptedpwd = CryptoJS.AES.encrypt(
            values.new_password,
            'my-secret-key@123'
          ).toString();
          setUserInfo((preState) => {
            const clone = preState || {};
            clone.cryptedpwd = cryptedpwd;
            return {
              ...clone
            };
          });
          memberData.forEach((each, index) => {
            if (userInfo.email === each.email) {
              memberData[index] = userInfo;
            }
          });
          const newUserData = await formatedUser(memberData);

          return await updateUsersList(newUserData);
        })
        .then(() => {
          utility.loading(false);
          utility.snack(
            'User setting has been successfully updated.',
            'success'
          );
        })
        .catch((error) => {
          console.log(error);
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        });
    },
    validationSchema: validationSchemaSettings
  });

  const handleClickOldShowPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickNewShowPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickConformShowPassword = () => {
    setShowConformPassword(!showConformPassword);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((res) => {
      if (res) {
        res.providerData.forEach((profile) => {
          formikProfile.setFieldValue(
            'email',
            profile.email ? profile.email : ''
          );
          if (profile.displayName) {
            let fname = '';
            let lname = '';
            const nameData = profile.displayName.split(' ');
            fname = nameData.length > 0 ? nameData[0] : '';
            if (nameData.length > 1) {
              delete nameData[0];
              lname = nameData.length > 1 ? nameData.join(' ').trim() : '';
            }
            formikProfile.setFieldValue('firstname', fname);
            formikProfile.setFieldValue('lastname', lname);
            formikProfile.setFieldValue('id', profile.id);
          }
          if (profile.email) {
            userRef.on('value', (dataSnapshot) => {
              const result = dataSnapshot.val();
              const memberData1 = [];
              for (const x in result) {
                memberData1.push({ new_userid: x, ...result[x] });
                formikProfile.setFieldValue('uid', result[x].uid);
              }
              setMemberData(memberData1);
            });
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (memberData) {
      const user = memberData.find(
        (e) => e.email === formikProfile.values.email
      );
      setUserInfo(user);
    }
  }, [memberData]);

  useEffect(() => {
    if (userInfo) {
      formikProfile.setFieldValue('username', userInfo.username);
    }
  }, [userInfo]);
  const onHeaderRobotSelected = () => {};

  return (
    <>
      <Header
        title={t('expertView.myUserProfile')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={false}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <form>
            <Card className={classes.rootCard} variant="outlined">
              <CardContent className={classes.form}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem>
                    <Typography>{t('profile')}</Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="username"
                      label={t('username')}
                      variant="outlined"
                      name="username"
                      fullWidth
                      type="text"
                      error={
                        formikProfile.errors.username &&
                        formikProfile.touched.username
                          ? true
                          : null
                      }
                      helperText={
                        formikProfile.errors.username &&
                        formikProfile.touched.username
                          ? formikProfile.errors.username
                          : ''
                      }
                      onChange={formikProfile.handleChange}
                      value={formikProfile.values.username}
                      onBlur={formikProfile.handleBlur}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="email"
                      label={t('email')}
                      variant="outlined"
                      name="email"
                      fullWidth
                      type="text"
                      error={
                        formikProfile.errors.email &&
                        formikProfile.touched.email
                          ? true
                          : null
                      }
                      helperText={
                        formikProfile.errors.email &&
                        formikProfile.touched.email
                          ? formikProfile.errors.email
                          : ''
                      }
                      onChange={formikProfile.handleChange}
                      value={formikProfile.values.email}
                      onBlur={formikProfile.handleBlur}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="firstname"
                      label={t('firstname')}
                      variant="outlined"
                      name="firstname"
                      fullWidth
                      type="text"
                      error={
                        formikProfile.errors.firstname &&
                        formikProfile.touched.firstname
                          ? true
                          : null
                      }
                      helperText={
                        formikProfile.errors.firstname &&
                        formikProfile.touched.firstname
                          ? formikProfile.errors.firstname
                          : ''
                      }
                      onChange={formikProfile.handleChange}
                      value={formikProfile.values.firstname}
                      onBlur={formikProfile.handleBlur}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="lastname"
                      label={t('lastname')}
                      variant="outlined"
                      name="lastname"
                      fullWidth
                      type="text"
                      error={
                        formikProfile.errors.lastname &&
                        formikProfile.touched.lastname
                          ? true
                          : null
                      }
                      helperText={
                        formikProfile.errors.lastname &&
                        formikProfile.touched.lastname
                          ? formikProfile.errors.lastname
                          : ''
                      }
                      onChange={formikProfile.handleChange}
                      value={formikProfile.values.lastname}
                      onBlur={formikProfile.handleBlur}
                    />
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={formikProfile.handleSubmit}
                    >
                      {t('updateprofile')}
                    </Button>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </form>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <form>
            <Card className={classes.rootCard} variant="outlined">
              <CardContent className={classes.form}>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem>
                    <Typography>{t('settings')}</Typography>
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="old_password"
                      label={t('oldpassword')}
                      variant="outlined"
                      name="old_password"
                      fullWidth
                      type={showOldPassword ? 'text' : 'password'}
                      error={
                        formikSettings.errors.old_password &&
                        formikSettings.touched.old_password
                          ? true
                          : null
                      }
                      helperText={
                        formikSettings.errors.old_password &&
                        formikSettings.touched.old_password
                          ? formikSettings.errors.old_password
                          : ''
                      }
                      onChange={formikSettings.handleChange}
                      value={formikSettings.values.old_password}
                      onBlur={formikSettings.handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickOldShowPassword}
                            >
                              {showOldPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="new_password"
                      label={t('newpassword')}
                      variant="outlined"
                      name="new_password"
                      fullWidth
                      type={showNewPassword ? 'text' : 'password'}
                      error={
                        formikSettings.errors.new_password &&
                        formikSettings.touched.new_password
                          ? true
                          : null
                      }
                      helperText={
                        formikSettings.errors.new_password &&
                        formikSettings.touched.new_password
                          ? formikSettings.errors.new_password
                          : ''
                      }
                      onChange={formikSettings.handleChange}
                      value={formikSettings.values.new_password}
                      onBlur={formikSettings.handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickNewShowPassword}
                            >
                              {showNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="conform_password"
                      label={t('repeatnewpassword')}
                      variant="outlined"
                      name="conform_password"
                      fullWidth
                      type={showConformPassword ? 'text' : 'password'}
                      error={
                        formikSettings.errors.conform_password &&
                        formikSettings.touched.conform_password
                          ? true
                          : null
                      }
                      helperText={
                        formikSettings.errors.conform_password &&
                        formikSettings.touched.conform_password
                          ? formikSettings.errors.conform_password
                          : ''
                      }
                      onChange={formikSettings.handleChange}
                      value={formikSettings.values.conform_password}
                      onBlur={formikSettings.handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConformShowPassword}
                            >
                              {showConformPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={formikSettings.handleSubmit}
                    >
                      {t('updatepassword')}
                    </Button>
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(UserProfile);
