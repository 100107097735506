import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import useStyles from './styles';

export default function CBar(props) {
  const classes = useStyles();

  return (
    <BarChart
      width={350}
      height={500}
      data={props.data}
      className={classes.barChart}
    >
      <Bar dataKey="answer" fill="#2f3640" />
      <XAxis dataKey="question" />
      <YAxis type="number" domain={[0, 5]} />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
    </BarChart>
  );
}
