import React, { memo, useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

import DateRangeSection from '../../components/DateRangeSection/DateRangeSection';
import OrderLineChart from '../../components/orderChart/OrderLineChart';
import { PanelHeader } from '../dialogs/components/actionbar';
import Header from '../../components/Header';

import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';

import useStyles from './styles';
import 'firebase/storage';

const AnalyticsOrders = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const [chartData, setChartData] = useState([]);
  const [chartAmountData, setChartAmountData] = useState([]);
  const [originalOrderList, setOriginalOrderList] = useState([]);
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalProductSold, setTotalProductSold] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState('');

  const actionDateChange = (date) => {
    setSelectedDate(date);
  };

  const getOrdersList = async (robootID, robootDetail) => {
    try {
      firebase.initializeApp(
        {
          projectId: robootDetail.project_id,
          databaseURL: robootDetail.firebaseDatabaseUrl || '',
          storageBucket: `${robootDetail.project_id}.appspot.com`
        },
        robootDetail.id
      );
      firebase.app(robootDetail.id);
    } catch (e) {
      /* empty */
    }
    const orderRef = firebase.app(robootID).database().ref('Shopping/Orders');
    orderRef.on('value', function (dataSnapshot) {
      const result = dataSnapshot.val();
      const list = [];
      for (const x in result) {
        const newObj = {};

        newObj['orderDate'] = result[x]['date'];
        newObj['orderNumber'] = result[x]['orderNumber'];
        newObj['id'] = result[x]['userName'] + '+' + result[x]['orderNumber'];
        newObj['orderStatus'] =
          result[x] && result[x]['orderStatus']
            ? result[x]['orderStatus']
            : 'initial';
        newObj['clientName'] = result[x]['userName'];
        newObj['totalPrice'] = parseInt(result[x]['totalPrice'] || 0);
        newObj['discountCoupon'] =
          result[x] && result[x]['discountCoupon']
            ? result[x]['discountCoupon']
            : '-';
        const products = result[x]['products'] || [];
        newObj['noOfProducts'] = products.length;
        newObj['products'] = products;

        list.push(newObj);
      }
      setOriginalOrderList(list);
    });
  };

  const getOrderCount = (data, Ymd) => {
    return data.reduce((total, cVal) => {
      if (moment(cVal.orderDate, 'DD-MM-YYYY').format('YYYY-MM-DD') === Ymd) {
        return ++total;
      }
      return total;
    }, 0);
  };

  const getOrderSumAmout = (data, Ymd) => {
    return data.reduce((total, cVal) => {
      if (moment(cVal.orderDate, 'DD-MM-YYYY').format('YYYY-MM-DD') === Ymd) {
        return total + cVal.totalPrice;
      }
      return total;
    }, 0);
  };

  const formatTableData = (data, dateRange) => {
    const { startYearMonthDay, endYearMonthDay } = dateRange;
    const chartlist = [];
    const chartlistAmout = [];
    for (
      let m = moment(startYearMonthDay);
      m.diff(endYearMonthDay, 'days') <= 0;
      m.add(1, 'days')
    ) {
      chartlist.push({
        orderDate: m.format('DD MMM YYYY'),
        Order: getOrderCount(data, m.format('YYYY-MM-DD'))
      });
      chartlistAmout.push({
        orderDate: m.format('DD MMM YYYY'),
        Amount: getOrderSumAmout(data, m.format('YYYY-MM-DD'))
      });
    }
    setTotalOrder(data.length);

    const tAmount = data.reduce((total, item) => total + item.totalPrice, 0);
    setTotalPrice(tAmount);

    const productsSold = data.reduce(
      (itemTotal, each) => itemTotal + each.noOfProducts,
      0
    );
    setTotalProductSold(productsSold || 0);
    setChartData(chartlist);
    setChartAmountData(chartlistAmout);
    utility.loading(false);
  };

  useEffect(() => {
    let list = [];
    const startDay = moment(selectedDate[0]).format('DD');
    const endDay = moment(selectedDate[1]).format('DD');
    const startMonth = moment(selectedDate[0]).format('MM');
    const endMonth = moment(selectedDate[1]).format('MM');
    const startYear = moment(selectedDate[0]).format('YYYY');
    const endYear = moment(selectedDate[1]).format('YYYY');
    const startYearMonthDay =
      startYear +
      '-' +
      startMonth.toString().padStart(2, '0') +
      '-' +
      startDay.toString().padStart(2, '0');
    const endYearMonthDay =
      endYear +
      '-' +
      endMonth.toString().padStart(2, '0') +
      '-' +
      endDay.toString().padStart(2, '0');
    if (selectedDate[0] === null && selectedDate[1] === null) {
      formatTableData(originalOrderList, {
        startYearMonthDay,
        endYearMonthDay
      });
    } else {
      list = originalOrderList.filter((each) => {
        const orderStartDay = moment(each.orderDate, 'DD-MM-YYYY').format('DD');
        const orderStartMonth = moment(each.orderDate, 'DD-MM-YYYY').format(
          'MM'
        );
        const orderEndYear = moment(each.orderDate, 'DD-MM-YYYY').format(
          'YYYY'
        );
        const orderYearMonthDay =
          orderEndYear +
          '-' +
          orderStartMonth.toString().padStart(2, '0') +
          '-' +
          orderStartDay.toString().padStart(2, '0');
        return (
          startYearMonthDay <= orderYearMonthDay &&
          endYearMonthDay >= orderYearMonthDay
        );
      });
      formatTableData(list, {
        startYearMonthDay,
        endYearMonthDay
      });
    }
  }, [selectedDate, originalOrderList]);

  const onHeaderRobotSelected = (robotId, robot) => {
    getOrdersList(robotId, robot);
    setCurrency(robot.currency || '');
  };

  return (
    <>
      <Header
        title={t('shopping.analytics.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={true}
      />
      <PanelHeader title={t('shopping.analytics.title')} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DateRangeSection
                    startText="Start"
                    endText="End"
                    actionDateChange={actionDateChange}
                    views={['year', 'month', 'day']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={4}>
                      <Card className={classes.cardMainContaner1}>
                        <CardContent className={classes.cardContaner}>
                          <Typography align="center">
                            {t('shopping.analytics.totalsales')}
                          </Typography>
                          <Typography align="center">
                            {currency}
                            {totalPrice || 0}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Card className={classes.cardMainContaner2}>
                        <CardContent className={classes.cardContaner}>
                          <Typography align="center">
                            {t('shopping.analytics.totalorders')}
                          </Typography>
                          <Typography align="center">{totalOrder}</Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Card className={classes.cardMainContaner3}>
                        <CardContent className={classes.cardContaner}>
                          <Typography align="center">
                            {t('shopping.analytics.NoofProductSold')}
                          </Typography>
                          <Typography align="center">
                            {totalProductSold}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card className={classes.cardMainContaner4}>
                <CardContent className={classes.cardContaner}>
                  <Typography>{t('shopping.analytics.totalsales')}</Typography>
                  <OrderLineChart data={chartAmountData} keyPath="Amount" />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.cardMainContaner4}>
                <CardContent className={classes.cardContaner}>
                  <Typography>
                    {t('shopping.analytics.selecteddates')}
                  </Typography>
                  <OrderLineChart data={chartData} keyPath="Order" />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(AnalyticsOrders);
