import React from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import LanguageList from './LanguageList';
import RobotList from './RobotList';
import { toggleSidebar, useLayoutDispatch } from '../../context/LayoutContext';

export default function Header(props) {
  const classes = useStyles();
  const { onHeaderRobotSelected, showRobotList } = props;
  const layoutDispatch = useLayoutDispatch();
  return (
    <div className={classes.headerContainer}>
      <IconButton onClick={() => toggleSidebar(layoutDispatch)}>☰</IconButton>
      <RobotList
        onHeaderRobotSelected={onHeaderRobotSelected}
        disabled={!showRobotList}
      />
      <LanguageList />
    </div>
  );
}

RobotList.propTypes = {
  onHeaderRobotSelected: PropTypes.func,
  showRobotList: PropTypes.bool
};

RobotList.defaultProps = {
  onHeaderRobotSelected: () => {},
  showRobotList: true
};
