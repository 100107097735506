import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    boxShadow: '0 0 3px 0 rgba(87,87,86,.25)',
    minHeight: '60px',
    width: '100%',
    background: '#fff',
    zIndex: '999',
    padding: '10px 24px',
    top: '0',
    marginLeft: '-24px'
  },
  typo: {
    color: '#575756',
    fontSize: '18px !important'
  },
  toggleMenu: {
    display: 'none',
    '@media (max-width:767px)': {
      display: 'block !important'
    }
  },
  btngroup: {
    position: 'absolute',
    top: '10px',
    right: '29%'
  }
}));
