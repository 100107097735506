import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'fixed',
    boxShadow: '0 0 3px 0 rgba(87,87,86,.25)',
    minHeight: '60px',
    width: '100%',
    background: '#fff',
    padding: '10px 24px',
    top: '0',
    left: 0,
    zIndex: 1200
  },
  typo: {
    color: '#575756',
    fontSize: '18px !important'
  },
  toggleMenu: {
    display: 'none',
    '@media (max-width:767px)': {
      display: 'block !important'
    }
  },
  // Robot List
  robotListContainer: {
    minWidth: '200px',
    marginLeft: 'auto',
    marginRight: 15,
    '@media (max-width:767px)': {
      minWidth: '160px'
    }
  },
  languageText: {
    marginLeft: 10,
    '@media (max-width:460px)': {
      display: 'none'
    }
  }
}));
