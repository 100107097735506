import React from 'react';
import { Chip, Grid } from '@material-ui/core';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { paginationRowPerArr } from "../../components/EnhancedDataTable/index";

const CustomChip = ({ label }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={console.log}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export default function Tables(props) {
  const { onMultiDelete } = props;
  const { t } = useTranslation('common');

  return (
    <>
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MUIDataTable
              title={props.title}
              data={props.data}
              columns={props.columns}
              options={{
                ...props.options,
                rowsPerPageOptions: paginationRowPerArr,
                filterType: 'checkbox',
                print: false,
                download: false,
                filter: false,
                textLabels: {
                  pagination: {
                    rowsPerPage: t('table.rowsParePage')
                  },
                  body: {
                    noMatch: t('table.emptyRows')
                  }
                },
                customToolbarSelect: (items) =>
                  onMultiDelete ? (
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={() => {
                        onMultiDelete && onMultiDelete(items);
                      }}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  ) : null
              }}
              components={{
                TableFilterList: CustomFilterList
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
