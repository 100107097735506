export const DATA_1 = {
  average_messages_per_user: {
    24: {
      current: [],
      historical: []
    },
    aggregated: {
      current: 0.0,
      historical: 0.0
    }
  },
  human_timestamps: [],
  num_users: {
    24: {
      current: [],
      historical: []
    },
    aggregated: {
      current: 0.0,
      historical: 0.0
    }
  },
  status: 200,
  timestamps: []
};

export const DATA_7 = {
  average_messages_per_user: {
    24: {
      current: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0],
      historical: [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0]
    },
    aggregated: {
      current: 0.0,
      historical: 0.0
    }
  },
  human_timestamps: [
    // "2020-10-30T00:00:00",
    // "2020-10-31T00:00:00",
    // "2020-11-01T00:00:00",
    // "2020-11-02T00:00:00",
    // "2020-11-03T00:00:00",
    // "2020-11-04T00:00:00",
    // "2020-11-05T00:00:00"
  ],
  num_users: {
    24: {
      current: [0, 0, 0, 0, 0, 0, 0],
      historical: [0, 0, 0, 0, 0, 0, 0]
    },
    aggregated: {
      current: 0.0,
      historical: 0.0
    }
  },
  status: 200,
  timestamps: [
    // 1604016000,
    // 1604102400,
    // 1604188800,
    // 1604275200,
    // 1604361600,
    // 1604448000,
    // 1604534400
  ]
};

export const DATA_30 = {
  average_messages_per_user: {
    24: {
      current: [
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0
      ],
      historical: [
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
        0.0, 0.0
      ]
    },
    aggregated: {
      current: 0.0,
      historical: 0.0
    }
  },
  human_timestamps: [],
  num_users: {
    24: {
      current: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0
      ],
      historical: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0
      ]
    },
    aggregated: {
      current: 0,
      historical: 0
    }
  },
  status: 200,
  timestamps: []
};
