import React from 'react';
import { useTranslation } from 'react-i18next';
import {Typography} from '@material-ui/core';
import EnhancedDataTable, { paginationRowPerArr } from '../EnhancedDataTable';

const FunctionLog = (props) => {
  const { logs } = props;
  const { t } = useTranslation('common');

  const columns = [
    {
      id: 'd', //Datetime
      name: 'd',
      numeric: false,
      disablePadding: false,
      label: t('dashboard.widgets.functionLogs.table.timestamp'),
      options: {
        customBodyRender: (row) => {
          return <Typography style={{ paddingRight: '30px' }}>{row}</Typography>;
        }
      }
    },
    {
      id: 'q', //Query
      name: 'q',
      numeric: false,
      disablePadding: false,
      label: t('dashboard.widgets.functionLogs.table.query')
    },
    {
      id: 'r', //Response
      name: 'r',
      numeric: false,
      disablePadding: false,
      label: t('dashboard.widgets.functionLogs.table.response')
    }
  ];

  return (
    <div className="list">
      {logs.length > 0 ? (
        <EnhancedDataTable
          title={t('dashboard.widgets.chart.title')}
          rows={logs}
          showCheckbox={false}
          columns={columns}
          pagination={true}
          rowsPerPageOptions={paginationRowPerArr}
          multiselect={false}
          onSelect={() => {}}
          hideCheckbox={true}
          responsive='vertical'
        />
      ) : (
        <h5 style={{ paddingLeft: '30px' }}>{t('noData')}</h5>
      )}
    </div>
  );
};

export default FunctionLog;
