import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { AddCircleOutline, Backup, Create, Delete } from '@material-ui/icons';
import clsx from 'clsx';
import 'firebase/storage';
import { useFormik } from 'formik';
import * as moment from 'moment';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import defaultImage from '../../images/default-image.png';
import Table from '../tables/Tables';
import useStyles from './styles';
import MediaPicker from '../../components/MediaPicker';
import { getCustomID, GetSeletedRobot } from '../../utills';
import Header from '../../components/Header';
import { getApi, postApi, putApi, deleteApi } from '../../services/Services';
import { useSelector } from 'react-redux';

const initialValues = {
  id: getCustomID(),
  category_name: '',
  discription: '',
  image: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  isDelete: false
};

const validationSchema = Yup.object().shape({
  category_name: Yup.string().label('category_name').required('Required'),
  discription: Yup.string().label('discription').required('Required'),
  image: Yup.object().shape({
    value: Yup.string(),
    type: Yup.string()
  }),
  createdAt: Yup.date(),
  updatedAt: Yup.date(),
  isDelete: Yup.bool().default(false)
});

const ShoppingCate = () => {
  const { t } = useTranslation('common');
  const language = useSelector(state => state.language.value);
  const [categoryList, setCategoryList] = useState([]);
  const [open, setOpen] = useState(false);
  const [actiontype, setActionType] = useState('add');
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [selectedRobot, setSelectedRobot] = useState('');
  const classes = useStyles();
  const getModalStyle = () => {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`
    };
  };
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const [modalStyle] = useState(getModalStyle);

  const closeModal = () => {
    setOpen(false);
  };

  const getCategoriesData = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/shopping-categories?selectedRobot=${
          robotId ? robotId : selectedRobot
        }&language=${language}`
      );
      const responseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        apiData.forEach((item) => {
          responseData.push([
            item.categoryId,
            item.imageLink,
            item.categoryTitle,
            item.categoryDescriotion,
            item.createdAt,
            item.updatedAt,
            item.isDelete
          ]);
        });
      }
      setCategoryList(responseData);
      utility.loading(false);
    } catch (e) {
      setCategoryList([]);
      utility.loading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      closeModal();
      utility.loading(true);
      const data = {
        categoryId: values.id,
        imageLink: values.image.value,
        categoryTitle: values.category_name,
        categoryDescriotion: values.discription,
        createdAt: values.createdAt.toString(),
        updatedAt: values.updatedAt.toString(),
        isDelete: false
      };
      if (actiontype === 'add') {
        try {
          const response = await postApi(
            `/shopping-category?selectedRobot=${selectedRobot}&&id=${values.id}&language=${language}`,
            data
          );
          if (response.statusCode === 200) {
            utility.snack('Category has been successfully created.', 'success');
            formik.resetForm();
            getCategoriesData();
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
          utility.loading(false);
        } catch (e) {
          console.log(e);
          formik.resetForm();
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      } else {
        try {
          const response = await putApi(
            `/shopping-category?selectedRobot=${selectedRobot}&&id=${values.id}`,
            data
          );
          if (response.statusCode === 200) {
            formik.resetForm();
            utility.snack('Category has been successfully updated.', 'success');
            getCategoriesData();
          } else {
            utility.snack('Something went wrong please try again.', 'error');
          }
          utility.loading(false);
        } catch (e) {
          console.log(e);
          formik.resetForm();
          utility.loading(false);
          utility.snack('Something went wrong please try again.', 'error');
        }
      }
    }
  });

  const openModal = () => {
    setOpen(true);
    setActionType('add');
    formik.setFieldValue('id', getCustomID());
  };

  const handleClose = () => {
    setOpenImagePicker(false);
  };

  const handleSelect = (item, type) => {
    if (item && type && type === 'image') {
      const genarateImage = {
        value: item.downloadlink,
        type: item.downloadlink.split('.').pop().split('?')[0]
      };
      formik.setFieldValue('image', genarateImage);
      handleClose();
    }
  };

  const handleDelete = useCallback(() => {
    const genarateImage = {
      value: '',
      type: ''
    };
    formik.setFieldValue('image', genarateImage);
  }, []);

  const getProductData = async (robotId) => {
    utility.loading(true);
    try {
      const response = await getApi(
        `/shopping-products?selectedRobot=${robotId ? robotId : selectedRobot}&language=${language}`
      );
      let responseData = [];
      const apiData = response.data;
      if (apiData && apiData.length > 0) {
        responseData = apiData;
        responseData.forEach((item) => {
          const data = [];
          Object.keys(item.productCateList).forEach((key) => {
            data.push(item.productCateList[key]);
          });
          item.productCateList = data;
        });
      }
      utility.loading(false);
    } catch (e) {
      console.log('eror === ', e.response);
      utility.loading(false);
    }
  };

  const onHeaderRobotSelected = (robotId) => {
    setSelectedRobot(robotId);
    getCategoriesData(robotId);
    getProductData(robotId);
  };

  const ModalBody = (
    <div className={classes.centerAlign}>
      <h2 id="simple-modal-title">{t('shopping.category.createCategories')}</h2>
      <List className={classes.noHorizontalPadding}>
        <ListItem
          className={clsx([
            classes.concenterAlign,
            classes.noHorizontalPadding
          ])}
        >
          <div className={classes.concenterAlign}>
            <Avatar
              src={
                formik.values.image?.value
                  ? formik.values.image?.value
                  : defaultImage
              }
              style={{
                width: 100,
                height: 100
              }}
            />
          </div>
        </ListItem>
        {formik.values.image?.value ? (
          <ListItem
            className={clsx([
              classes.concenterAlign,
              classes.noHorizontalPadding
            ])}
          >
            <Avatar onClick={handleDelete}>
              <Delete />
            </Avatar>
          </ListItem>
        ) : (
          <ListItem
            className={clsx([
              classes.concenterAlign,
              classes.noHorizontalPadding
            ])}
          >
            <>
              <label htmlFor="contained-button-file">
                <Avatar onClick={() => setOpenImagePicker(true)}>
                  <Backup />
                </Avatar>
              </label>
            </>
          </ListItem>
        )}
        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            label={t('shopping.category.Categoryname')}
            id="category_name"
            margin="normal"
            type="text"
            fullWidth
            name="category_name"
            error={
              formik.errors.category_name && formik.touched.category_name
                ? true
                : null
            }
            helperText={
              formik.errors.category_name && formik.touched.category_name
                ? formik.errors.category_name
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.category_name}
            onBlur={formik.handleBlur}
          />
        </ListItem>

        <ListItem className={classes.noHorizontalPadding}>
          <TextField
            id="discription"
            label="Discription"
            variant="outlined"
            name="discription"
            fullWidth
            multiline
            rows={4}
            error={
              formik.errors.discription && formik.touched.discription
                ? true
                : null
            }
            helperText={
              formik.errors.discription && formik.touched.discription
                ? formik.errors.discription
                : ''
            }
            onChange={formik.handleChange}
            value={formik.values.discription}
            onBlur={formik.handleBlur}
          />
        </ListItem>
      </List>
    </div>
  );

  function editRow(data) {
    setOpen(true);
    formik.setFieldValue('id', data.rowData[0]);
    formik.setFieldValue('image', {
      value: data.rowData[1] ? data.rowData[1] : '',
      type: data.rowData[1]
        ? data.rowData[1].split('.').pop().split('?')[0]
        : ''
    });
    formik.setFieldValue('category_name', data.rowData[2]);
    formik.setFieldValue('discription', data.rowData[3]);
    formik.setFieldValue('createdAt', data.rowData[4]);
    formik.setFieldValue('updatedAt', moment().unix());
    formik.setFieldValue('isDelete', data.rowData[6]);
    setActionType('edit');
  }

  const removeRow = async ({ rowData }) => {
    utility.loading(true);
    const selectedCategory = {
      categoryId: rowData[0],
      imageLink: rowData[1],
      categoryTitle: rowData[2],
      categoryDescriotion: rowData[3],
      createdAt: rowData[4].toString(),
      updatedAt: rowData[5].toString(),
      isDelete: true
    };
    try {
      const response = await deleteApi(
        `/shopping-category?selectedRobot=${selectedRobot}`,
        { id: selectedCategory.categoryId }
      );
      if (response.statusCode === 200) {
        formik.resetForm();
        utility.snack('Category has been successfully deleted.', 'success');
        getCategoriesData();
      } else {
        utility.snack('Something went wrong please try again.', 'error');
      }
      utility.loading(false);
    } catch (e) {
      console.log(e);
      formik.resetForm();
      utility.loading(false);
      utility.snack('Something went wrong please try again.', 'error');
    }
  };

  const columns = [
    {
      label: t('Id'),
      name: 'categoryId'
    },
    {
      label: t('avatar'),
      name: 'imageLink',
      options: {
        customBodyRender: (value) => {
          return <Avatar alt="Remy Sharp" src={value} />;
        }
      }
    },
    {
      label: t('name'),
      name: 'categoryTitle'
    },
    {
      label: t('description'),
      name: 'categoryDescriotion',
      options: {
        customBodyRender: (value) => {
          return value && <EllipsisText text={value} length={50} />;
        }
      }
    },
    {
      label: t('date'),
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          return (
            value && <Typography>{moment(value).format('LLLL')}</Typography>
          );
        }
      }
    },
    {
      label: t('actions'),
      options: {
        customBodyRender: (tableMeta, itemRow) => {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  onClick={() => editRow(itemRow)}
                >
                  <Create fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={() => removeRow(itemRow)}
                >
                  <Delete fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    }
  ];

  const options = {
    viewColumns: false
  };

  useEffect(() => {
    const getSeletedRobot = GetSeletedRobot();
    setSelectedRobot(getSeletedRobot);
    getCategoriesData(getSeletedRobot);
    getProductData(getSeletedRobot);
  }, [language]);

  return (
    <>
      <Header
        title={t('shopping.category.title')}
        onHeaderRobotSelected={onHeaderRobotSelected}
        showRobotList={actiontype === 'add' ? true : false}
      />
      <div>
        <MediaPicker
          handleClose={handleClose}
          handleSelect={handleSelect}
          handleDelete={() => {}}
          open={openImagePicker}
          type="image"
          selectedRobotID={selectedRobot}
        />
        <div className={classes.addIconbtn}>
          <Tooltip
            title={t('shopping.category.createCategories')}
            onClick={openModal}
          >
            <IconButton>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </div>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
        >
          <div style={modalStyle} className={classes.paper}>
            <form>
              {ModalBody}
              <div className={classes.btnDiv}>
                <Button onClick={closeModal} variant="contained">
                  {t('close')}
                </Button>
                <Button
                  onClick={formik.submitForm}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: '20px' }}
                >
                  {' '}
                  {t('save')}
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <Table
        title={t('shopping.category.listCategories')}
        data={categoryList}
        columns={columns}
        options={options}
      />
    </>
  );
};

export default memo(ShoppingCate);
