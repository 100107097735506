import classnames from 'classnames';
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLayoutState } from '../../context/LayoutContext';
import AnalyticsOrders from '../../pages/analytics';
import Charts from '../../pages/charts/Charts';
import Dashboard from '../../pages/dashboard';
import Dialogs from '../../pages/dialogs';
import KnowledgeBase from '../../pages/knowledgeBase';
import KnowledgeBaseDocument from '../../pages/knowledgeBase/components/KnowledgeBaseDocument';
import DialogsEdit from '../../pages/dialogs/components/Dialogs-edit';
import Faq from '../../pages/faq';
import Logout from '../../pages/logout';
import MediaLibrary from '../../pages/mediaLibrary';
import ManageOrders from '../../pages/orders';
import ManageOrderForm from '../../pages/orders/ManageOrderForm';
import ListPresenter from '../../pages/presenter/ListPresenter';
import ManagePresenter from '../../pages/presenter/ManagePresenter';
import TeamMemberForm from '../../pages/teamMember/teamMemberForm';
import Products from '../../pages/products';
import ManageProduct from '../../pages/products/ManageProduct';
import Projects from '../../pages/projects';
import Robots from '../../pages/robots';
import RobotForm from '../../pages/robots/RobotForm';
import ScreenSaver from '../../pages/screensaver';
import ShoppingCate from '../../pages/shopping/ShoppingCate';
import Shortcodes from '../../pages/shortcodes';
import TeamMember from '../../pages/teamMember';
import UserManagement from '../../pages/userMangement';
import Userprofile from '../../pages/userprofile';
import Survey from '../../pages/survey/survey';
import Quizzes from '../../pages/quizzes/survey';
import Sidebar from '../Sidebar';
import useStyles from './styles';
import ShoppingCoupons from '../../pages/coupons/ShoppingCoupons';
import StoryTellings from '../../pages/storytelling/Storytellings';
import CreateStortytelling from '../../pages/storytelling/createStorytelling';
import FaceRecognition from '../../pages/faceRecognition';
import FaceRecognitionForm from '../../pages/faceRecognition/faceRecognitionForm';

const Layout = () => {
  const classes = useStyles();
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/projects" component={Projects} />
            <Route
              exact
              path="/app/dialogs"
              render={() => <Redirect to="/app/dialogs/custom" />}
            />

            <Route path="/app/dialogs/edit/:id" component={DialogsEdit} />
            <Route path="/app/dialogs/custom" component={Dialogs} />

            <Route path="/app/dialogs/standard" component={Dialogs} />
            <Route path="/app/robots" component={Robots} />
            <Route path="/app/robotform" component={RobotForm} />
            <Route path="/app/editform/:id" component={RobotForm} />

            <Route
              exact
              path="/app/knowledgebase"
              render={() => <Redirect to="/app/knowledgebase/custom" />}
            />
            <Route path="/app/knowledgebase/custom" component={KnowledgeBase} />
            <Route
              path="/app/knowledgebase/document/:id"
              component={KnowledgeBaseDocument}
            />

            <Route
              exact
              path="/app/storytellings/list"
              component={StoryTellings}
            />
            <Route
              path="/app/storytellings/create"
              component={CreateStortytelling}
            />
            <Route
              path="/app/storytellings/edit/:id"
              component={CreateStortytelling}
            />

            <Route
              exact
              path="/app/presenter"
              render={() => <Redirect to="/app/presenter/list" />}
            />
            <Route path="/app/presenter/create" component={ManagePresenter} />
            <Route path="/app/presenter/list" component={ListPresenter} />
            <Route path="/app/presenter/edit/:id" component={ManagePresenter} />
            <Route path="/app/media" component={MediaLibrary} />
            <Route path="/app/teamMember" component={TeamMember} />
            <Route
              path="/app/teamMemberForm/create"
              component={TeamMemberForm}
            />
            <Route
              path="/app/teamMemberForm/edit/:id"
              component={TeamMemberForm}
            />
            <Route
              exact
              path="/app/exertView"
              render={() => <Redirect to="/app/users/management" />}
            />
            <Route path="/app/shortcodes" component={Shortcodes} />
            <Route path="/app/users/management" component={UserManagement} />
            <Route path="/app/users/profile" component={Userprofile} />
            <Route path="/app/faq" component={Faq} />
            <Route path="/app/screensaver" component={ScreenSaver} />
            <Route path="/app/analytics" component={Charts} />
            <Route path="/app/survey" component={Survey} />
            <Route path="/app/quizzes" component={Quizzes} />
            {/* <Route path="/app/old-survey" component={UserSurvey} /> */}
            <Route
              exact
              path="/app/shopping"
              render={() => <Redirect to="/app/shopping/categories" />}
            />
            <Route path="/app/shopping/categories" component={ShoppingCate} />
            <Route path="/app/shopping/coupons" component={ShoppingCoupons} />
            <Route
              path="/app/shopping/create-product"
              component={ManageProduct}
            />
            <Route path="/app/shopping/products" component={Products} />
            <Route
              path="/app/shopping/edit-products/:id"
              component={ManageProduct}
            />
            <Route path="/app/shopping/orders" component={ManageOrders} />
            <Route
              path="/app/shopping/edit-order/:id"
              component={ManageOrderForm}
            />
            <Route path="/app/shopping/analytics" component={AnalyticsOrders} />
            <Route path="/app/faceRecognition" component={FaceRecognition} />
            <Route path="/app/faceRecognitionForm/create" component={FaceRecognitionForm} />
            <Route path="/app/faceRecognitionForm/edit/:id" component={FaceRecognitionForm} />
            <Route path="/app/logout" component={Logout} />
          </Switch>
        </div>
      </>
    </div>
  );
};

export default withRouter(Layout);
