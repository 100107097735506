import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  pageTitle: {
    width: '100%',
    fontWeight: '500',
    fontSize: 'calc(28px)',
    textAlign: 'left',
    marginBottom: '20px'
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  visitsNumberContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    position: 'absolute',
    width: 400,
    '@media (max-width:767px)': {
      width: '80% !important',
      left: '10% !important',
      top: '20% !important',
      transform: 'none !important'
    },
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  btnDiv: {
    marginTop: '30px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  addIconbtn: {
    padding: '5px 0'
  },
  noHorizontalPadding: {
    padding: '5px 0'
  },
  noVerticalPadding: {
    padding: '5px 0'
  },
  concenterAlign: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputFileUpload: {
    display: 'none'
  },
  highlightOn: {
    color: 'green'
  },
  highlightOff: {
    color: '#afaf1b'
  },
  listRoot: {
    width: '100%',
    maxWidth: 360,
    height: 200,
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper
  },
  listRootHeading: {
    font: 'inherit',
    fontSize: 16,
    textAlign: 'left'
  },
  errorMessage: {
    font: 'inherit',
    fontSize: 13,
    textAlign: 'left',
    color: 'red'
  }
}));
