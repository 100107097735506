import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';

const FloatingButton = (props) => {
  const { actions, direction, ariaLabel } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SpeedDial
      ariaLabel={ariaLabel}
      icon={<SpeedDialIcon openIcon={<Close />} />}
      onClose={handleClose}
      open={open}
      direction={direction}
      onClick={handleOpen}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={handleClose}
        />
      ))}
    </SpeedDial>
  );
};

FloatingButton.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any),
  direction: PropTypes.string,
  ariaLabel: PropTypes.string
};

FloatingButton.defaultProps = {
  actions: [],
  direction: 'left',
  ariaLabel: 'SpeedDial openIcon example'
};

export default memo(FloatingButton);
