import './survey.css';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import {
  PanelHeader,
  TooltipIconButton
} from '../dialogs/components/actionbar';
import EnhancedModal from '../../components/Utility/EnhancedModal';
import ConfirmModal from '../../components/Utility/ConfirmModal';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import CPie from './pie';
import Legends from './Legends';
import { getApi, postApi, putApi, deleteApi } from '../../services/Services';
import { getCustomID } from '../../utills';
import { useSelector } from 'react-redux';
import { Close, Check } from '@material-ui/icons';
import Table from '../tables/Tables';

const COLORS = ['#2f3640', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function Survey(props) {
  const { selectedRobot } = props;
  const { t } = useTranslation('common');
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);
  const classes = useStyles();
  const language = useSelector(state => state.language.value);
  const [chartData, setChartData] = useState([]);
  const [checked, setCheked] = useState([]);
  const [actualList, setActualList] = useState([]);
  const [openAddModal, setopenAddModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [storytellingData, setStorytellingData] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedQuizzes, setSelectedQuizzes] = useState({
    id: undefined,
    questionOptions: [],
    storyteller: '',
    question: ''
  });
  const [selectedChartQuestion, setChartSelectedQuestion] = useState({
    question: ''
  });
  const [optionInput, setOptionInput] = useState('');
  const [editQuestionId, setEditQuestionId] = useState('');
  const [selectID, setSelectID] = useState('');
  const [notEmpty, setNotEmpty] = useState({
    emptyQuestion: false,
    emptyOptions: false,
    correctOption: false
  });

  const getQuestions = async () => {
    const storytellersResponse = await getApi(
      `/storyteller/list?selectedRobot=${selectedRobot}&language=${language}`
    );
    setStorytellingData(storytellersResponse.data);

    const serveyResponse = await getApi(
      `/quizzes?selectedRobot=${selectedRobot}&language=${language}`
    );
    if (serveyResponse.data && serveyResponse.data.length > 0) {
      setQuestions(serveyResponse.data);
      const responseData = [];
      const apiData = serveyResponse.data;
      if (apiData && apiData.length > 0) {
        apiData.forEach((item) => {
          responseData.push([
            item.question,
            {
              id: item.id,
              options: item.questionOptions,
              storyteller: item.storyteller
            }
          ]);
        });
      }
      setActualList(responseData);
    } else {
      setQuestions([]);
      setActualList([]);
    }
    utility.loading(false);
  };

  const refreshList = async () => {
    utility.loading(true);
    getQuestions();
  };

  const showChart = (row) => {
    const itemOptions = row.rowData[1].options;
    if (itemOptions.length > 0) {
      itemOptions.forEach((item, index) => {
        item.color = COLORS[index];
      });
    }

    setChartSelectedQuestion({
      id: row.rowData[1].id,
      question: row.rowData[0],
      questionOptions: itemOptions,
      storyteller: row.rowData[1].storyteller,
    });
    setChartData(itemOptions);
  };

  const editQuestion = (row) => {
    const rowOptions = row.rowData[1].options;
    const id = row.rowData[1].id;
    const selectStoryteler = row.rowData[1].storyteller;
    setSelectedQuizzes({
      id,
      question: row.rowData[0],
      questionOptions: rowOptions,
      storyteller: selectStoryteler
    });
    setEditQuestionId(id);
    setopenAddModal(true);
  };

  const deleteQuestion = async () => {
    utility.loading(true);
    const deleteResponse = await deleteApi(
      `/quizzes?id=${selectID}&&selectedRobot=${selectedRobot}`
    );
    if (deleteResponse.statusCode === 200) {
      utility.snack('Successfully Delete');
      refreshList();
    }
    setOpenConfirmModal(false);
    utility.loading(false);
  };

  const deleteQuestionModal = async (id) => {
    setSelectID(id);
    setOpenConfirmModal(true);
  };

  useEffect(() => {
    setSelectID(selectedRobot);
    if (selectedRobot) {
      refreshList();
    }
  }, [selectedRobot, language]);

  const columns = [
    {
      label: t('survey.name'),
      name: 'question',
    },
    {
      label: t('actions'),
      options: {
        customBodyRender: (tableMeta, itemRow) => {
          return (
            <div className="table-actions" style={{ display: 'flex' }}>
              <TooltipIconButton
                size="small"
                title="Quiz"
                onClick={() => showChart(itemRow)}
                icon={<AssessmentIcon />}
              />
              <TooltipIconButton
                size="small"
                title="Edit"
                onClick={() => {
                  editQuestion(itemRow);
                }}
                icon={<EditIcon />}
              />
              <TooltipIconButton
                size="small"
                title="Delete"
                onClick={() => {
                  deleteQuestionModal(itemRow.rowData[1].id);
                }}
                icon={<DeleteIcon />}
              />
            </div>
          );
        }
      }
    }
  ];
  // const onSelect = useCallback((selected) => {
  //   setCheked(selected);
  // }, []);

  const onSelectOptions = (updatedOptions) => {
    const newArr = selectedQuizzes.questionOptions.map((item) => {
      if(item.id === updatedOptions.id) {
        return {
          ...item,
          is_correct_answer: !!!item.is_correct_answer
        }
      }
      return item
    })
    setSelectedQuizzes({
      ...selectedQuizzes,
      questionOptions: newArr
    })
  };

  const onRemoveOptions = (updatedOptions) => {
    const newArr = selectedQuizzes.questionOptions.filter(item => item.id !== updatedOptions.id);
    setSelectedQuizzes({
      ...selectedQuizzes,
      questionOptions: newArr
    })
  };

  const onSelectStoryteller = (storytellerItem) => {
    setSelectedQuizzes({
      ...selectedQuizzes,
      storyteller: storytellerItem
    })
  }

  const notEmptyFunc = () => {
    if (notEmpty.emptyOptions) return <span className={classes.notEmptyText}>{t('notOptionForQuestion')}</span>
    if (notEmpty.correctOption && !notEmpty.emptyOptions) return <span className={classes.notEmptyText}>{t('oneAnswerSelected')}</span>
  }

  const createSurvey = async () => {
    let response = {};
    if(selectedQuizzes.questionOptions.length > 1 && selectedQuizzes.question){
      const getTrueVal = selectedQuizzes.questionOptions.find((item)=> item.is_correct_answer);
      if (getTrueVal) {
        utility.loading(true);
        const resBody = {
          question: selectedQuizzes.question,
          questionOptions: selectedQuizzes.questionOptions,
        }

        const storytellerTitle = selectedQuizzes.storyteller ? `&storytellerTitle=${selectedQuizzes.storyteller}`:'';
        if (editQuestionId) {
          resBody.id = editQuestionId;
          const res = await putApi(`/quizzes?selectedRobot=${selectedRobot}&language=${language}${storytellerTitle}`, resBody);
          response = res;
        } else {
          const res = await postApi(`/quizzes?selectedRobot=${selectedRobot}&language=${language}${storytellerTitle}`, resBody);
          response = res;
        }

        if (response.statusCode === 200) {
          utility.loading(false);
          setSelectedQuizzes({
            question: '',
            questionOptions: [],
            storyteller: ''
          });
          setopenAddModal(false);
          setEditQuestionId('');
          refreshList();
          setNotEmpty({
            emptyQuestion: false,
            emptyOptions: false,
            correctOption: false
          });
        }
      }
      else{
        setNotEmpty({...notEmpty, correctOption: true});
      }
    }else{
      const emotyVal = {
        emptyQuestion: false,
        emptyOptions: false
      };
      if (!selectedQuizzes.question) emotyVal.emptyQuestion = true;
      if (selectedQuizzes.questionOptions.length <= 1) emotyVal.emptyOptions = true;
      setNotEmpty(emotyVal)
      refreshList();
    }
  };

  const onModalClose = () => {
    setEditQuestionId('');
    setSelectedQuizzes({
      id: undefined,
      question: '',
      questionOptions: [],
      storyteller: ''
    });
  };

  const onCancelSearch = () => {
    setQuestions(actualList);
  };

  const onSearch = (e) => {
    const search_text = e.target.value;

    const list = actualList.filter(
      (item) =>
        item.question.toLowerCase().indexOf(search_text.toLowerCase()) >= 0
    );

    if (search_text === '') {
      return onCancelSearch();
    }
    setQuestions(list);
  };

  const onOptionChange = async (e) => {
    if (e.key === 'Enter' && e.target.value) {
      const value = e.target.value;
      setSelectedQuizzes({
        ...selectedQuizzes,
        questionOptions: [
          ...selectedQuizzes.questionOptions || [],
          {
            id: getCustomID(),
            value,
            chartValue: 0
          }
        ]
      })
      if(selectedQuizzes.questionOptions.length > 0) setNotEmpty({...notEmpty, emptyOptions: false});
      setOptionInput('');
    }
  };

  const onQuestionChange = (e) => {
    const value = e.target.value;
    setSelectedQuizzes({
      ...selectedQuizzes,
      question: value
    });
  };

  const chart =
    chartData.length > 0 ? (
      <>
        <Legends data={chartData} />
        {selectedChartQuestion.question ? (
          <span className={classes.questionInChart}>
            {selectedChartQuestion.question}
          </span>
        ) : null}
        <CPie data={chartData} />
      </>
    ) : (
      <span>{t('survey.chartNotFound')}</span>
    );

  const renderQuestionModal = () => {
    return (
      <EnhancedModal
        open={openAddModal}
        onOk={createSurvey}
        onCancel={() => setopenAddModal(false)}
        onClose={onModalClose}
      >
        <List>
          <ListItem>
            <TextField
              className={notEmpty.emptyQuestion ? classes.notEmty:''}
              id="question"
              label={t('survey.name')}
              variant="outlined"
              name="question"
              fullWidth
              multiline
              rows={4}
              onChange={onQuestionChange}
              value={selectedQuizzes.question}
            />
          </ListItem>
          <ListItem>
            <FormControl className={classes.formControlModal}>
              <TextField
                id="newOption"
                label={t('survey.addOption')}
                variant="outlined"
                name="newOptions"
                value={optionInput}
                onChange={(e) => setOptionInput(e.target.value)}
                fullWidth
                onKeyDown={onOptionChange}
              />
            </FormControl>
          </ListItem>
          <ListItem className={classes.noHorizontalPadding}>
            <div className={classes.responseItemsContainer +' '+(notEmpty.emptyOptions ? classes.notEmty:'')}>
              <div>
                {
                  (selectedQuizzes.questionOptions||[]).map((item)=> {
                    return(
                      <div
                      key={Math.random()}
                      className={classes.responseItem +' '+ (item.is_correct_answer ? classes.activeResponse : '')}
                      >
                        <div className={classes.itemContainer}>
                          <div className={classes.itemValue}>
                            <span>{item.value}</span>
                          </div>
                          <div className={classes.deleteContainer}>
                            <Check
                            className={classes.checkItem}
                            onClick={()=> onSelectOptions(item)}
                            />
                            <Close
                            className={classes.delResponse}
                            onClick={()=> onRemoveOptions(item)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </ListItem>
          <ListItem>
                <FormControl
                  style={{ minWidth: 100, flex: 1 }}
                >
                  <InputLabel id="multiLanguage">{t('storytelling.title')}</InputLabel>
                  <Select
                    disabled={storytellingData.length ? false : true}
                    label={t('storytelling.title')}
                    id="storytelling"
                    name="storytelling"
                    value={selectedQuizzes.storyteller && selectedQuizzes.storyteller.length ? selectedQuizzes.storyteller : t('nanName')}
                    onChange={(e)=> onSelectStoryteller(e.target.value)}
                  >
                    <MenuItem style={{backgroundColor: '#F3F5FF', minHeight: 36}} value={t('nanName')}>{t('nanName')}</MenuItem>
                    {
                      storytellingData.map((item)=> {
                        return(
                          <MenuItem
                          key={Math.random()*100}
                          value={item['0']}
                          >
                            {item['0']}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </ListItem>
          <ListItem>
            <div>{ notEmptyFunc() }</div>
          </ListItem>
        </List>
      </EnhancedModal>
    );
  };

  const renderConfirmModal = () => {
    return (
      <ConfirmModal
        open={openConfirmModal}
        onOk={() => deleteQuestion()}
        onCancel={() => setOpenConfirmModal(false)}
        onClose={() => setOpenConfirmModal(false)}
        conf
      >
        <List>
          <ListItem>
            <div>Do you want to delete?</div>
          </ListItem>
        </List>
      </ConfirmModal>
    );
  };

  const onAddModal = () => {
    setopenAddModal(!openAddModal);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PanelHeader
            title={t('survey.Quizzes')}
            padding="0"
            onCancelSearch={onCancelSearch}
            onSearch={onSearch}
            bgColor="transparent"
            onAdd={onAddModal}
            count={questions.length}
            isSelected={checked.length > 0}
            btnTitle={{
              add: 'Add Question'
            }}
            settings={{
              search: true,
              addNew: true
            }}
          />
          {renderQuestionModal()}
          {renderConfirmModal()}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <Table
            title={t('survey.Quizzes')}
            data={actualList}
            columns={columns}
            options={selectedQuizzes.questionOptions}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Paper
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 200
            }}
          >
            {chart}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
