import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  inputFileUpload: {
    display: 'none'
  },
  errorContainer: {
    marginLeft: 14,
    marginRight: 14,
    marginBottom: 5,
    marginTop: 5
  },
  media: {
    width: 200,
    height: 200
  },
  actions: {
    // margin: "20px 0",
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    '& span': {
      display: 'flex'
    }
  },
  category: {
    margin: 0
  },
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));
