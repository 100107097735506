import * as moment from 'moment';

export const getChartData = (
  name = 'average_messages_per_user',
  day = 7,
  data
) => {
  // let LINECHART_DATA = data;
  const LINECHART_DATA = data[day];

  const daily = LINECHART_DATA ? LINECHART_DATA[name]['24'].current : [];
  const historical = LINECHART_DATA
    ? LINECHART_DATA[name]['24'].historical
    : [];
  const timestamps = LINECHART_DATA ? LINECHART_DATA.human_timestamps : [];

  return {
    chart: timestamps.map((ts, index) => {
      return {
        name: moment(ts).format(`MM/DD/YY${day === 1 ? ' HH:MM' : ''}`),
        daily: daily[index],
        daily_historical: historical[index]
      };
    }),
    aggregated: LINECHART_DATA ? LINECHART_DATA[name].aggregated : 0.0
  };
};

export const processData = (data) => {
  return {
    sessions: getChartData('average_messages_per_user', data),
    num_users: getChartData('num_users', data)
  };
};
