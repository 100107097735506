import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';

const MediaItemHeading = (props) => {
  const { titile, titleIcon } = props;

  return (
    <List dense>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{titleIcon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={titile} />
      </ListItem>
    </List>
  );
};

MediaItemHeading.propTypes = {
  titile: PropTypes.string,
  titleIcon: PropTypes.node
};

MediaItemHeading.defaultProps = {
  titile: '',
  titleIcon: null
};

export default memo(MediaItemHeading);
