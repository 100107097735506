import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';

// components
import { getChartData } from './data';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

export const SessionTitle = ({ title, data }) => {
  return (
    <div className="SessionTitle">
      <h3>{title}</h3>
      <span className="data">{data}</span>
    </div>
  );
};

export default function Charts(props) {
  const { chartData } = props;
  const theme = useTheme();
  const { t } = useTranslation('common');

  const [state, setState] = useState({
    days: 7,
    sessions: {
      chart: null,
      aggregated: null
    },
    num_users: {
      chart: null,
      aggregated: null
    }
  });

  const generateGraph = () => {
    const num_users = getChartData('num_users', state.days, chartData);
    const sessions = getChartData(
      'average_messages_per_user',
      state.days,
      chartData
    );

    setState({ ...state, sessions, num_users });
  };

  useEffect(() => {
    return generateGraph();
  }, [props]);

  const style = {
    responsive_container: {
      height: 150,
      width: 1000
    },
    linechart: {
      width: 1000,
      height: 150,
      margin: {
        top: 25,
        right: 30,
        left: 20,
        bottom: 5
      }
    }
  };

  const onChangeDays = (e) => {
    const value = e.target.value;

    const num_users = getChartData('num_users', value, chartData);
    const sessions = getChartData('average_messages_per_user', value, chartData);

    return setState({
      ...state,
      sessions,
      num_users,
      days: value
    });
  };

  if (!state.sessions.chart || !state.num_users.chart) {
    return null;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}>
        <FormControl>
          <InputLabel id="days">{t('days')}</InputLabel>
          <Select
            labelId="days"
            id="days"
            value={state.days ? state.days : 7}
            onChange={onChangeDays}
          >
            <MenuItem value={1}>{t('yesterday')}</MenuItem>
            <MenuItem value={7}>
              {t('last')} 7 {t('days')}
            </MenuItem>
            <MenuItem value={30}>
              {t('last')} 30 {t('days')}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <ResponsiveContainer
        width="100%"
        height={style.responsive_container.height}
      >
        <LineChart
          width={style.linechart.width}
          height={style.linechart.height}
          data={state.num_users.chart}
          margin={style.linechart.margin}
        >
          <XAxis tick={false} axisLine={true} dataKey="name" />
          <Tooltip />
          <Line
            label="Daily Historical"
            name="Daily Historical"
            type="linear"
            dataKey="daily_historical"
            stroke={theme.palette.primary.main}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

// ################################################################
