import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import Axios from 'axios';

import useStyles from '../login/styles';
import logo from '../login/logo.png';

function EmailVerify(props) {
  const classes = useStyles();
  const [message, setMessage] = useState('Verifying Email address...');

  useEffect(() => {
    // reset login state
    Cookies.remove('u_id');
    Cookies.remove('token');
    Cookies.remove('selectedRobot');

    const params = new URLSearchParams(props.location.search);
    let token;

    if ((token = params.get('token'))) {
      Axios.patch('/verify-user?token=' + token)
        .then((res) => {
          setMessage(res.message);
          setTimeout(() => {
            setMessage('Redirecting to Login...');
            setTimeout(() => {
              window.location.assign(
                `${process.env.REACT_APP_CLIENT_URL}/#/login`
              );
            }, 500);
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            window.location.assign(
              `${process.env.REACT_APP_CLIENT_URL}/#/login`
            );
          }, 500);
        });
    }
  }, [props.location.search]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>IRIS Robotics</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography className={classes.quoteText} variant="h3">
              {message}
            </Typography>
            {/* {errorTag} */}
          </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          @{new Date().getFullYear()} IRIS Robotics
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(EmailVerify);
