import React, { memo, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import 'firebase/storage';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useUtility, useUtilityDispatch } from '../../context/UtilityContext';
import { PanelHeader } from '../dialogs/components/actionbar';
import useStyles from './styles';
import { getApi, postApi } from '../../services/Services';
import { GetSeletedRobot } from '../../utills';

const ManageOrderForm = (props) => {
  const { history, match } = props;
  const { t } = useTranslation('common');
  const [orderDate, setOrderDate] = useState('');
  const [orderByName, setOrderByName] = useState('');
  const [orderByEmail, setOrderByEmail] = useState('');
  const [orderProducts, setOrderProducts] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [orderTotalPrice, setOrderTotalPrice] = useState('');
  const classes = useStyles();
  const dispatch = useUtilityDispatch();
  const utility = useUtility(dispatch);

  const getManageOrderData = async (selectedRobot) => {
    utility.loading(true);
    const { params } = match;
    const { id } = params;
    if (id) {
      const response = await getApi(`/getmanageorder`, { id, selectedRobot });
      if (response && response.data) {
        const result = response.data;
        setOrderByName(result.userName);
        setOrderByEmail(result.userEmail);
        result && result.orderStatus
          ? setOrderStatus(result.orderStatus)
          : setOrderStatus('');
        setOrderDate(result.date);
        setOrderTotalPrice(result.totalPrice);

        const productNames = [];
        result.products.forEach((product) => {
          if (product['productName']) productNames.push(product['productName']);
        });
        setOrderProducts(productNames.join(', '));
      }
    }
    utility.loading(false);
  };

  useEffect(() => {
    const selectedRobot = GetSeletedRobot();
    (async () => {
      await getManageOrderData(selectedRobot);
    })();
  }, []);

  const onChangeStatus = (e) => {
    if (e.target.value) {
      setOrderStatus(e.target.value);
    }
  };

  const updateManageOrder = async (e) => {
    e.preventDefault();
    const selectedRobot = GetSeletedRobot();
    const { params } = match;
    const { id } = params;
    const values = {
      orderStatus
    };

    if (id) {
      const r = await postApi(
        `/updateManageOrder?id=${id}&selectedRobot=${selectedRobot}`,
        { values }
      );
      if (r.statusCode === 200) {
        history.push('/app/shopping/orders');
        utility.snack('Successfully Updated Order');
      }
    }
  };

  return (
    <>
      <PageTitle title={t('Edit Order')} />
      <PanelHeader title={t('Edit Order')} />
      <div>
        <form className="presenter-form" onSubmit={updateManageOrder}>
          <Grid container>
            <Grid item xs={12} md={12} lg={6}>
              <Card>
                <CardContent>
                  <List>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.order.ClientName')}
                        variant="outlined"
                        id="orderByName"
                        margin="normal"
                        type="text"
                        fullWidth
                        disabled
                        value={orderByName}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.order.ClientEmail')}
                        variant="outlined"
                        id="orderByEmail"
                        margin="normal"
                        type="text"
                        fullWidth
                        disabled
                        value={orderByEmail}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <FormControl className={classes.formControlModal}>
                        <InputLabel id="orderStatus">
                          {t('shopping.order.Status')}
                        </InputLabel>
                        <Select
                          labelId="orderStatus"
                          id="orderStatus"
                          fullWidth
                          name="orderStatus"
                          onChange={(e) => {
                            onChangeStatus(e);
                          }}
                          value={orderStatus}
                        >
                          <MenuItem value="waiting for payment">
                            {t('orderStatus.waitingForPayment')}
                          </MenuItem>
                          <MenuItem value="order placed">
                            {t('orderStatus.orderPlaced')}
                          </MenuItem>
                          <MenuItem value="processing">
                            {t('orderStatus.processing')}
                          </MenuItem>
                          <MenuItem value="preparing to ship">
                            {t('orderStatus.preparingToShip')}
                          </MenuItem>
                          <MenuItem value="shipped">
                            {t('orderStatus.shipped')}
                          </MenuItem>
                          <MenuItem value="shipping to store">
                            {t('orderStatus.shippingToStore')}
                          </MenuItem>
                          <MenuItem value="delivered">
                            {t('orderStatus.delivered')}
                          </MenuItem>
                          <MenuItem value="ready for pickup">
                            {t('orderStatus.readyForPickup')}
                          </MenuItem>
                          <MenuItem value="picked up">
                            {t('orderStatus.pickedUp')}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.order.date')}
                        variant="outlined"
                        id="orderDate"
                        margin="normal"
                        type="text"
                        fullWidth
                        name="orderDate"
                        disabled
                        value={orderDate}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.order.Products')}
                        variant="outlined"
                        id="orderProducts"
                        margin="normal"
                        type="text"
                        fullWidth
                        name="orderProducts"
                        disabled
                        value={orderProducts}
                      />
                    </ListItem>
                    <ListItem className={classes.noHorizontalPadding}>
                      <TextField
                        label={t('shopping.order.TotalPrice')}
                        variant="outlined"
                        id="orderTotalPrice"
                        margin="normal"
                        type="number"
                        fullWidth
                        name="orderTotalPrice"
                        disabled
                        value={orderTotalPrice}
                      />
                    </ListItem>
                    <ListItem>
                      <Button type="submit" variant="contained" color="primary">
                        {t('update')}
                      </Button>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default withRouter(memo(ManageOrderForm));
