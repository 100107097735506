import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import { useTranslation } from 'react-i18next';
import MUIDataTable, {TableFilterList} from 'mui-datatables';
import { Delete } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

const CustomChip = ({ label }) => {
  return (
    <Chip
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={console.log}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.showCheckbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color="primary"
            />
          </TableCell>
        ) : null}
        {props.headCells.map((headCell) => {
          let sortable = true;
          if (headCell.sortable === false) {
            sortable = false;
          }
          return (
            <TableCell
              key={Math.random() * 100}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onMultiDelete = () => {} } = props;

  return (
    numSelected > 0 &&
    props.multiselect && (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
        {numSelected > 0 ? (
          <Grid container>
            <Grid item xs={11}>
              <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {numSelected} selected
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton aria-label="delete" onClick={onMultiDelete}>
                <DeleteOutlineOutlined />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {props.title}
          </Typography>
        )}
      </Toolbar>
    )
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.number.isRequired,
  onMultiDelete: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

const EnhancedDataTable = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const { onMultiDelete } = props;
  const { t } = useTranslation('common');

  React.useEffect(() => {
    setSelected([]);
  }, [props.rows]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          multiselect={props.multiselect}
          numSelected={selected.length}
          selected={0}
          onMultiDelete={() => onMultiDelete(selected)}
        />
        <TableContainer>
        <MUIDataTable
            title={props.title}
            data={props.rows}
            columns={props.columns}
            selected={props.selected}
            options={{
              ...props.options,
              rowsPerPageOptions: paginationRowPerArr,
              filtertype: "checkbox",
              responsive: props.responsive,
              selectableRowsHideCheckboxes: props.hideCheckbox,
              fixedheader: true,              
              print: false,
              download: false,
              filter: false,
              viewColumns: false,
              search: false,
              onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
                let list = props.rows.filter((_, i) => {
                  if (rowsSelected.includes(i)) {
                    return true;
                  }
                }).map(item => item.id)

                props.onSelect(list)
              },
              textLabels: {
                pagination: {
                  rowsPerPage: t('table.rowsParePage')
                },
                body: {
                  noMatch: t('table.emptyRows')
                }
              },
              customToolbarSelect: (items) =>
                onMultiDelete ? (
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => {
                      onMultiDelete && onMultiDelete(items);
                    }}
                  >
                    <Delete fontSize="inherit" />
                  </IconButton>
                ) : null
            }}
            components={{
              TableFilterList: CustomFilterList,
            }}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

export function TableCellRender({ row, columns }) {
  return columns.map((column, index) => {
    return (
      <TableCell key={index.toString()}>
        {' '}
        {column.render ? column.render(row) : row[column['id']]}
      </TableCell>
    );
  });
}


EnhancedDataTable.propTypes = {
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  multiselect: PropTypes.bool
};

export const paginationRowPerArr = [10, 50, 100];
EnhancedDataTable.defaultProps = {
  showCheckbox: true,
  pagination: true,
  rowsPerPageOptions: [10, 50, 100],
  multiselect: true
};

export default EnhancedDataTable;
